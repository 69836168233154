import {ItemPackaging} from "../../../corelogic/models/item";
import {ItemPackagingGateway} from "../../../corelogic/gateways/ItemPackagingGateway";
import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {
    ItemPackagingResponse,
    mapItemPackagingApiResponseToItemPackaging,
    mapItemPackagingToItemPackagingApiRequest
} from "./Model/itemPackagingApi";

export class ApiItemPackagingGateway implements ItemPackagingGateway {

    retrieveAllByItemId(itemId: number): Promise<ItemPackaging[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.itemPackaging, itemId.toString())})
    }

    add(itemId: number, itemPackagingType: ItemPackaging): Promise<ItemPackaging> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.itemPackaging),
            method: "POST",
            data: {...mapItemPackagingToItemPackagingApiRequest(itemPackagingType), itemId: itemId}
        })
    }

    delete(itemPackagingTypeId: number): Promise<void> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.itemPackaging, itemPackagingTypeId.toString()),
            method: "DELETE"
        })
    }

    update(itemPackagingType: ItemPackaging): Promise<ItemPackaging> {
        return apiFetchApiResponse<ItemPackagingResponse>({
            url: buildUrl(API_PREFIX.itemPackaging),
            method: "PUT",
            data: mapItemPackagingToItemPackagingApiRequest(itemPackagingType)
        })
            .then(x => mapItemPackagingApiResponseToItemPackaging(x))
    }
}