import {useBarcode} from "react-barcodes";
import React from "react";

export const Barcode = (props: BaracodeProps) => {
    const {inputRef} = useBarcode({
        value: props.value,
        options: {
            ...props.options
        }
    })

    switch (props.type) {
        case "svg":
            return <svg ref={inputRef}/>
        case "canvas":
            return <canvas ref={inputRef}/>
        default:
            return <img key={`barcode-${props.value}`} ref={inputRef} alt={`barcode-${props.value}`}/>

    }

}

interface BaracodeProps {
    value: string,
    type?: 'img' | 'svg' | 'canvas'
    options?: {
        format?: string,
        width?: number,
        height?: number,
        displayValue?: boolean,
        text?: string,
        fontOptions?: string,
        font?: string,
        textAlign?: string,
        textPosition?: string,
        textMargin?: number,
        fontSize?: number,
        background?: string,
        lineColor?: string,
        margin?: number,
        marginTop?: number,
        marginBottom?: number,
        marginLeft?: number,
        marginRight?: number,
        flat?: boolean
    }
}