export const getDateFromMilliseconds = (dateInMilliseconds?: number): Date | undefined => {
    if (dateInMilliseconds) {
        return new Date(dateInMilliseconds)
    }
}

export const getMillisecondsFromDate = (date?: Date): number | undefined => {
    if (date) {
        return date.getTime()
    }
}