import * as React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Avatar, Grid, Tooltip} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import GetUserRoleButton from "../Utils/GetUserRoleButton";
import GetUserStatusButton from "../Utils/GetUserStatusButton";

export const columnsUserListDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID'
    },
    {
        field: 'userPicture',
        headerAlign: "center",
        sortable: false,
        filterable: false,
        width: 150,
        renderHeader: () => {
            return (
                <ImageIcon/>
            )
        },
        renderCell: (cellValues) => {
            return (
                <Grid container justifyContent="center">
                    <Avatar
                        src={`${process.env.REACT_APP_API_ENDPOINT}img/${cellValues.row.avatar}`}
                        style={{
                            height: "35px",
                            width: "35px",
                            backgroundSize: "cover",
                            objectFit: "cover"
                        }}/>
                </Grid>
            )
        }
    },
    {
        field: 'userLogin',
        headerName: 'userPageFormFieldLogin',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.login || ''}>
                        <span>
                            {cellValues.row.login || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.login
    },
    {
        field: 'userLastName',
        headerName: 'userPageFormFieldName',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.lastName || ''}>
                        <span>
                            {cellValues.row.lastName || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.lastName
    },
    {
        field: 'userFirstName',
        headerName: 'userPageFormFieldFirstName',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.firstName || ''}>
                        <span>
                            {cellValues.row.firstName || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.firstName
    },
    {
        field: 'userMail',
        headerName: 'userPageFormFieldEmail',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.mail || ''}>
                        <span>
                            {cellValues.row.mail || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.mail
    },
    {
        field: 'userLangage',
        headerName: 'userPageFormFieldLanguage',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 120,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.language?.label || ''}>
                        <span>
                            {cellValues.row.language?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.language?.code
    },
    {
        field: 'userRepresentative',
        headerName: 'userPageFormFieldRepresentative',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 120,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.representative?.label || ''}>
                        <span>
                            {cellValues.row.representative?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.representative?.label
    },
    {
        field: 'userLinkedCustomer',
        headerName: 'userPageFormFieldLinkedCustomer',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 120,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.linkedCustomer?.businessName || ''}>
                        <span>
                            {cellValues.row.linkedCustomer?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.linkedCustomer?.businessName
    },
    {
        field: 'userRole',
        headerName: 'userListPageGridColRole',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.role?.label || ''}>
                    <GetUserRoleButton dataGridMode userRoleCode={cellValues.row.role?.code}/>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.role?.code
    },
    {
        field: 'userStatus',
        headerName: 'userListPageGridColStatus',
        headerAlign: "center",
        align: "center",
        type: "string",
        width: 200,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.status?.label || ''}>
                    <GetUserStatusButton dataGridMode userStatus={cellValues.row.status?.value}/>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.status?.value
    }
]