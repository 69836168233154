import React, {useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    Paper,
    TextField
} from "@mui/material";
import ReturnButton from "../../Utils/ReturnButton";
import {DividingHR} from "../../Utils/DividingHR";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import moment, {Moment} from "moment/moment";
import {FormattedMessage} from "react-intl";
import messages from "../../../../../i18n/messages";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {Representative} from "../../../../../corelogic/models/parameter";
import {TextValueField} from "../../Utils/TextValueField";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../store/selectors/sessionSelector";

export interface HomeHeaderProps {
    sendValues?: (startingDate: Moment, endingDate: Moment, representative: Representative | null) => void,
    representatives?: Representative[]
}

const HomeHeader = (props: HomeHeaderProps) => {
    const dateFormat = "DD/MM/YYYY"

    const {sendValues, representatives = []} = props
    const {userLogged} = useSelector((getUserLoggedSelector))
    const [representativeSelected, setRepresentativeSelected] = useState<Representative | null>(userLogged?.representative ? userLogged.representative : null)
    const [startingDate, setStartingDate] = useState<Moment | null>(moment().subtract(15, 'd'))
    const [endingDate, setEndingDate] = useState<Moment | null>(moment())
    const [dateError, setDateError] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState(false)
    const [fullWidth, setFullWidth] = useState(true)
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('xs')
    const btnRef = useRef<HTMLButtonElement>(null)
    useEffect(() => {
        if (startingDate && endingDate) {
            if (startingDate > endingDate) {
                setDateError(true)
            } else {
                setDateError(false)
            }
        } else {
            setDateError(true)
        }
    }, [endingDate, startingDate])

    const handleChangeEndingDate = (newValue: Moment | null) => {
        setEndingDate(newValue)
    }

    const handleChangeStartingDate = (newValue: Moment | null) => {
        setStartingDate(newValue)
    }

    const handleKeyUpInputFieldDate = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === "Enter")
            handleSendDates()
    }
    const handleChangeRepresentative = (newValue: Representative | null) => {
        setRepresentativeSelected(newValue)
        btnRef?.current?.focus()
    }

    const handleSendDates = () => {
        if (startingDate && endingDate) {
            if (sendValues) {
                sendValues(startingDate, endingDate, representativeSelected)
            }
        }
        handleCloseModal()
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <>
            <Grid container alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleOpenModal} startIcon={<EventRepeatIcon/>}>Changer
                                période</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextValueField
                        style={{
                            marginLeft: 1,
                            fontWeight: "bolder"
                        }}
                        text={""}
                        separator={""}
                        value={`${startingDate?.format(dateFormat)} - ${endingDate?.format(dateFormat)}` + (representativeSelected ? ` - ${representativeSelected.label}` : "")}/>
                </Grid>
            </Grid>
            {openModal &&
                <Dialog
                    open
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    keepMounted
                >
                    <DialogTitle sx={{textAlign: "center"}}>
                        Changer la periode
                    </DialogTitle>
                    <DividingHR/>
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Paper sx={{p: 1}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Box sx={{display: "flex", alignItems: "center", p: 1}}>
                                            <DatePicker
                                                label="Starting date"
                                                inputFormat="DD/MM/YYYY"
                                                disableFuture
                                                value={startingDate}
                                                onChange={handleChangeStartingDate}
                                                renderInput={(params) => <TextField fullWidth {...params}
                                                                                    variant="standard"
                                                                                    onKeyUp={handleKeyUpInputFieldDate}
                                                />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper sx={{p: 1}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Box sx={{display: "flex", alignItems: "center", p: 1}}>
                                            <DatePicker
                                                label="Ending date"
                                                inputFormat="DD/MM/YYYY"
                                                disableFuture
                                                value={endingDate}
                                                onChange={handleChangeEndingDate}
                                                renderInput={(params) => <TextField fullWidth {...params}
                                                                                    variant="standard"
                                                                                    onKeyUp={handleKeyUpInputFieldDate}
                                                />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper sx={{p: 1}}>
                                    <Box sx={{display: "flex", alignItems: "center", p: 1}}>
                                        <Autocomplete
                                            disabled={!!userLogged?.representative}
                                            options={representatives}
                                            onChange={(event, param) => {
                                                handleChangeRepresentative(param ? param : null)
                                            }}
                                            fullWidth
                                            value={representatives.find(r => r.id === representativeSelected?.id)}
                                            getOptionLabel={(option => option.label || "")}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            renderInput={(params) => <TextField {...params}
                                                // label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldBusinessForm"))}
                                                                                label={"Représentant"}
                                                                                variant="standard"
                                            />}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button variant="contained" color="error"
                                            onClick={handleCloseModal} ><FormattedMessage
                                        id={messages.genericCancel.id}/></Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleSendDates} ref={btnRef}><FormattedMessage
                                        id={messages.genericConfirm.id}/></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </>
    );
};

export default HomeHeader;