import {AuthenticationGateway} from "corelogic/gateways/AuthenticationGateway";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "store/appState";
import {apiErrorsGestion} from "store/utils";

export const userLogin = (login: string, password: string):
    ThunkResult<{ authenticationGateway: AuthenticationGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {authenticationGateway}) => {
        try {
            dispatch({type: APP_ACTIONS_TYPES.session.LOGIN_FETCHING})
            const token = await authenticationGateway.login(login, password)
            dispatch({type: APP_ACTIONS_TYPES.session.SESSION_AUTH_TOKEN, payload: token})
            const currentUser = await authenticationGateway.retrieveCurrent()
            dispatch({type: APP_ACTIONS_TYPES.session.SESSION_LOGIN, payload: currentUser})
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}

export const userLogout = ():
    ThunkResult<{ authenticationGateway: AuthenticationGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {authenticationGateway}) => {
        try {
            await authenticationGateway.logout()
            dispatch({type: APP_ACTIONS_TYPES.session.SESSION_LOGOUT})
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}

export const authenticationRefreshToken = ():
    ThunkResult<{ authenticationGateway: AuthenticationGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {authenticationGateway}) => {
        try {
            const token = await authenticationGateway.refreshingToken()
            dispatch({type: APP_ACTIONS_TYPES.session.SESSION_AUTH_TOKEN, payload: token})
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}

export const retrieveCurrentlyLoggedInUser = ():
    ThunkResult<{ authenticationGateway: AuthenticationGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {authenticationGateway}) => {
        try {
            dispatch({type: APP_ACTIONS_TYPES.session.LOGIN_FETCHING})
            const currentUser = await authenticationGateway.retrieveCurrent()
            dispatch({type: APP_ACTIONS_TYPES.session.SESSION_LOGIN, payload: currentUser})
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}