import {AppState} from "store/appState";

export const getCustomersSelector = (state: AppState) => {
    return {
        data: state.customers.data,
        fetching: state.customers.fetching
    }
}

export const getCustomersFamiliesSelector = (state: AppState) => {
    return {
        dataFamilies: state.customers.dataFamilies,
        fetching: state.customers.fetching
    }
}

export const getCustomerSelectedSelector = (state: AppState) => {
    return {
        customerSelected: state.customers.selected,
        fetchingOnAction: state.customers.fetchingOnAction,
        fetchingAddressesSelected: state.customers.addressesFetching,
    }
}

export const getCustomerSelectedDeliveryAddresses = (state: AppState) => {
    return {
        customerSelectedAddresses: state.customers.selected?.deliveryAddresses,
        fetchingAddressesSelected: state.customers.fetchingOnAction,
    }
}

export const getCustomerFormSelector = (state: AppState) => {
    return {
        dataFormFields: state.customers.dataFormFields
    }
}