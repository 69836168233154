import React, {useState} from "react";
import {Alert, Button, Container, Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import BuildIcon from "@mui/icons-material/Build";
import {FormattedMessage} from "react-intl";
import messages from "../../../../../i18n/messages";
import {launchImport} from "../../../../../corelogic/usecase/admin/adminActions";
import {getCustomerSpecificData, getServerVersion} from "../../../../../store/selectors/serverSelector";
import {CustomerSpecificCode} from "../../../../../corelogic/models/CustomerSpecificData";

export const AdminPage = () => {
    const dispatch = useDispatch()
    const {serverVersion} = useSelector(getServerVersion)
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const [alertVisible, setAlertVisible] = useState(false)

    const handleRunImportClick = () => {
        dispatch(launchImport())
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 5000)
    }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1} p={4}>
                <Grid item md={3}>
                    <Button variant="contained" onClick={handleRunImportClick}>
                        <BuildIcon sx={{marginRight: "8px"}}/>
                        <FormattedMessage id={messages.adminToolsImportStartRun.id}/>
                    </Button>
                </Grid>
                <Grid item>
                    {alertVisible && <Alert severity="info">
                        <FormattedMessage id={messages.adminToolsImportLaunched.id}/>
                    </Alert>}
                </Grid>
            </Grid>
            <Grid container spacing={1} p={4}>
                <Grid item md={3}>
                    {`Version du serveur: ${serverVersion}`}
                </Grid>
                <Grid item md={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        {`Client spécifique: ${CustomerSpecificCode[customerSpecificData?.customerSpecificCode || CustomerSpecificCode.NONE]}`}
                        </Grid>
                        <Grid item xs={12}>
                        {`Top1 spécifique: ${customerSpecificData?.top1}`}
                        </Grid>
                        <Grid item xs={12}>
                        {`Top2 spécifique: ${customerSpecificData?.top2}`}
                        </Grid>
                        <Grid item xs={12}>
                        {`Top3 spécifique: ${customerSpecificData?.top3}`}
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}