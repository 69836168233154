import {ItemPackaging} from "../../../../corelogic/models/item";

export interface ItemPackagingApiRequest {
    id?: number
    code?: string
    label?: string
    coefficient?: number
    authorizedForSale?: boolean
}

export interface ItemPackagingResponse {
    id: number
    code: string
    label: string
    coefficient: number
    authorizedForSale: boolean
}

export const mapItemPackagingToItemPackagingApiRequest = (itemPackaging: ItemPackaging): ItemPackagingApiRequest => {
    return {
        id: itemPackaging.id,
        code: itemPackaging.code,
        label: itemPackaging.label,
        coefficient: itemPackaging.coefficient,
        authorizedForSale: itemPackaging.authorizedForSale
    }
}

export const mapItemPackagingApiResponseToItemPackaging = (itemPackaging: ItemPackagingResponse): ItemPackaging => {
    return {
        ...itemPackaging,
        id: itemPackaging.id,
    }
}