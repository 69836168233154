import {AnyAction, applyMiddleware, combineReducers, createStore} from 'redux'

import {composeWithDevTools} from 'redux-devtools-extension'
import thunk, {ThunkMiddleware} from 'redux-thunk'
import {AppState} from 'store/appState'
import generics from './store/reducers/genericsReducer'
import session from './store/reducers/sessionReducer'
import items from './store/reducers/itemsReducer'
import orders from './store/reducers/ordersReducer'
import customers from './store/reducers/customersReducer'
import users from './store/reducers/usersReducer'
import societies from './store/reducers/societiesReducer'
import customerItemRefs from "./store/reducers/customerItemsReducer";
import parameters from "./store/reducers/parametersReducer";
import stats from "./store/reducers/statsReducer";
import server from "./store/reducers/serverReducer";

export const configureStore = (dependencies: any) => {
    return createStore(combineReducers({
            generics,
            session,
            items,
            orders,
            customers,
            users,
            societies,
            customerItemRefs,
            parameters,
            stats,
            server
        }), composeWithDevTools(applyMiddleware(
            (thunk.withExtraArgument(dependencies) as ThunkMiddleware<AppState, AnyAction>)
        ))
    )
}