import {useDispatch, useSelector} from "react-redux";
import {getItemsSelector} from "../../../../../../store/selectors/itemsSelectors";
import {useIntl} from "react-intl";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Item} from "../../../../../../corelogic/models/item";
import {addOrderLine} from "../../../../../../corelogic/usecase/orders/ordersActions";
import {Autocomplete, Button, Grid, InputLabel, Paper, Select, SelectChangeEvent, TextField} from "@mui/material";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {InputNumberField} from "adapters/primary/Components/Utils/TextValueField";

interface OrderLineForm {
    item?: Item
    quantity?: number
    package?: string | undefined
}

interface FastLineAddInt {
    dataOrderId: number
}

export const FastLineAdd = (props: FastLineAddInt) => {
    const {dataOrderId} = props
    const dispatch = useDispatch()
    const {data} = useSelector(getItemsSelector)
    const intl = useIntl()
    const itemSelectDOM = useRef<HTMLInputElement | null>(null)
    const itemQuantityDOM = useRef<HTMLInputElement | null>(null)
    const [lineForAdd, setLineForAdd] = useState<OrderLineForm>({item: undefined, quantity: undefined, package: ""})
    const [autoCompleteItemValue, setAutoCompleteItemValue] = React.useState<string[]>([])
    const PACKAGE = "PACKAGE"
    const [packageSelected, setPackageSelected] = useState<string | undefined>("")

    useEffect(() => {
        if (!lineForAdd.item && !lineForAdd.quantity) {
            itemSelectDOM.current?.focus()
        }
        if (lineForAdd.item && !lineForAdd.quantity) {
            itemQuantityDOM.current?.focus()
        }
    }, [lineForAdd.item, lineForAdd.quantity])

    const resetValues = () =>{
        setLineForAdd({})
        setAutoCompleteItemValue([])
        setPackageSelected("")
    }

    const getAutoCompleteItemValue = (e: React.SyntheticEvent<Element, Event>, value: string | null | undefined) => {
        const itemSelected = data.find(is => is.code.toUpperCase() === value?.toUpperCase())
        if (value) {
            setAutoCompleteItemValue([value])
        }
        if (itemSelected) {
            setLineForAdd({...lineForAdd, item: itemSelected, package: itemSelected.orderUnit?.code})
            setPackageSelected(itemSelected.orderUnit?.code)
        }
    }

    const handleChangeFastLineAddQuantity = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const input = Number(e.target.value)
        setLineForAdd({...lineForAdd, quantity: input})
    }

    const handleAddLineFromFastAdd = () => {
        if (lineForAdd.quantity && lineForAdd.item && lineForAdd.quantity > 0 && lineForAdd.package) {
            dispatch(addOrderLine(dataOrderId, lineForAdd.item.id, lineForAdd.quantity, lineForAdd.package))
            resetValues()
        }
    }

    const handleChangeQuantityType = (event: SelectChangeEvent) => {
        setPackageSelected(event.target.value)
    }

    const handleEnterKeyOnQuantity = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            handleAddLineFromFastAdd()
        }
    }

    const isDataValidForSubmit = () => lineForAdd.quantity && lineForAdd.item && lineForAdd.quantity > 0 && lineForAdd.package

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <Paper>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={data.map(x => x.code)}
                        onInputChange={(event, newInputValue, reason) => {
                             if (reason === "clear") {
                                resetValues()
                            }
                        }}
                        onChange={(e, value) => getAutoCompleteItemValue(e, value?.toUpperCase())}
                        value={autoCompleteItemValue[0] ? autoCompleteItemValue[0] : null}
                        renderInput={(params) => <TextField {...params}
                                                            label={intl.formatMessage(getMessageDescriptor("orderLineGridColItemCodeLabel"))}
                                                            inputRef={itemSelectDOM}
                                                            variant="standard"/>}
                    />
                </Paper>
            </Grid>
            <Grid item xs={2.5}>
                <Paper>
                    <TextField id="filled-basic"
                               fullWidth
                               label={intl.formatMessage(getMessageDescriptor("orderLineGridColItemQuantity"))}
                               variant="standard"
                               value={lineForAdd?.quantity || ""}
                               inputRef={itemQuantityDOM}
                               InputProps={{
                                   inputComponent: InputNumberField as any
                               }}
                               onChange={handleChangeFastLineAddQuantity}
                               onKeyDown={handleEnterKeyOnQuantity}
                               error={lineForAdd.item && lineForAdd?.quantity === 0}
                               helperText={lineForAdd.item && lineForAdd?.quantity === 0 ?
                                   intl.formatMessage(getMessageDescriptor("genericIncorrectValue"))
                                   : ""}/>
                </Paper>
            </Grid>
            <Grid item xs={2.5}>
                <Paper>
                    <FormControl fullWidth>
                        <InputLabel>{intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}</InputLabel>
                        <Select
                            sx={{height: "48px"}}
                            value={packageSelected}
                            defaultValue={""}
                            onChange={handleChangeQuantityType}
                            label={intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}>
                            {lineForAdd?.item?.authorizationToSellByConsumerUnit &&
                                <MenuItem value={lineForAdd.item.orderUnit?.code}>{lineForAdd.item.orderUnit?.label}
                                </MenuItem>}
                            {lineForAdd?.item?.authorizationToSellByPackage &&
                                <MenuItem value={PACKAGE}>{`Colis (x ${lineForAdd.item.byHowMuch})`}</MenuItem>
                            }
                            {lineForAdd?.item?.authorizationToSellByPackage && lineForAdd?.item?.packagings.map((x) =>
                                <MenuItem key={x.id} value={x.code}>{x.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Paper>
            </Grid>
            <Grid item xs={1.5}>
                <Paper>
                    <TextField id="filled-basic"
                               label={intl.formatMessage(getMessageDescriptor("orderLineGridColUnitPriceExclTax"))}
                               variant="standard"
                               disabled
                               value={`${lineForAdd.item?.itemPrice?.netPrice || 0} €`}/>
                </Paper>
            </Grid>
            <Grid item xs={1.5}>
                <Paper>
                    <TextField id="filled-basic"
                               label={intl.formatMessage(getMessageDescriptor("orderLineGridColTotalExclTax"))}
                               variant="standard"
                               disabled
                               value={`${lineForAdd.item?.itemPrice?.netPrice && lineForAdd.quantity ? lineForAdd.item?.itemPrice?.netPrice * lineForAdd.quantity : 0} €`}/>
                </Paper>
            </Grid>
            <Grid item xs={1}>
                <Paper sx={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Button variant="contained"
                            onClick={handleAddLineFromFastAdd}
                            disabled={!isDataValidForSubmit()}>
                        +
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    )
}