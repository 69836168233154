import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {
    Customer,
    CustomerAllFormData,
    CustomerFilter,
    CustomerForm,
    CustomerSuperFamiliesAndFamilies,
} from "../../../corelogic/models/customer";
import {CustomerGateway} from "../../../corelogic/gateways/CustomerGateway";
import {CustomerApiResponse, mapCustomerApiResponseToCustomer} from "./Model/customerApi";
import qs from "qs";
import {CustomerFamily} from "../../../corelogic/models/parameter";

export class ApiCustomerGateway implements CustomerGateway {
    retrieveById(id: number): Promise<Customer | undefined> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customer, id.toString())})
    }

    retrieveAll(): Promise<Customer[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customer)})
    }

    retrieveAllCustomersFamilies(): Promise<CustomerFamily[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.parameter, "customerFamily")})
    }

    retrieveAllReferencedCustomersSuperFamiliesAndFamilies(): Promise<CustomerSuperFamiliesAndFamilies[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customer)})
    }

    retrieveAllFiltered(filterValue: CustomerFilter): Promise<Customer[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customer, "filter"),
            params: {...filterValue},
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    create(customer: CustomerForm): Promise<Customer> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customer),
            method: "POST",
            data: JSON.stringify(customer)
        })
    }

    update(customer: CustomerForm): Promise<Customer> {
        return apiFetchApiResponse<CustomerApiResponse>({
            url: buildUrl(API_PREFIX.customer),
            method: "PUT",
            data: JSON.stringify(customer)
        })
            .then(x => mapCustomerApiResponseToCustomer(x))
    }

    delete(id: number): Promise<void> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customer, id.toString()), method: "DELETE"})
    }

    retrieveAllDataFormField(): Promise<CustomerAllFormData> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customer, "all-data")})
    }
}