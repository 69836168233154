import {AppState} from "store/appState";

export const getItemsSelector = (state: AppState) => {
    return {
        data: state.items.data,
        fetching: state.items.fetching,
        count:state.items.count
    }
}

export const getItemSelectedSelector = (state: AppState) => {
    return {
        itemSelected: state.items.selected,
        fetchingSelected: state.items.fetchingOnAction,
    }
}

export const getItemAllFormSelector = (state: AppState) => {
    return {
        dataFormFields: state.items.dataFormFields,
    }
}

export const getItemAllPackagingSelector = (state: AppState) => {
    return {
        packagingsSelected: state.items.selected?.packagings || [],
    }
}