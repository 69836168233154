import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {CustomerItemReferenceGateway} from "../../gateways/CustomerItemReferenceGateway";
import {ItemFilter} from "../../models/item";

export const retrieveAllItemRefByCustomerId = (filterValue: ItemFilter, customerId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVING})
        try {
            const itemsRefData = await customerItemReferenceGateway.retrieveAllItemRefByCustomerId(filterValue, customerId)
            dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED, payload: itemsRefData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveAllItemRefByCustomerSuperFamilyId = (filterValue: ItemFilter, customerSuperFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVING})
        try {
            const itemsRefData = await customerItemReferenceGateway.retrieveAllItemRefByCustomerSuperFamilyId(filterValue, customerSuperFamilyId)
            dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED, payload: itemsRefData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveAllItemRefByCustomerFamilyId = (filterValue: ItemFilter, customerFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVING})
        try {
            const itemsRefData = await customerItemReferenceGateway.retrieveAllItemRefByCustomerFamilyId(filterValue, customerFamilyId)
            dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED, payload: itemsRefData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllCustomersSuperFamiliesAndFamiliesReferenced = ():
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVING})
        try {
            const customersSuperFamiliesAndFamiliesReferencedData = await customerItemReferenceGateway.retrieveAllCustomersSuperFamiliesAndFamiliesReferenced()
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVED,
                payload: customersSuperFamiliesAndFamiliesReferencedData
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}


export const addCustomerItemReferenceForCustomer = (itemId: number, customerId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const addOk = await customerItemReferenceGateway.addForCustomer(itemId, customerId)
            if (addOk)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_ADD_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeCustomerItemReferenceForCustomer = (itemId: number, customerId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const removeOK = await customerItemReferenceGateway.removeForCustomer(itemId, customerId)
            if (removeOK)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_REMOVE_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}


export const addCustomerItemReferenceForCustomerSuperFamily = (itemId: number, customerSuperFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const addOk = await customerItemReferenceGateway.addForCustomerSuperFamily(itemId, customerSuperFamilyId)
            if (addOk)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_ADD_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeCustomerItemReferenceForCustomerSuperFamily = (itemId: number, customerSuperFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const removeOK = await customerItemReferenceGateway.removeForCustomerSuperFamily(itemId, customerSuperFamilyId)
            if (removeOK)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_REMOVE_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}


export const addCustomerItemReferenceForCustomerFamily = (itemId: number, customerFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const addOk = await customerItemReferenceGateway.addForCustomerFamily(itemId, customerFamilyId)
            if (addOk)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_ADD_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeCustomerItemReferenceForCustomerFamily = (itemId: number, customerFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            const removeOK = await customerItemReferenceGateway.removeForCustomerFamily(itemId, customerFamilyId)
            if (removeOK)
                dispatch({
                    type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_REMOVE_REFERENCE,
                    payload: itemId
                })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addAllCustomerItemReferenceForCustomerFromFilter = (filterValue: ItemFilter, customerId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.addAllForCustomerFromFilter(filterValue, customerId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerId(filterValue, customerId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeAllCustomerItemReferenceForCustomerFromFilter = (filterValue: ItemFilter, customerId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.removeAllForCustomerFromFilter(filterValue, customerId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerId(filterValue, customerId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}


export const addAllCustomerItemReferenceForCustomerFamilyFromFilter = (filterValue: ItemFilter, customerFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.addAllForCustomerFamilyFromFilter(filterValue, customerFamilyId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerFamilyId(filterValue, customerFamilyId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeAllCustomerItemReferenceForCustomerFamilyFromFilter = (filterValue: ItemFilter, customerFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.removeAllForCustomerFamilyFromFilter(filterValue, customerFamilyId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerFamilyId(filterValue, customerFamilyId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}


export const addAllCustomerItemReferenceForCustomerSuperFamilyFromFilter = (filterValue: ItemFilter, customerSuperFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.addAllForCustomerSuperFamilyFromFilter(filterValue, customerSuperFamilyId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerSuperFamilyId(filterValue, customerSuperFamilyId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeAllCustomerItemReferenceForCustomerSuperFamilyFromFilter = (filterValue: ItemFilter, customerSuperFamilyId: number):
    ThunkResult<{ customerItemReferenceGateway: CustomerItemReferenceGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerItemReferenceGateway}) => {
        try {
            await customerItemReferenceGateway.removeAllForCustomerSuperFamilyFromFilter(filterValue, customerSuperFamilyId)
            const itemListAfter = await customerItemReferenceGateway.retrieveAllItemRefByCustomerSuperFamilyId(filterValue, customerSuperFamilyId)
            dispatch({
                type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED,
                payload: itemListAfter
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}