import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Paper,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DividingHR} from "../../../Utils/DividingHR";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import {TextValueField} from "../../../Utils/TextValueField";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {
    addItemPackaging,
    deleteItemPackaging,
    retrieveAllItemPackagings,
    updateItemPackaging
} from "../../../../../../corelogic/usecase/items/itemsActions";
import {ItemPackaging, ItemPackagingForm} from "../../../../../../corelogic/models/item";
import {getItemAllPackagingSelector} from "../../../../../../store/selectors/itemsSelectors";
import BackpackIcon from '@mui/icons-material/Backpack';

interface ItemPackagingComponentInterfaceProps {
    itemId: number
}

const ItemPackagingComponent = (props: ItemPackagingComponentInterfaceProps) => {
    const {itemId} = props
    const dispatch = useDispatch()
    const {packagingsSelected} = useSelector(getItemAllPackagingSelector)
    const blankPackaging: ItemPackagingForm = {
        code: "",
        label: "",
        coefficient: 1,
        authorizedForSale: true
    }
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [packagingSelected, setPackagingSelected] = useState<ItemPackagingForm>(blankPackaging)

    useEffect(() => {
        dispatch(retrieveAllItemPackagings(itemId))
    }, [dispatch, itemId])

    const handleCancelEditMode = () => {
        reset()
    }

    const handleValidate = (packagingForm: ItemPackagingForm) => {
        if (isEditMode) {
            dispatch(updateItemPackaging(packagingForm))
        } else {
            dispatch(addItemPackaging(itemId, packagingForm))
        }
        reset()
    }

    const handleUpdate = (itemPackagingToUpdate: ItemPackagingForm) => {
        setPackagingSelected(itemPackagingToUpdate)
        setIsEditMode(true)
    }

    const handleDuplicate = (itemPackagingToDuplicate: ItemPackagingForm) => {
        setPackagingSelected(itemPackagingToDuplicate)
        setIsEditMode(false)
    }

    const handleDelete = (itemPackagingId: number) => {
        dispatch(deleteItemPackaging(itemPackagingId))
        reset()
    }

    const reset = () => {
        setPackagingSelected(blankPackaging)
        setIsEditMode(false)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <ItemPackagingFormComponent editModeState={isEditMode}
                                            onCancelClick={handleCancelEditMode}
                                            onValidateClick={handleValidate}
                                            packagingSelectedState={packagingSelected}/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <ItemPackagingAccordeonComponent dataPackagings={packagingsSelected}
                                                 onUpdate={handleUpdate}
                                                 onDuplicate={handleDuplicate}
                                                 onDelete={handleDelete}/>
            </Grid>
        </Grid>
    )
}

export default ItemPackagingComponent;

interface ItemPackagingFormComponentInterfaceProps {
    onCancelClick?: () => void
    onValidateClick?: (itemPackaging: ItemPackagingForm) => void
    editModeState?: boolean
    packagingSelectedState: ItemPackagingForm
}

export const ItemPackagingFormComponent = (props: ItemPackagingFormComponentInterfaceProps) => {
    const {onCancelClick, packagingSelectedState, editModeState, onValidateClick} = props
    const intl = useIntl()
    const codeInputFocus = useRef<HTMLInputElement | null>(null)

    const schema = yup.object({
        code: yup.string().required("This field is required").default(packagingSelectedState.code || ""),
        label: yup.string().required("This field is required").default(packagingSelectedState.label || ""),
        coefficient: yup.number().required("This field is required").default(packagingSelectedState.coefficient || 1),
        authorizedForSale: yup.boolean().default(packagingSelectedState.authorizedForSale),
    }).required()

    const {handleSubmit, formState: {errors}, control, reset} = useForm<ItemPackagingForm>({
        resolver: yupResolver(schema)
    })

    useEffect(() => {
        reset(packagingSelectedState)
        codeInputFocus.current?.focus()
    }, [reset, packagingSelectedState])

    const validateForm = (data: ItemPackagingForm) => {
        if (onValidateClick) {
            onValidateClick(data)
        }
    }

    const handleReset = () => {
        if (onCancelClick) {
            onCancelClick()
        }
    }

    return (
        <Paper elevation={5}>
            <Grid container justifyContent="center" alignItems="center" p={1}>
                {editModeState ?
                    <>
                        <EditIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 5}}/>
                        <Typography variant="h5" component="h2" fontWeight="bolder">
                            {intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabEditPackage"))}
                        </Typography>
                    </>
                    :
                    <>
                        <AddCircleIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 5}}/>
                        <Typography variant="h5" component="h2" fontWeight="bolder">
                            {intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabAddPackage"))}
                        </Typography>
                    </>
                }
            </Grid>
            <DividingHR style={{marginTop: 0}}/>
            <Grid container>
                <Grid item sm={12} p={3}>
                    <Paper elevation={3}>
                        <form onSubmit={handleSubmit(validateForm)} onReset={handleReset}>
                            <Grid container direction="column" p={3} spacing={2}>
                                <Grid item>
                                    <Controller
                                        name="code"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                inputRef={codeInputFocus}
                                                fullWidth
                                                autoFocus
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCode"))}
                                                type="text"
                                                defaultValue={packagingSelectedState.code || ""}
                                                error={!!errors.code}
                                                helperText={errors.code?.message}
                                                InputLabelProps={{shrink: !!field.value}}
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        name="label"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldLabel"))}
                                                type="text"
                                                defaultValue={packagingSelectedState.label || ""}
                                                error={!!errors.label}
                                                helperText={errors.label?.message}
                                                InputLabelProps={{shrink: !!field.value}}
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        name="coefficient"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCoefficient"))}
                                                type="number"
                                                defaultValue={packagingSelectedState.coefficient || 1}
                                                error={!!errors.coefficient}
                                                helperText={errors.coefficient?.message}
                                                InputLabelProps={{shrink: !!field.value}}
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldAuthorizedForSale"))}
                                        sx={{margin: "10px"}}
                                        control={
                                            <Controller
                                                name="authorizedForSale"
                                                control={control}
                                                defaultValue={packagingSelectedState.authorizedForSale}
                                                render={({field: props}) => (
                                                    <Checkbox
                                                        {...props}
                                                        checked={props.value}
                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container justifyContent="flex-end" p={2}>
                                        <Button type="reset" variant="outlined"
                                                color="error" sx={{marginRight: "10px"}}>
                                            <Typography component="div">
                                                <FormattedMessage id={messages.genericCancel.id}/>
                                            </Typography>
                                        </Button>
                                        <Button type="submit" variant="outlined"
                                                sx={{marginLeft: "10px"}}>
                                            <Typography component="div">
                                                <FormattedMessage id={messages.genericConfirm.id}/>
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

interface ItemPackagingAccordeonComponentInterfaceProps {
    onUpdate?: (packagingToUpdate: ItemPackagingForm) => void
    onDuplicate?: (packagingToDuplicate: ItemPackagingForm) => void
    onDelete?: (packagingToDeleteId: number) => void
    dataPackagings: ItemPackaging[]
}

export const ItemPackagingAccordeonComponent = (props: ItemPackagingAccordeonComponentInterfaceProps) => {
    const {dataPackagings, onUpdate, onDuplicate, onDelete} = props
    const intl = useIntl()
    const [openDeletionModale, setOpenDeletionModale] = useState<boolean>(false)
    const [packagingToDeleteDisplay, setPackagingToDeleteDisplay] = useState<ItemPackagingForm>({
        code: "",
        label: "",
        coefficient: 1,
        authorizedForSale: true
    })

    const handleCloseModal = () => {
        setOpenDeletionModale(false)
    }

    const handleUpdate = (e: MouseEvent<HTMLElement>, itemPackagingSelected: ItemPackaging) => {
        e.stopPropagation()
        if (onUpdate) {
            onUpdate(itemPackagingSelected)
        }
    }

    const handleDuplicate = (e: MouseEvent<HTMLElement>, itemPackagingSelected: ItemPackaging) => {
        e.stopPropagation()
        if (onDuplicate) {
            onDuplicate({...itemPackagingSelected, id: undefined})
        }
    }

    const handleDelete = (e: MouseEvent<HTMLElement>, itemPackaging: ItemPackaging) => {
        e.stopPropagation()
        setPackagingToDeleteDisplay(itemPackaging)
        setOpenDeletionModale(true)
    }

    const handleConfirmDeletion = () => {
        setOpenDeletionModale(false)
        if (onDelete && packagingToDeleteDisplay.id) {
            onDelete(packagingToDeleteDisplay.id)
        }
    }

    const deletionModale = () => {
        return (
            <Dialog
                open={openDeletionModale}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabConfirmDeletion"))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabConfirmDeletionConfirm"))}</Alert>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <Alert
                            severity="info">{intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabConfirmDeletionCancel"))}</Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="error"
                            variant="outlined">{intl.formatMessage(getMessageDescriptor("genericCancel"))}</Button>
                    <Button onClick={handleConfirmDeletion} autoFocus
                            variant="outlined">{intl.formatMessage(getMessageDescriptor("genericConfirm"))}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Paper elevation={5}>
                <Grid container justifyContent="center" alignItems="center" p={1}>
                    <BackpackIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 5}}/>
                    <Typography variant="h5" component="h2" fontWeight="bolder">
                        {intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabPackageListHeader"))}
                    </Typography>
                </Grid>
                <DividingHR style={{marginTop: 0}}/>
                <Grid container>
                    <Grid item sm={12} p={3}>
                        {dataPackagings.length > 0 ? dataPackagings.map(ip =>
                                <Accordion key={ip.id}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography component="div" color="text.primary" fontWeight="bolder">
                                                    {ip.label}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Paper elevation={3}>
                                                    <Button onClick={(e) => handleUpdate(e, ip)}><EditIcon/></Button>
                                                    <Button onClick={(e) => handleDuplicate(e, ip)}><FileCopyIcon/></Button>
                                                    <Button onClick={(e) => handleDelete(e, ip)}><DeleteIcon
                                                        sx={{fill: "rgba(255, 82, 82, 1)"}}/></Button>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <DividingHR subContent style={{marginTop: 0}}/>
                                    <AccordionDetails>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCode"))}
                                                    value={ip.code}/>
                                            </Grid>
                                            <Grid item>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCoefficient"))}
                                                    value={ip.coefficient}/>
                                            </Grid>
                                            <Grid item>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldAuthorizedForSale"))}
                                                    value={ip.authorizedForSale ?
                                                        intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldAuthorizedForSaleTrue"))
                                                        :
                                                        intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldAuthorizedForSaleFalse"))}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>)
                            :
                            <Alert severity="info">
                                {intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabNoPackagingFound"))}
                            </Alert>
                        }
                    </Grid>
                </Grid>
            </Paper>
            {openDeletionModale && deletionModale()}
        </>
    )
}