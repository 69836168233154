import React, {useState} from 'react';
import {Button, Grid} from "@mui/material";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {FormattedMessage, useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useNavigate} from "react-router-dom";

interface UserListPageHeaderInterface {
    counterUsers: number
}

const UserListPageHeader = (props: UserListPageHeaderInterface) => {
    const {counterUsers} = props
    const intl = useIntl()
    const navigate = useNavigate()
    const [inputSearch, setInputSearch] = useState("")

    const handleCreateNewUser = () => {
        navigate("/users/creation")
    }

    const handleSearchBarChange = (input: string) => {
        setInputSearch(input)
    }

    const handleResetSearchBar = () => {
        setInputSearch("")
    }

    return (
        <Grid container justifyContent="space-between" alignItems="center" p={1}>
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <ReturnButton/>
                    </Grid>
                    <Grid item mx={2}>
                        <DividingHR
                            subContent
                            style={{height: "30px", width: 1, margin: 0}}/>
                    </Grid>
                    <Grid item>
                        <TextValueField
                            style={{
                                fontWeight: "bolder"
                            }}
                            text="Utilisateurs"
                            value={counterUsers}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item>
                        <Button variant="contained" onClick={handleCreateNewUser}>
                            <PersonAddIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                            <FormattedMessage {...getMessageDescriptor("userListPageHeaderCreateUser")}/>
                        </Button>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <SearchBar label={intl.formatMessage(getMessageDescriptor("userListPageHeaderSearchUser"))}*/}
                    {/*               value={inputSearch}*/}
                    {/*               onChange={handleSearchBarChange} onResetClick={handleResetSearchBar}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserListPageHeader;