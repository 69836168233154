import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {ParameterGateway} from "../../gateways/ParameterGateway";

export const retrieveCustomerSuperFamilyById = (id: number):
    ThunkResult<{ parameterGateway: ParameterGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {parameterGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.parameters.PARAMETER_FETCHING})
        try {
            const parametersData = await parameterGateway.retrieveCustomerSuperFamilyById(id)
            dispatch({type: APP_ACTIONS_TYPES.parameters.PARAMETER_RETRIEVED, payload: parametersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveCustomerFamilyById = (id: number):
    ThunkResult<{ parameterGateway: ParameterGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {parameterGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.parameters.PARAMETER_FETCHING})
        try {
            const parametersData = await parameterGateway.retrieveCustomerFamilyById(id)
            dispatch({type: APP_ACTIONS_TYPES.parameters.PARAMETER_RETRIEVED, payload: parametersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
