import {AppState} from "store/appState";

export const getOrdersSelector = (state: AppState) => {
    return {
        data: state.orders.data,
        fetching: state.orders.fetching
    }
}

export const getOrderLinesSelector = (state: AppState) => {
    return {
        dataOrderLines: state.orders.selectedLines,
        fetchingOrderLines: state.orders.fetchingOrderLines
    }
}

export const getOrderSelectedSelector = (state: AppState) => {
    return {
        orderSelected: state.orders.selected,
        fetchingSelected: state.orders.fetchingOnAction
    }
}

export const getOrderCommentsSelector = (state: AppState) => {
    return {
        dataOrderComments: state.orders.activeComments,
        fetchingOrderComments: state.orders.fetchingOrderComments
    }
}