import React from 'react';

interface LinkMailToProps {
    mailto: string,
    label: string
}

const LinkMailTo = (props: LinkMailToProps) => {
    const {mailto, label} = props
    return (
        <a href={`mailto:${mailto}`}>{label}</a>
        // <Link
        //     to={mailto}
        //     onClick={(e) => {
        //         window.location.href = mailto
        //         e.preventDefault()
        //     }}
        // >
        //     {label}
        // </Link>
    );
};

export default LinkMailTo;