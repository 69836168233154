import {Item} from "../../../../../../corelogic/models/item";
import * as React from "react";
import {useEffect, useState} from "react";
import {generatePath, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {Image, UserRoleCode} from "../../../../../../corelogic/models/user";
import {Grid, Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import {DividingHR} from "../../../Utils/DividingHR";
import {truncateStringWithDot} from "../../../Utils/Utils";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SellIcon from "@mui/icons-material/Sell";
import {TextValueField, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {ItemDetailsDialog} from "../ItemDetailsPage/ItemDetailsDialog";
import moment from "moment";

export const ItemCard = (props: { data: Item, onToggleFavorite: (item: Item) => void }) => {
    const {data, onToggleFavorite} = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const navigation = useNavigate()
    const intl = useIntl()
    const [imageValue64Format, setImageValue64Format] = useState<string | undefined>("")
    const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
    useEffect(() => {
        // On récupère l'avatar de l'user si il en a déjà un en bdd
        if (props.data.picture) {
            //Json stringify l'objet de type File pour pouvoir le manipuler
            const imageObjectToJsonString = JSON.stringify(props.data.picture)
            const imageJsonStringToObject: Image = JSON.parse(imageObjectToJsonString)
            //On envoit la value en base64 dans le use state pour preview image
            setImageValue64Format(imageJsonStringToObject.image)
        }
    }, [imageValue64Format, props.data.picture])


    const handleDialogItemDetailsOpen = () => {
        setOpenItemDetails(true)
    }

    const handleDialogItemDetailsClose = () => {
        setOpenItemDetails(false)
    }

    const handleToggleFavorite = () => {
        onToggleFavorite(data)
    }

    const handleDetailedProduct = () => {
        navigation(generatePath("/items/:itemId", {itemId: String(data.id)}))
    }

    return (
        <Card sx={{width: "270px", m: 1, p: 1, pb: 0}}>
            <Grid container>
                <Grid item xs={data.deletionDate ? 11 : 12}>
                    <CardHeader sx={{display: "contents"}} title={
                        <Tooltip title={data.label}>
                            <Typography width="100%" noWrap textAlign="center" fontWeight="bolder"
                                        className="item-card-title text-lower-first-letter-up">
                                {data.label}
                            </Typography>
                        </Tooltip>
                    }/>
                </Grid>
                {data.deletionDate && <Grid item xs={1}>
                    <Tooltip
                        title={intl.formatMessage(getMessageDescriptor("deleteOn"), {date: moment(data.deletionDate).format("DD/MM/YYYY")})}>
                        <DeleteIcon sx={{fill: "rgba(255, 82, 82, 1)"}}/>
                    </Tooltip>
                </Grid>}
                <Grid item xs={12} container justifyContent="center" sx={{my: 1}}>
                    <CardMedia
                        component="img"
                        image={`${process.env.REACT_APP_API_ENDPOINT}img/items/${data.code.replace("/", "_")}.jpg`}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null
                            currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                        }}
                        alt={data.label}
                        sx={{borderRadius: "5px", width: "auto", height: "150px"}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={data.code}>
                        <Typography>
                            <TextValueField
                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldProductCode"))}
                                value={data.code ? data.code : ""}/>
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={data.brand?.label}>
                        <Typography>
                            <TextValueField
                                text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldBrandTitle"))}
                                value={truncateStringWithDot(data.brand?.label ? data.brand.label : "", 0, 15)}/>
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={data.superFamily?.label}>
                        <Typography>
                            <TextValueField
                                text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldSuperFamilyTitle"))}
                                value={truncateStringWithDot(data.superFamily?.label ? data.superFamily.label : "", 0, 15)}/>
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={data.family?.label}>
                        <Typography>
                            <TextValueField
                                text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldFamilyTitle"))}
                                value={truncateStringWithDot(data.family?.label ? data.family.label : "", 0, 15)}/>
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={data.orderUnit?.label}>
                        <Typography>
                            <TextValueField
                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldOrderingUnits"))}
                                value={data.orderUnit?.label}/>
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <TextValueFieldMonetary
                        text={intl.formatMessage(getMessageDescriptor("itemDetailRateGrossPrice"))}
                        value={data.itemPrice?.grossPrice}/>
                </Grid>
                <Grid item xs={12}>
                    <DividingHR subContent/>
                </Grid>
                <Grid item xs={12} container justifyContent="space-between">
                    <Grid item xs={6} container justifyContent="start">
                        <IconButton aria-label="add to favorites" onClick={handleToggleFavorite}>
                            {data.favorite ?
                                <FavoriteIcon style={{fill: "rgba(255, 82, 82, 1)", transition: "ease"}}/> :
                                <FavoriteIcon/>}
                        </IconButton>
                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER &&
                            <IconButton aria-label="is sellable">
                                <SellIcon style={{
                                    fill: data.authorizationToSellByConsumerUnit && data.authorizationToSellByPackage ? "rgba(255, 82, 82, 1)" : "grey",
                                    transition: "ease"
                                }}/>
                            </IconButton>
                        }
                    </Grid>
                    <Grid item xs={6} container justifyContent="end">
                        {userLogged?.role?.code === UserRoleCode.CUSTOMER && (data.rateDetails.length > 0 || data.promotionalRateDetails.length > 0 || data.orderSchedule) &&
                            <IconButton sx={{cursor: "pointer"}}
                                        onClick={handleDialogItemDetailsOpen}>
                                <InfoIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        }
                        <Tooltip
                            title={intl.formatMessage(getMessageDescriptor("itemListCardSeeMoreDetails"))}>
                            <IconButton onClick={handleDetailedProduct}>
                                <RemoveRedEyeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            {openItemDetails && <ItemDetailsDialog onClose={handleDialogItemDetailsClose}
                                                   itemCode={data.code}
                                                   dateRef={moment().toDate()}
                                                   rateDetails={data.rateDetails}
                                                   promotionalRateDetails={data.promotionalRateDetails}
                                                   orderSchedule={data.orderSchedule}
                                                   itemPrice={data.itemPrice}
                                                   unitCode={data.orderUnit?.code}
                                                   userLogged={userLogged}/>}
        </Card>
    )
}