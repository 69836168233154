import {Order, OrderLinePricing} from "../../../../../../corelogic/models/order";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {getOrderLinesSelector} from "../../../../../../store/selectors/ordersSelectors";
import * as React from "react";
import {useCallback, useState} from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getColumnsOrderBillingDetailsLinesDescription} from "../../../Descriptions/orderLineListDescription";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import Box from "@mui/material/Box";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid, GridRowModel} from "@mui/x-data-grid";
import {
    updateOrderLineLabel,
    updateOrderLinePricing,
    updateOrderLineQuantity
} from "../../../../../../corelogic/usecase/orders/ordersActions";
import {DividingHR} from "../../../Utils/DividingHR";
import {SearchBar} from "../../../Utils/SearchBar";
import {FastLineAdd} from "./FastLigneAdd";
import OrderTotal from "../../../Utils/OrderTotal";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {Customer} from "../../../../../../corelogic/models/customer";

interface OrderBillingDetailsInterface {
    dataOrder: Order,
    onOrderLineFilterBySearchChange: (orderLineFilterInput: string) => void,
    onDeleteLineClick: (lineId: number) => void
    onValidateClick: () => void,
    onCancelClick: () => void
    onDeleteOrder: (orderId: number) => void
    customer: Customer
}

export const OrderBillingDetails = (props: OrderBillingDetailsInterface) => {
    const {
        dataOrder,
        onDeleteLineClick,
        onValidateClick,
        onCancelClick,
        onDeleteOrder,
        onOrderLineFilterBySearchChange,
        customer
    } = props
    const dispatch = useDispatch()
    const intl = useIntl()
    const {dataOrderLines, fetchingOrderLines} = useSelector(getOrderLinesSelector)
    const [promiseArguments, setPromiseArguments] = useState<any>(null)
    const [lineIdToDelete, setLineIdToDelete] = useState<undefined | number>()
    const [density, setDensity] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [cancelClickModal, setCancelClickModal] = useState(false)
    const [searchBarInput, setSearchBarInput] = useState("")
    const {userLogged} = useSelector(getUserLoggedSelector)

    const columns: GridColDef[] = [
        ...getColumnsOrderBillingDetailsLinesDescription(userLogged?.role?.code, userLogged?.defaultSociety.authorizationToChangeTheGrossPrice,
            userLogged?.defaultSociety.authorizationToChangeDiscounts, userLogged?.defaultSociety.freeManagementOrderLine, userLogged?.defaultSociety.authorizationToChangeOrderLineItemLabel).map(cd => {
            return {
                ...cd,
                headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
                description: intl.formatMessage(getMessageDescriptor(cd.description)),
            }
        }),
        {
            field: 'actions',
            description: `Actions d'un produit`,
            headerAlign: "center",
            align: "center",
            flex: 0.3,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title="Changer la densité">
                            <ReorderIcon onClick={() => setDensity(prevState => !prevState)}
                                         sx={{
                                             fill: "rgba(33, 150, 243, 1)",
                                             fontSize: "1.5rem",
                                             cursor: "pointer"
                                         }}/>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: (params) => {
                return (
                    <DeleteIcon onClick={() => handleDeleteModalOnTrashClick(Number(params.id))}
                                style={{fill: "rgba(255, 82, 82, 1)", cursor: "pointer"}}/>
                )
            },
        },
    ]

    const onValidateButtonClick = () => {
        onValidateClick()
    }

    const handleChangeSearchInput = (val: string) => {
        setSearchBarInput(val)
        onOrderLineFilterBySearchChange(val)
    }

    const handleResetSearchInput = () => {
        setSearchBarInput("")
        onOrderLineFilterBySearchChange("")
    }

    const processRowUpdate = useCallback(
        (newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                const pricing: OrderLinePricing = {
                    grossPrice: newRow.grossPrice,
                    customerRateR1: newRow.discount1,
                    customerPromotionalRateR2: newRow.discount2,
                    free: newRow.free
                }
                if (oldRow.quantity !== newRow.quantity) {
                    const quantityValue = Number(newRow.quantity)
                    if (!isNaN(quantityValue)) {
                        if (quantityValue === 0) {
                            setPromiseArguments({resolve, reject, newRow, oldRow})
                            setLineIdToDelete(newRow.id)
                        } else {
                            dispatch(updateOrderLineQuantity(Number(newRow.id), quantityValue))
                            resolve(newRow)
                        }
                        return
                    }
                } else if (oldRow.grossPrice !== newRow.grossPrice) {
                    dispatch(updateOrderLinePricing(Number(newRow.id), {...pricing}))
                } else if (oldRow.discount1 !== newRow.discount1) {
                    if (isNaN(newRow.discount1))
                        newRow.discount1 = undefined
                    else
                        newRow.discount1 = Number(newRow.discount1) / 100
                    dispatch(updateOrderLinePricing(Number(newRow.id), {...pricing, customerRateR1: newRow.discount1}))
                } else if (oldRow.discount2 !== newRow.discount2) {
                    if (isNaN(newRow.discount2))
                        newRow.discount2 = undefined
                    else
                        newRow.discount2 = Number(newRow.discount2) / 100
                    dispatch(updateOrderLinePricing(Number(newRow.id), {
                        ...pricing,
                        customerPromotionalRateR2: newRow.discount2
                    }))
                } else if (oldRow.free !== newRow.free) {
                    dispatch(updateOrderLinePricing(Number(newRow.id), {...pricing}))
                } else if (oldRow.label !== newRow.label) {
                    dispatch(updateOrderLineLabel(Number(newRow.id), newRow.label))
                }
                resolve(newRow)
            }),
        [dispatch]
    )

    const handleYes = async () => {
        if (lineIdToDelete) {
            onDeleteLineClick(lineIdToDelete)
        }
        setPromiseArguments(null)
        setLineIdToDelete(undefined)

        if (promiseArguments) {
            const {oldRow, resolve} = promiseArguments
            resolve(oldRow)
        }
    }

    const handleNo = () => {
        setPromiseArguments(null)
        setLineIdToDelete(undefined)

        if (promiseArguments) {
            const {oldRow, resolve} = promiseArguments
            resolve(oldRow)
        }
    }

    const handleDeleteModalOnTrashClick = (id: number) => {
        setLineIdToDelete(id)
    }

    const handleDeleteOrder = () => {
        onDeleteOrder(dataOrder.id)
    }

    const handleExitOrder = () => {
        onCancelClick()
    }

    const renderConfirmDeleteDialog = () => {
        if (!promiseArguments && !lineIdToDelete) {
            return null;
        }
        return (
            <>
                <Dialog
                    maxWidth="lg"
                    open
                >
                    <DialogTitle>
                        <FormattedMessage id={messages.orderCreationModalOrderLineDeleteConfirmation.id}/>
                    </DialogTitle>
                    <DialogContent>
                        {promiseArguments ?
                            <DialogContentText>
                                <Alert
                                    severity="warning">{intl.formatMessage(getMessageDescriptor("orderLineGridDeleteQuantity0"))}
                                </Alert>
                            </DialogContentText>
                            :
                            <>
                                <DialogContentText m={1}>
                                    <Alert
                                        severity="warning">{intl.formatMessage(getMessageDescriptor("orderLineGridDeleteClickConfirm"))}</Alert>
                                </DialogContentText>
                                <DialogContentText m={1}>
                                    <Alert
                                        severity="info">{intl.formatMessage(getMessageDescriptor("orderLineGridDeleteClickCancel"))}</Alert>
                                </DialogContentText>
                            </>
                        }
                    </DialogContent>
                    <DividingHR subContent/>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleNo}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                        <Button variant="outlined" onClick={handleYes}><FormattedMessage
                            id={messages.genericConfirm.id}/></Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const renderConfirmCancelClick = () => {
        return (
            <>
                <Dialog
                    maxWidth="lg"
                    open
                >
                    <DialogTitle>
                        {intl.formatMessage(getMessageDescriptor("orderCreationModalDeleteOrderConfirmationTitle"))}
                        <IconButton sx={{ml: 1}}>
                            <CloseIcon onClick={() => setCancelClickModal(false)}/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText m={1}>
                            <Alert
                                severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationModalDeleteOrderConfirmation"))}</Alert>
                        </DialogContentText>
                        <DialogContentText m={1}>
                            <Alert
                                severity="info">{intl.formatMessage(getMessageDescriptor("orderCreationModalDeleteOrderCancel"))}</Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DividingHR subContent/>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleExitOrder}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                        <Button variant="outlined" onClick={handleDeleteOrder}><FormattedMessage
                            id={messages.genericConfirm.id}/></Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" py={0.5} px={3}>
                <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <ShoppingCartIcon/>
                        </Grid>
                        <Grid item>
                            <Typography fontSize="1.2rem">
                                <FormattedMessage id={messages.orderCreationPageOrderDetailsTitle.id}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <SearchBar label={intl.formatMessage(getMessageDescriptor("searchBarItem"))}
                               onChange={handleChangeSearchInput} onResetClick={handleResetSearchInput}
                               value={searchBarInput}/>
                </Grid>
            </Grid>
            <DividingHR style={{marginTop: 1}}/>
            <Paper elevation={2} sx={{p: 1}}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <FastLineAdd dataOrderId={dataOrder.id}/>
                    </Grid>
                    <Grid item height="52vh">
                        <Paper sx={{height: "100%", width:"100%"}}>
                            <DataGrid className="orderCreationPage-billing-grid"
                                      rows={dataOrderLines}
                                      getRowId={(row: any) => row.id}
                                      columns={columns}
                                      columnVisibilityModel={{id: false}}
                                      loading={fetchingOrderLines}
                                      paginationModel={paginationModel}
                                      pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                                      onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                                      editMode="cell"
                                      disableRowSelectionOnClick
                                      processRowUpdate={processRowUpdate}
                                      density={density ? "compact" : "standard"}
                                      slots={{
                                          noRowsOverlay: () => (
                                              <Stack height="100%" alignItems="center" justifyContent="center">
                                                  <Alert severity="info">
                                                      {intl.formatMessage(getMessageDescriptor("orderLinesNoData"))}
                                                  </Alert>
                                              </Stack>
                                          )
                                      }}
                                      sx={{
                                          "& .MuiDataGrid-cell:focus": {
                                              outline: "none"
                                          },
                                          "& .MuiDataGrid-columnHeader:focus": {
                                              outline: "none"
                                          },
                                          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                                              outline: "none",
                                              color: "rgba(33, 150, 243, 1) !important"
                                          }
                                      }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item width="100%">
                        <Paper>
                            <OrderTotal totalGrossTaxExcluded={dataOrder.totals?.totalGrossTaxExcluded}
                                        totalShippingCost={dataOrder.totals?.totalShippingCost}
                                        discount={(dataOrder.totals?.totalGrossTaxExcluded || 0) * (dataOrder.totals?.discount || 0)}
                                        totalTaxExcluded={dataOrder.totals?.totalTaxExcluded}
                                        totalVat={dataOrder.totals?.totalVat}
                                        totalWithTax={dataOrder.totals?.totalWithTax}
                                        vatExcluded={dataOrder.vatManagement?.value === "E"}
                                        customer={customer}
                            />
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button onClick={() => setCancelClickModal(true)} variant="outlined" color="error">
                                    <FormattedMessage id={messages.genericCancel.id}/>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={onValidateButtonClick} variant="outlined">
                                    <FormattedMessage id={messages.genericValidate.id}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {renderConfirmDeleteDialog()}
            {cancelClickModal && renderConfirmCancelClick()}
        </>
    )
}