import {OrderComment} from "../../../../../corelogic/models/order";
import Grid from "@mui/material/Grid";
import {Accordion, AccordionDetails, AccordionSummary, LinearProgress, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DividingHR} from "../../Utils/DividingHR";
import List from "@mui/material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentIcon from "@mui/icons-material/Comment";
import * as React from "react";
import {useEffect} from "react";
import {APP_ACTIONS_TYPES} from "../../../../../store/AppActionsTypes";
import {retrieveOrderCommentsByOrderId} from "../../../../../corelogic/usecase/orders/ordersActions";
import {useDispatch, useSelector} from "react-redux";
import {getOrderCommentsSelector} from "../../../../../store/selectors/ordersSelectors";
import Box from "@mui/material/Box";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {TextValueField} from "../../Utils/TextValueField";
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';

export const OrderCommentsListStore = (props: { orderId: number }) => {
    const {orderId} = props
    const dispatch = useDispatch()
    const {dataOrderComments, fetchingOrderComments} = useSelector(getOrderCommentsSelector)

    useEffect(() => {
        dispatch(retrieveOrderCommentsByOrderId(orderId))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_CLEAR})
    }, [dispatch, orderId])

    return (
        <Box sx={{width: "300px"}}>
            {fetchingOrderComments ? <LinearProgress color="primary"/> : <OrderCommentsList data={dataOrderComments}/>}
        </Box>
    )
}

export const OrderCommentsList = (props: { data: OrderComment[] }) => {
    const {data} = props
    const intl = useIntl()

    return (
        <Grid container p={1} justifyContent="center">
            <Typography sx={{textAlign: "center", whiteSpace: "nowrap", fontWeight: "bolder"}}>
                <FormattedMessage id={messages.orderListPageCommentsOrder.id}/>
            </Typography>
            <DividingHR/>
            <List>
                {data?.length ? data.map(x => (
                        <Accordion elevation={5}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon
                                    sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container flexWrap="wrap" whiteSpace="nowrap">
                                    <CommentIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    <Typography fontWeight="bolder">
                                        <TextValueField
                                            text={intl.formatMessage(getMessageDescriptor("orderListPageCommentsOrderAuthor"))}
                                            value={x.profile ? x.profile : "Unknown author"}/>
                                    </Typography>
                                    <DividingHR subContent/>
                                    <Typography fontWeight="bolder">
                                        <TextValueField
                                            text={intl.formatMessage(getMessageDescriptor("orderListPageCommentsOrderDate"))}
                                            value={new Date(x.createdAt).toLocaleDateString() || ""}/>
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <DividingHR subContent/>
                            <AccordionDetails sx={{maxWidth: "250px"}}>
                                <Typography>{x.message}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    :
                    <Paper>
                        <Grid container alignItems="center" justifyContent="center" p={1}>
                            <Grid item>
                                <CommentsDisabledIcon/>
                            </Grid>
                            <Grid item>
                                <Typography component="div" textAlign="center">
                                    <FormattedMessage id={messages.noOrderCommentFound.id}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>}
            </List>
        </Grid>
    )
}