import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {userLogin} from "../../../corelogic/usecase/session/sessionActions";
import {useDispatch, useSelector} from "react-redux";
import {getAPIErrorSelector} from "../../../store/selectors/genericsSelector";
import {getActiveAuthTokenSelector} from "../../../store/selectors/sessionSelector";
import {Alert, CircularProgress, Grid, Paper} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../i18n/messages";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://sodiwin.com/">
                Sodiwin
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

type InputsFormLogin = {
    login: string
    password: string
}

const schemaValidation = yup.object({
    login: yup.string().required("Please enter your login"),
    password: yup.string().required("Please enter your password")
}).required()

export default function SignIn(props: { initialMsg?: string }) {
    const dispatch = useDispatch()
    const intl = useIntl()
    const {apiError} = useSelector(getAPIErrorSelector)
    const {activeAuthToken, fetching} = useSelector(getActiveAuthTokenSelector)
    const loginFieldRef = useRef<HTMLInputElement | null>(null)
    const [firstTry, setFirstTry] = useState(true)
    const hasError = !fetching && !firstTry && !activeAuthToken && apiError !== null
    const [themeValueStringToChangeSpecificColor, setThemeValueStringToChangeSpecificColor] = useState("")

    const {handleSubmit, control, reset, formState: {errors}} = useForm<InputsFormLogin>(
        {
            resolver: yupResolver(schemaValidation)
        }
    )

    useEffect(() => {
        const themeSodiwinAppValue = localStorage.getItem("themeSodiwinApp")

        if (themeSodiwinAppValue === "light") {
            setThemeValueStringToChangeSpecificColor("light")
        } else {
            setThemeValueStringToChangeSpecificColor("dark")
        }
    }, [])

    useEffect(() => {
        loginFieldRef.current?.focus()
    }, [hasError, errors])

    const onSubmit: SubmitHandler<InputsFormLogin> = (data) => {
        dispatch(userLogin(data.login, data.password))
        setFirstTry(false)
        reset({login: data.login, password: ""})
    }

    return (
        <Container component="main" maxWidth="sm" sx={{height: "100vh"}}>
            <Grid container height="100%" alignItems="center" justifyContent="center" direction="column">
                <Grid item width="100%">
                    <Paper>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Grid container direction="column" alignItems="center" spacing={1} px={5}>
                                <Grid item>
                                    {themeValueStringToChangeSpecificColor === "light" ?
                                        <img src={`${process.env.PUBLIC_URL}/Logo300-blue.svg`} alt="logo sodiwin thème clair"
                                             style={{width: "200px", height: "100px"}}/>
                                        :
                                        <img src={`${process.env.PUBLIC_URL}/logo-sodiwin-blanc.svg`} alt="logo sodiwin thème sombre"
                                             style={{width: "200px", height: "100px"}}/>
                                    }
                                </Grid>
                                <Grid item container>
                                    <Grid container alignItems="center">
                                        {/*<Grid item>*/}
                                        {/*    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>*/}
                                        {/*        <LockOutlinedIcon/>*/}
                                        {/*    </Avatar>*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item>
                                            <Typography component="h1" variant="h5" fontWeight="bolder">
                                                {process.env.PUBLIC_URL}
                                            </Typography>
                                        </Grid>*/}
                                        <Grid item>
                                            <Typography component="h1" variant="h5" fontWeight="bolder">
                                                <FormattedMessage id={messages.appLoginSignIn.id}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" spacing={2}>
                                    <Grid item>
                                        <Controller
                                            name="login"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    inputRef={loginFieldRef}
                                                    autoFocus
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    label={intl.formatMessage((getMessageDescriptor("userPageFormFieldLogin")))}
                                                    placeholder={intl.formatMessage((getMessageDescriptor("userPageFormFieldLogin"))) + "..."}
                                                    type="text"
                                                    error={!!errors.login}
                                                    helperText={errors.login?.message}/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    label={intl.formatMessage((getMessageDescriptor("userPageFormPassword")))}
                                                    placeholder={intl.formatMessage((getMessageDescriptor("userPageFormPassword"))) + "..."}
                                                    type="password"
                                                    error={!!errors.password}
                                                    helperText={errors.password?.message}/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item textAlign="center">
                                        {fetching && (
                                            <CircularProgress size={24}/>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {hasError &&
                                            <Alert
                                                severity="error">{intl.formatMessage(getMessageDescriptor("appLoginCredentialsError"))}</Alert>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={fetching}
                                        sx={{mt: 3, mb: 2}}> <FormattedMessage id={messages.appLoginSignIn.id}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
                <Box>
                    <Copyright sx={{mt: 8, mb: 4}}/>
                </Box>
            </Grid>
        </Container>
    )
}
