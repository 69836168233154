import React from 'react';
import {Alert, Grid, Paper, Tooltip} from "@mui/material";
import {ContactPhoneOutlined, InfoOutlined, LocationOnOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {DividingHR} from "../../Utils/DividingHR";
import {TextValueField} from "../../Utils/TextValueField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {Society} from "../../../../../corelogic/models/society";
import Box from "@mui/material/Box";
import {AddressDisplayBlock} from "../../Utils/Address";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from '@mui/icons-material/Badge';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

interface SocietyViewInterface {
    societySelected?: Society
}

const SocietyPage = (props: SocietyViewInterface) => {
    const {societySelected} = props
    const intl = useIntl()
    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
                <Paper>
                    <Grid container direction="column">
                        <Grid container item alignItems="center" p={1}>
                            <InfoOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                            <Typography variant="h6" component="h2" fontWeight="bolder">
                                <FormattedMessage id={messages.customerPageGeneralInformationTitle.id}/>
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Paper elevation={5} sx={{mx: 2, mb: 2, maxWidth: "100%"}}>
                            <Grid container>
                                <Grid item md={12} lg={6}>
                                    <Grid container direction="column" p={1} spacing={1}>
                                        <Grid container item sm={12}>
                                            <TextValueField text={"Code société"}
                                                            value={societySelected?.code || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Label"} value={societySelected?.label || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Langue"}
                                                            value={societySelected?.language?.label || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Devise"}
                                                            value={societySelected?.currency?.label || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Siret"}
                                                            value={societySelected?.siret || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Tva intracommunautaire"}
                                                            value={societySelected?.intraVat || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Code APE/NAF"}
                                                            value={societySelected?.apeNaf || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Code EAN"}
                                                            value={societySelected?.eanCode || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"RCS"}
                                                            value={societySelected?.rcs || ""}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField text={"Capital social"}
                                                            value={societySelected?.stockCapital || ""}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={12}>
                                    <Grid container direction="column" p={1} spacing={1}>
                                        <Tooltip
                                            title={societySelected?.orderSubmissionEmails}>
                                            <Grid item width="100%">
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("societyMailsForOrderSubmission"))}
                                                    value={societySelected?.orderSubmissionEmails}/>
                                            </Grid>
                                        </Tooltip>
                                        <Grid item width="100%">
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("userPageFormFieldSendEmailAtOrderSubmission"))}
                                                value={societySelected?.sendEmailAtOrderSubmission ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("societyFormFieldBlockAllEmailShipmentsAtOrderSubmission"))}
                                                value={societySelected?.blockAllEmailShipmentsAtOrderSubmission ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("societyFormFieldFreeManagementOrderLine"))}
                                                value={societySelected?.freeManagementOrderLine ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeDiscounts"))}
                                                value={societySelected?.authorizationToChangeDiscounts ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                value={societySelected?.authorizationToChangeTheGrossPrice ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                // text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                text={"Envoi mail création utilisateur"}
                                                value={societySelected?.sendingMailAtUserCreation ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                // text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                text={"Autorisation de modifier un label de ligne de commande"}
                                                value={societySelected?.authorizationToChangeOrderLineItemLabel ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                // text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                text={"Remplacer le label article de la ligne de commande par celui du cadencier"}
                                                value={societySelected?.replaceOrderLineItemLabelFromOrderScheduler ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                        <Grid item width="100%">
                                            <TextValueField
                                                // text={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                text={"Colis proposé par défaut en saisie de commande"}
                                                value={societySelected?.defaultOrderUnitPackage ?
                                                    <CheckBoxIcon fontSize="small"/> :
                                                    <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12} lg={6}>
                <Paper sx={{height: "100%"}}>
                    <Grid container direction="column" height="100%">
                        <Grid container item alignItems="center" p={1}>
                            <LocationOnOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                            <Typography variant="h6" component="h2" fontWeight="bolder">
                                <FormattedMessage id={messages.customerPageViewFieldMainAddress.id}/>
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Grid item pb={2} px={2} flexGrow={1}>
                            <Paper elevation={5} sx={{height: "100%"}}>
                                <Grid container item p={2}>
                                    {(!societySelected?.businessName) || (!societySelected?.address1 && !societySelected?.address2 && !societySelected?.address3) ?
                                        <Box sx={{width: "100%"}}>
                                            <Alert severity="info">Pas d'adresse enregistrée</Alert>
                                        </Box>
                                        :
                                        <Box sx={{paddingLeft: "10px"}}>
                                            <AddressDisplayBlock title={societySelected?.businessName}
                                                                 address1={societySelected?.address1}
                                                                 address2={societySelected?.address2}
                                                                 address3={societySelected?.address3}
                                                                 zipCode1={societySelected?.zipCode1}
                                                                 zipCode2={societySelected?.zipCode2}
                                                                 city={societySelected?.city}
                                                                 country={societySelected?.country?.label}
                                                                 hasFocus={true}/>
                                        </Box>
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12} lg={6}>
                <Paper sx={{height: "100%"}}>
                    <Grid container direction="column" height="100%">
                        <Grid container item alignItems="center" p={1}>
                            <ContactPhoneOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                            <Typography variant="h6" component="h2" fontWeight="bolder">
                                <FormattedMessage id={messages.customerPageContactTitle.id}/>
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Grid item pb={2} px={2} flexGrow={1} width="100%">
                            <Paper elevation={5} sx={{height: "100%"}}>
                                <Grid container direction="column" p={1} justifyContent="space-around" height="100%">
                                    <Grid container item>
                                        <Grid item sm={1}>
                                            <PhoneIcon sx={{mr: 1}}/>
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                value={societySelected?.phone}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item sm={1}>
                                            <FaxIcon sx={{mr: 1}}/>
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldFax"))}
                                                value={societySelected?.fax}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item sm={1}>
                                            <EmailIcon sx={{mr: 1}}/>
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                value={societySelected?.mail}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item sm={1}>
                                            <BadgeIcon sx={{mr: 1}}/>
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                value={societySelected?.comInterlocutor?.name}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item sm={1}>
                                            <LanguageOutlinedIcon sx={{mr: 1}}/>
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextValueField
                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldWebsite"))}
                                                value={societySelected?.website}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12}>
                <Paper>
                    <Grid container direction="column">
                        <Grid container item alignItems="center" p={1}>
                            <ContactPhoneOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                            <Typography variant="h6" component="h2" fontWeight="bolder">
                                Interlocuteurs
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Grid container item spacing={1} p={1} px={2}>
                            <Grid item md={12} lg={4} pb={2}>
                                <Paper elevation={5}>
                                    <Grid container direction="column" p={1} spacing={1}>
                                        <Grid item alignItems="center" p={1}>
                                            <Typography fontWeight="bolder">
                                                Commercial
                                            </Typography>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <BadgeIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                    value={societySelected?.comInterlocutor?.name}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <PhoneIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                    value={societySelected?.comInterlocutor?.phone}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <EmailIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                    value={societySelected?.comInterlocutor?.mail}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={12} lg={4} pb={2}>
                                <Paper elevation={5}>
                                    <Grid container direction="column" p={1} spacing={1}>
                                        <Grid item alignItems="center" p={1}>
                                            <Typography fontWeight="bolder">
                                                Technique
                                            </Typography>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <BadgeIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                    value={societySelected?.techInterlocutor?.name}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <PhoneIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                    value={societySelected?.techInterlocutor?.phone}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <EmailIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                    value={societySelected?.techInterlocutor?.mail}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={12} lg={4} pb={2}>
                                <Paper elevation={5}>
                                    <Grid container direction="column" p={1} spacing={1}>
                                        <Grid item alignItems="center" p={1}>
                                            <Typography fontWeight="bolder">
                                                Administratif
                                            </Typography>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <BadgeIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                    value={societySelected?.adminInterlocutor?.name}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <PhoneIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                    value={societySelected?.adminInterlocutor?.phone}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item sm={1}>
                                                <EmailIcon sx={{mr: 1}}/>
                                            </Grid>
                                            <Grid item sm={11}>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                    value={societySelected?.adminInterlocutor?.mail}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SocietyPage;