import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {ParameterGateway} from "../../../corelogic/gateways/ParameterGateway";
import {CustomerFamily, CustomerSuperFamily, Representative} from "../../../corelogic/models/parameter";

export class ApiParameterGateway implements ParameterGateway {

    retrieveCustomerFamilyById(customerFamilyId: number): Promise<CustomerFamily | undefined> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.parameter, "customerFamily",customerFamilyId.toString())})
    }

    retrieveCustomerSuperFamilyById(customerSuperFamilyId: number): Promise<CustomerSuperFamily | undefined> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.parameter, "customerSuperFamily",customerSuperFamilyId.toString())})
    }

    retrieveRepresentatives(): Promise<Representative[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.parameter, "representative")})
    }
}