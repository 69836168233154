import {APIError} from "corelogic/interfaces";
import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";

const apiNetworkError = (state: APIError | null = null, action: AnyAction): APIError | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH:
            return action.payload
        case APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CLEAN:
            return null
        case APP_ACTIONS_TYPES.session.LOGIN_FETCHING:
            return null
        default:
            return state
    }
}

const apiError = (state: APIError | null = null, action: AnyAction): APIError | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.generics.API_ERROR_CATCH:
            return action.payload
        case APP_ACTIONS_TYPES.generics.API_ERROR_CLEAN:
            return null
        case APP_ACTIONS_TYPES.session.LOGIN_FETCHING:
            return null
        default:
            return state
    }
}

export default combineReducers({
    apiNetworkError,
    apiError
})