import React, {useEffect, useState} from 'react';
import SocietyHeader from "./SocietyHeader";
import {useDispatch, useSelector} from "react-redux";
import {getSocietyFormSelector, getSocietySelectedSelector} from "../../../../../store/selectors/societiesSelectors";
import {Society, SocietyAllFormData, SocietyForm} from "../../../../../corelogic/models/society";
import {
    retrieveSelectedSociety,
    retrieveSocietyFormDataValues,
    updateSociety
} from "../../../../../corelogic/usecase/society/societyActions";
import {Button, Grid, Paper, Tooltip} from "@mui/material";
import Container from "@mui/material/Container";
import SocietyView from "./SocietyView";
import SocietyFormComponent from "./SocietyFormComponent";
import {useParams} from "react-router-dom";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import {useIntl} from "react-intl";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const SocietyPageStore = () => {
    const {societySelected, fetchingSelected} = useSelector(getSocietySelectedSelector)
    const {dataFormFields} = useSelector(getSocietyFormSelector)
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        if (params.societyId) {
            dispatch(retrieveSelectedSociety(Number(params.societyId)))
        }
    }, [dispatch, params.societyId])

    useEffect(() => {
        dispatch(retrieveSocietyFormDataValues())
    }, [dispatch])

    return (
        <>
            <SocietyPage
                societySelected={societySelected ? societySelected : undefined}
                dataFormFields={dataFormFields}/>
        </>
    )
}

interface SocietyPageInterface {
    societySelected?: Society
    dataFormFields?: SocietyAllFormData
}

const SocietyPage = (props: SocietyPageInterface) => {
    const {societySelected, dataFormFields} = props
    const dispatch = useDispatch()
    const intl = useIntl()
    const [editMode, setEditMode] = useState(false)
    const handleChangeMode = (editMode: boolean) => {
        setEditMode(editMode)
    }

    const handleUpdateSociety = (societyFormChild: SocietyForm) => {
        if (societySelected) {
            dispatch(updateSociety({
                id: societySelected.id,
                code: societySelected.code,
                rcs: societyFormChild.rcs,
                eanCode: societyFormChild.eanCode,
                apeNaf: societyFormChild.apeNaf,
                intraVat: societyFormChild.intraVat,
                siret: societyFormChild.siret,
                stockCapital: societyFormChild.stockCapital,
                address1: societyFormChild.address1,
                address2: societyFormChild.address2,
                address3: societyFormChild.address3,
                zipCode1: societyFormChild.zipCode1,
                zipCode2: societyFormChild.zipCode2,
                city: societyFormChild.city,
                fax: societyFormChild.fax,
                website: societyFormChild.website,
                mail: societyFormChild.mail,
                sendEmailAtOrderSubmission: societyFormChild.sendEmailAtOrderSubmission,
                businessName: societyFormChild.businessName,
                blockAllEmailShipmentsAtOrderSubmission: societyFormChild.blockAllEmailShipmentsAtOrderSubmission,
                label: societyFormChild.label,
                countryId: societyFormChild.countryId,
                phone: societyFormChild.phone,
                currencyId: societyFormChild.currencyId,
                freeManagementOrderLine: societyFormChild.freeManagementOrderLine,
                languageId: societyFormChild.languageId,
                adminInterlocutor: societyFormChild.adminInterlocutor,
                comInterlocutor: societyFormChild.comInterlocutor,
                techInterlocutor: societyFormChild.techInterlocutor,
                orderSubmissionEmails: societyFormChild.orderSubmissionEmails,
                authorizationToChangeDiscounts: societyFormChild.authorizationToChangeDiscounts,
                authorizationToChangeTheGrossPrice: societyFormChild.authorizationToChangeTheGrossPrice,
                sendingMailAtUserCreation: societyFormChild.sendingMailAtUserCreation,
                authorizationToChangeOrderLineItemLabel: societyFormChild.authorizationToChangeOrderLineItemLabel,
                replaceOrderLineItemLabelFromOrderScheduler: societyFormChild.replaceOrderLineItemLabelFromOrderScheduler,
                defaultOrderUnitPackage: societyFormChild.defaultOrderUnitPackage
            }))
        }
        setEditMode(prevState => !prevState)
    }

    const handleResetForm = () => {
        handleChangeMode(false)
    }

    return (
        <>
            <Paper elevation={3} sx={{
                backgroundColor: "common.main",
                position: "sticky",
                top: "77px",
                zIndex: 1000
            }}>
                <SocietyHeader societySelected={societySelected}/>
            </Paper>
            <Container maxWidth="xl">
                <Grid container p={2} spacing={1}>
                    <Grid item sm={!editMode ? 11 : 12}>
                        {editMode ?
                            <SocietyFormComponent dataFormFields={dataFormFields} onUpdate={handleUpdateSociety}
                                                  onReset={handleResetForm}
                                                  societySelected={societySelected}/>
                            :
                            <SocietyView societySelected={societySelected}/>
                        }
                    </Grid>
                    {!editMode &&
                        <Grid item sm={1}>
                            <Paper sx={{
                                position: "sticky",
                                top: "145px",
                                zIndex: 1000
                            }}>
                                <Grid container direction="column">
                                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericEditMode"))}>
                                        <Button onClick={() => handleChangeMode(true)}
                                                disabled={editMode === undefined}><EditIcon/></Button>
                                    </Tooltip>
                                    <Button disabled><DeleteIcon sx={{fill: "grey"}}/></Button>
                                </Grid>
                            </Paper>
                        </Grid>}
                </Grid>
            </Container>
        </>
    );
};

export default SocietyPage;