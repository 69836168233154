import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Parameter} from "../../corelogic/models/parameter";

const selected = (state: Parameter | null = null, action: AnyAction): Parameter | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.parameters.PARAMETER_RETRIEVED:
            return action.payload
        default:
            return state
    }
}
const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("PARAMETER")) {
        return action.type === APP_ACTIONS_TYPES.parameters.PARAMETER_FETCHING
    }
    return state
}
export default combineReducers({
    selected,
    fetching
})