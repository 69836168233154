import {AnyAction, combineReducers} from "redux"
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes"
import {User, UserAllFormData} from "../../corelogic/models/user";

const emptyFormData = () => ({
    languages: [],
    roles: [],
    statuses: [],
    representatives: [],
    customers: []
} as UserAllFormData)

const dataFormFields = (state: UserAllFormData = emptyFormData(), action: AnyAction): UserAllFormData | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.users.USERS_FORM_DATA_FIELDS:
            return action.payload
        default:
            return state;
    }
}

const data = (state: User[] = [], action: AnyAction): User[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.users.USERS_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.users.USERS_RESET_DATA:
            return []
        case APP_ACTIONS_TYPES.users.USER_UPDATE:
            return state.map(u => u.id === action.payload.id ? action.payload : u)
        default:
            return state;
    }
}

const selected = (state: User | null = null, action: AnyAction): User | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.users.USER_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.users.USER_UPDATE:
            return (state && state.id === action.payload.id) ? action.payload : state
        case APP_ACTIONS_TYPES.users.USER_RESET_SELECTING:
            return null
        default:
            return state
    }
}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("USER")) {
        return action.type === APP_ACTIONS_TYPES.users.USER_FETCHING
    }
    return state
}

export default combineReducers({
    data,
    fetching,
    selected,
    dataFormFields
})