import * as React from "react";
import {CSSProperties, FC, useState} from "react";
import {Box, Typography} from "@mui/material";
import {FormattedCustomNumber, FormattedMonetaryNumber, FormattedPercentageNumber} from "./FormattedDisplayNumber";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import {useIntl} from "react-intl";
import moment from "moment/moment";

interface TextValueFieldProps {
    text: string
    separator?: string
    style?: CSSProperties
    classNameProp?: string
}

export const TextValueField2: FC<TextValueFieldProps> = (props) => {
    const {separator = ":", style, text, classNameProp} = props
    return <Box className={classNameProp ? classNameProp : "field-value"} sx={{...style}}>
        <Typography className="text-field-label">{text}</Typography>
        <span> {separator}</span>
        <span className="text-field-value">
            {props.children}
        </span>
    </Box>
}

export const TextValueField = (props: {
    text: string,
    separator?: string,
    value?: any,
    style?: CSSProperties,
    classNameProp?: string,
    format?: string,
    fontSize?: string
}) => {
    const {separator = " :", style, text, value, format, classNameProp, fontSize} = props
    return <Box className={classNameProp ? classNameProp : "field-value"} sx={{...style}}>
        <Typography overflow="initial" noWrap className="text-field-label" fontSize={fontSize}>{text}</Typography>
        {separator && <span> {separator}</span>}
        <span className="text-field-value">
            {!format ? value : <FormattedMonetaryNumber value={value}/>}
        </span>
    </Box>
}

export const TextValueFieldDate = (props: {
    text: string,
    separator?: string,
    value?: Date,
    style?: CSSProperties,
    classNameProp?: string,
    fontSize?: string
}) => {
    const {separator = " :", style, text, value,  classNameProp, fontSize} = props
    return <Box className={classNameProp ? classNameProp : "field-value"} sx={{...style}}>
        <Typography overflow="initial" noWrap className="text-field-label" fontSize={fontSize}>{text}</Typography>
        {separator && <span> {separator}</span>}
        <span className="text-field-value">
                    {value ? moment(value).format("DD/MM/YYYY") : ""}
        </span>
    </Box>
}

export const TextValueFieldMonetary = (props: {
    text: string,
    separator?: string,
    value?: number,
    style?: CSSProperties,
    classNameProp?: string,
    currency?: string
}) => {
    const {separator = ":", style, text, value, classNameProp, currency = "€"} = props
    return <Box className={classNameProp ? classNameProp : "field-value"} sx={{...style}}>
        <Typography className="text-field-label">{text}</Typography>
        <span> {separator}</span>
        <span className="text-field-value">
            <FormattedMonetaryNumber value={value || 0}/> {currency}
        </span>
    </Box>
}

export const TextValueFieldNumber = (props: {
    text: string,
    separator?: string,
    value?: number,
    style?: CSSProperties,
    classNameProp?: string,
    currency?: string
}) => {
    const {separator = ":", style, value, text, classNameProp} = props
    return <Box className={classNameProp ? classNameProp : "field-value"} sx={{...style}}>
        <Typography className="text-field-label">{text}</Typography>
        <span> {separator}</span>
        <span className="text-field-value">
            <FormattedCustomNumber value={value || 0}/>
        </span>
    </Box>
}

export const TextValueFieldPercentage = (props: { text: string, separator?: string, value?: number }) => {
    const {separator = ":", text, value} = props
    return (
        <Box className="field-value">
            <Typography className="text-field-label">{text}</Typography>
            <span> {separator}</span>
            <span className="text-field-value"><FormattedPercentageNumber value={value || 0}/></span>
        </Box>
    )
}

export const TextValueFieldBillingBox = (props: {
    text: string,
    separator?: string,
    value?: any,
    style?: CSSProperties
}) => {
    const {separator = " :", style, text, value} = props

    return <div className="field-value" style={{...style}}>
        <span className="text-field-label">{text}{separator}</span>
        <span className="text-field-value">
             <FormattedMonetaryNumber value={value}/> €
                </span>
    </div>
}


interface CustomProps {
    onChange: (event: { target: { name: string; value: number } }) => void
    name: string
    value: number
}

export const InputNumberField = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;
        const {formatters, locale} = useIntl()
        const decimalSepLocal = formatters.getNumberFormat(locale).formatToParts(1000.1).find(p => p.type === "decimal")?.value || ","
        const thousandSepLocal = formatters.getNumberFormat(locale).formatToParts(1000.1).find(p => p.type === "group")?.value || " "
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: Number(values.value),
                        }
                    });
                }}
                value={props.value}
                decimalScale={2}
                decimalSeparator={decimalSepLocal}
                thousandSeparator={thousandSepLocal}
                allowNegative={false}
            />
        );
    },
)

export const PercentField = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;
        const [val, setVal] = useState((props.value * 100))

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    setVal(Number(values.value))
                    onChange({
                        target: {
                            name: props.name,
                            value: Number(values.value) / 100,
                        }
                    });
                }}
                value={val}
                decimalScale={2}
                allowNegative={false}
                suffix="%"
            />
        );
    },
)
export const MonetaryField = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: Number(values.value),
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                suffix=" €"
            />
        );
    },
)