import {OrderLineWithOrderTotals, OrderTotals} from "../../../../corelogic/models/order";
import {Item} from "../../../../corelogic/models/item";
import {VatType} from "../../../../corelogic/models/parameter";

export interface OrderLineApiResponse {
    id: number
    item: Item
    label?: string
    itemCode?: string
    itemLabel?: string
    quantity?: number
    grossPrice?: number
    discount1?: number
    discount2?: number
    discount3?: number
    vat?: VatType
    itemTotalExTax?: number
}

export interface OrderLineWithOrderTotalsApiResponse {
    orderLine: OrderLineApiResponse
    orderTotals: OrderTotals
}

export const mapOrderLineWithOrderTotalsApiResponseToOrderLineWithOrderTotals = (orderLineWithOrderTotalsApiResponse: OrderLineWithOrderTotalsApiResponse): OrderLineWithOrderTotals => {
    return {
        ...orderLineWithOrderTotalsApiResponse,
        orderLine: {
            ...orderLineWithOrderTotalsApiResponse.orderLine,
            item: orderLineWithOrderTotalsApiResponse.orderLine.item,
            itemCode: orderLineWithOrderTotalsApiResponse.orderLine.item.code,
            itemLabel: orderLineWithOrderTotalsApiResponse.orderLine.item.label,
            vat: orderLineWithOrderTotalsApiResponse.orderLine.vat
        },
    }
}