import {Parameter} from "../../../../corelogic/models/parameter";
import {Address} from "../../../../corelogic/models/customer";

export interface CustomerAddressApiRequest {
    id?: number
    title: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    countryId?: number
}

export interface CustomerAddressApiResponse {
    id?: number
    title: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter
}

export const mapCustomerAddressToCustomerAddressApiRequest = (customerAddress: Address): CustomerAddressApiRequest => {
    return {
        id: customerAddress.id,
        title: customerAddress.title,
        businessName: customerAddress.businessName,
        address1: customerAddress.address1,
        address2: customerAddress.address2,
        address3: customerAddress.address3,
        zipCode1: customerAddress.zipCode1,
        zipCode2: customerAddress.zipCode2,
        city: customerAddress.city,
        countryId: customerAddress.country?.id
    }
}

export const mapCustomerAddressApiResponseToCustomerAddress = (customerAddress: CustomerAddressApiResponse): Address => {
    return {
        ...customerAddress,
        id: customerAddress.id,
        country: customerAddress.country
    }
}