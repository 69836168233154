import {API_ERRORS_TYPE, API_URL} from "corelogic/config"
import {APIError, APIResponse} from "corelogic/interfaces"
import axios, {AxiosError, AxiosRequestConfig} from "axios";

export class APINetworkException extends Error {

    private readonly apiErrorObject: APIError

    constructor(apiError: APIError) {
        super()
        this.message = apiError.message
        this.name = apiError.type
        this.apiErrorObject = apiError
    }

    getError() {
        return this.apiErrorObject
    }
}

export class APIException extends Error {

    private readonly apiErrorObject: APIError
    private trace: string | undefined;
    private url: string | undefined;
    private method: string | undefined;
    private timestamp: string | undefined;
    private error: string | undefined;
    private errorMessage: string | undefined;

    constructor(apiError: APIError) {
        super()
        this.message = apiError.message
        this.name = apiError.type
        this.apiErrorObject = apiError
        this.trace = apiError.trace
        this.url = apiError.url
        this.method = apiError.method
        this.error = apiError.error
        this.errorMessage = apiError.errorMessage
        this.timestamp = apiError.timestamp
    }

    getError() {
        return this.apiErrorObject
    }
}

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
    },
    withCredentials: true,
})

export async function apiFetchApiResponse<P>(requestConfig: AxiosRequestConfig): Promise<P> {
    return axiosInstance.request(requestConfig)
        .then((response) => {
            if (response.status === 204) {
                return null
            }
            return response.data
        }).then((responseData: APIResponse) => {
            if (!responseData.error) {
                return responseData.data
            } else {
                throw new APIException(responseData.apiError)
            }
        }).catch((reason: AxiosError<any,any> | APIException) => {
            if (reason instanceof APIException)
                throw reason
            if (reason.response?.status === 401) {
                window.dispatchEvent(new CustomEvent("UNAUTHORIZED_USER"))
                throw new APIException({
                    message: "",
                    code: '',
                    type: API_ERRORS_TYPE.BAD_CREDENTIALS,
                    gravity: ''
                })
            }
            if (reason.response?.status === 405) {
                throw new APIException({
                    message: reason.message,
                    code: reason.response.data.toString(),
                    type: API_ERRORS_TYPE.NOT_FOUND,
                    gravity: '',
                    trace: reason.response.data.trace,
                    url: reason.config?.url,
                    method: reason.config?.method,
                    error: reason.response?.data.error,
                    errorMessage: reason.response?.data.message,
                    timestamp: reason.response.data.timestamp
                })
            }
            throw new APINetworkException({
                code: '999',
                message: reason.message,
                type: 'NETWORK',
                gravity: 'GRAVE'
            })
        })
}
