import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "store/appState";
import {StatsGateway} from "../../gateways/StatsGateway";
import {ParameterGateway} from "../../gateways/ParameterGateway";

export const retrieveStatsOrder = (startDate:Date,endDate:Date,reprId?:number):
    ThunkResult<{ statsGateway: StatsGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {statsGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.stats.STATS_RETRIEVING})
        try {
            const orderStats = await statsGateway.retrieveOrderStats(startDate,endDate,reprId)
            dispatch({type: APP_ACTIONS_TYPES.stats.STATS_RETRIEVED, payload: orderStats})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveRepresentativeForStats = ():
    ThunkResult<{ parameterGateway: ParameterGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {parameterGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.stats.STATS_REPR_RETRIEVING})
        try {
            const representative = await parameterGateway.retrieveRepresentatives()
            dispatch({type: APP_ACTIONS_TYPES.stats.STATS_REPR_RETRIEVED, payload: representative})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}