import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {UserGateway} from "../../../corelogic/gateways/UserGateway";
import {User, UserAllFormData, UserForm, UserPasswordForm} from "../../../corelogic/models/user";
import {buildUrl} from "../../../corelogic/utils/tools";
import {mapUserToUserApiRequest, UserApiResponse} from "./Model/userApi";

export class ApiUserGateway implements UserGateway {

    retrieveAll(): Promise<User[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.user)})
    }

    retrieveById(id: number): Promise<User | undefined> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.user, id.toString())})
    }

    retrieveAllDataFormField(): Promise<UserAllFormData> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.user, "all-data")})
    }

    create(user: UserForm): Promise<User> {
        const formData = new FormData()

        formData.append("user", new Blob([JSON.stringify(user)], {type: "application/json"}))

        if (user.avatar) {
            formData.append("image", user.avatar as File)
        }

        return apiFetchApiResponse<UserApiResponse>({
            url: buildUrl(API_PREFIX.user),
            method: "POST",
            headers: {"Content-Type": "multipart/form-data"},
            data: formData
        })
    }

    updatePassword(userPasswordForm: UserPasswordForm): Promise<UserPasswordForm> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.user, "password"),
            method: "PATCH",
            data: userPasswordForm
        })
    }

    updatePasswordAdmin(userPassword: string, userId: number): Promise<string> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.user, userId.toString(), "password"),
            method: "PATCH",
            data: userPassword
        })
    }

    update(user: User): Promise<User> {
        const userRequest = mapUserToUserApiRequest(user)
        const formData = new FormData()

        formData.append("user", new Blob([JSON.stringify(userRequest)], {type: "application/json"}))

        if (user.avatar) {
            formData.append("image", user.avatar as File)
        }

        return apiFetchApiResponse<UserApiResponse>({
            url: buildUrl(API_PREFIX.user),
            method: "PUT",
            headers: {"Content-Type": "multipart/form-data"},
            data: formData
        })
    }

    delete(id: number): Promise<void> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.user, id.toString()), method: "DELETE"})
    }
}