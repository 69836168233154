import React from 'react';
import {Grid} from "@mui/material";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {useIntl} from "react-intl";
import {Item} from "../../../../../../corelogic/models/item";

const ItemDetailsPageHeader = (props: { data?: Item }) => {
    const {data} = props
    const intl = useIntl()
    return (
        <Grid container justifyContent="space-between" alignItems="center" p={1}>
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <ReturnButton/>
                    </Grid>
                    <Grid item mx={2}>
                        <DividingHR
                            subContent
                            style={{height: "30px", width: 1, margin: 0}}/>
                    </Grid>
                    <Grid item>
                        <TextValueField
                            style={{
                                fontWeight: "bolder"
                            }}
                            text={intl.formatMessage(getMessageDescriptor("itemDetailsPageHeaderTitle"))}
                            value={data?.label || ""}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ItemDetailsPageHeader;