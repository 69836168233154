import React from 'react';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Grid, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormattedNumber} from "react-intl";
import ImageIcon from '@mui/icons-material/Image';

export const columnsOrderVisualisationLinesListDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: 'id'
    },
    {
        field: 'picture',
        headerAlign: "center",
        filterable: false,
        sortable: false,
        width: 100,
        renderHeader: () => {
            return (
                <ImageIcon/>
            )
        },
        renderCell: (cellValues) => {
            return (
                <Grid container justifyContent="center">
                    <img alt={cellValues.row.item?.label || ""}
                         src={`${process.env.REACT_APP_API_ENDPOINT}img/items/${cellValues.row.item?.code.replace("/","_")}.jpg`}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null
                             currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                         }}
                         style={{
                             height: "60px",
                             width: "auto",
                             backgroundSize: "cover",
                             objectFit: "cover"
                         }}/>
                </Grid>
            )
        }
    },
    {
        field: 'itemCode',
        headerName: 'orderVisualisationPageGridOrderLinesItemCode',
        type: "string",
        width: 170,
        headerAlign: "center",
        align: "center",
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.item?.code || ''}`}>
                    <Box>
                        <Typography fontSize="small">
                            {cellValues.row.item?.code || ''}
                        </Typography>
                    </Box>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.item?.code
    },
    {
        field: 'itemLabel',
        headerName: 'orderVisualisationPageGridOrderLinesItemLabel',
        type: "string",
        width: 260,
        headerAlign: "center",
        align: "left",
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.label || ''}`}>
                    <Box>
                        <Typography fontSize="small">
                            {cellValues.row.label || ''}
                        </Typography>
                    </Box>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.label
    },
    {
        field: 'itemSuperFamily',
        headerName: 'orderVisualisationPageGridOrderLinesItemSuperFamily',
        type: "string",
        width: 170,
        headerAlign: "center",
        align: "center",
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.item?.superFamily?.label || ''}`}>
                    <Box>
                        <Typography fontSize="small">
                            {cellValues.row.item?.superFamily?.code || ''}
                        </Typography>
                    </Box>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.item?.superFamily?.code
    },
    {
        field: 'itemBrand',
        headerName: 'orderVisualisationPageGridOrderLinesItemBrand',
        type: "string",
        width: 170,
        headerAlign: "center",
        align: "center",
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.item?.brand?.label || ''}`}>
                    <Box>
                        <Typography fontSize="small">
                            {cellValues.row.item?.brand?.code || ''}
                        </Typography>
                    </Box>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.item?.brand?.code
    },
    {
        field: 'quantity',
        headerName: 'orderVisualisationPageGridOrderLinesItemQuantity',
        type: "number",
        width: 110,
        headerAlign: "center",
        align: "center",
        valueGetter: (cellValues) => cellValues.row.quantity
    },
    {
        field: 'packaging',
        headerName: 'orderVisualisationPageGridOrderLinesItemPackaging',
        type: "string",
        headerAlign: "center",
        align: "center",
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Typography fontSize="small">
                    {cellValues.row.unit || ''}
                </Typography>
            )
        },
        valueGetter: (cellValues) => cellValues.row.unit
    },
    {
        field: 'priceExTax',
        headerName: 'orderLineGridColUnitPriceExclTax',
        type: 'number',
        headerAlign: "center",
        align: "center",
        width: 170,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row.grossPrice || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row.grossPrice
    },
    {
        field: 'discount1',
        headerName: 'orderLineGridColDiscount',
        headerAlign: "center",
        align: "center",
        type: "number",
        description: 'orderLineGridColDiscountDesc',
        flex: 1,
        sortable: false,
        filterable: false,
        disableReorder: true,
        resizable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <Box>
                    {
                        <>
                            <FormattedNumber value={cellValues.row.discount1 || 0} maximumFractionDigits={2}
                                             style="percent"/>
                        </>
                    }
                </Box>
            )
        }
    },
    {
        field: 'discount2',
        headerName: 'orderLineGridColDiscount2',
        headerAlign: "center",
        align: "center",
        type: "number",
        description: 'orderLineGridColDiscountDesc',
        flex: 1,
        sortable: false,
        filterable: false,
        disableReorder: true,
        resizable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <Box>
                    {
                        <>
                            <FormattedNumber value={cellValues.row.discount2 || 0} maximumFractionDigits={2}
                                             style="percent"/>
                        </>
                    }
                </Box>
            )
        }
    },
    {
        field: 'priceWithTax',
        headerName: 'orderLineGridColUnitPriceInclTax',
        type: 'number',
        headerAlign: "center",
        align: "center",
        width: 170,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row.netPrice || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row.netPrice
    },
    {
        field: 'itemTotalExTax',
        headerName: 'orderVisualisationPageGridOrderLinesItemTotalExcludingTaxes',
        type: 'number',
        headerAlign: "center",
        align: "center",
        width: 170,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={(cellValues.row.netPrice) * (cellValues.row.quantity) || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => Number((cellValues.row.netPrice) * Number(cellValues.row.quantity)).toFixed(2)
    }
]