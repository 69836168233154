import {EnumData, Parameter, ParameterRateCode} from "./parameter";
import {Society} from "./society";

export interface CustomerAllFormData {
    countries: Parameter[]
    currencies: Parameter[]
    languages: Parameter[]
    businessForms: Parameter[]
    accountingCodes: Parameter[]
    customerCategories: Parameter[]
    superCustomerFamilies: Parameter[]
    customerFamilies: Parameter[]
    paymentChoices: Parameter[]
    promotionalRateCodes: Parameter[]
    vatManagements: EnumData[]
    representatives: Parameter[]
    rateCodes: Parameter[]
}

export interface Customer {
    id: number
    code: string
    society: Society
    businessForm?: Parameter
    businessName?: string
    language?: Parameter
    currency?: Parameter
    accountingCode?: Parameter
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    isBlocked?: boolean
    rateCode?: ParameterRateCode
    status?: Parameter
    saleRepresentative1?: Parameter
    deliveryAddresses: Address[]
    vatManagement?: EnumData
    superFamily?: Parameter
    family?: Parameter
    category?: Parameter
    paymentChoice?: Parameter
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCode?: ParameterRateCode
    sendEmailAtOrderSubmission?: boolean
    customerOutstanding?: number
    amountDue?: number
    promotionalRatePercentage?: number
}

export interface CustomerFilter {
    search?: string
    si?: string
    ra?: number | string
    iv?: string
    rp?: number | string
    zc?: string
    block?: boolean
    ca?: string[]
    sf?: string[]
    fa?: string[]
    va?: string[]
    re?: string[]
}

export interface CustomerForm {
    id?: number
    societyId?: number
    code?: string
    businessFormId?: number
    businessName?: string
    languageId?: number
    currencyId?: number
    accountingCodeId?: number
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    countryId?: number
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    isBlocked?: boolean
    statusId?: number
    saleRepresentative1Id?: number
    vatManagement?: string
    superFamilyId?: number
    familyId?: number
    categoryId?: number
    paymentChoiceId?: number
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCodeId?: number
    rateCodeId?: number
    sendEmailAtOrderSubmission?: boolean
    customerOutstanding?: number
    amountDue?: number
    promotionalRatePercentage?: number
}

export interface Address {
    id?: number
    title: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter | null
}

export interface CustomerSuperFamiliesAndFamilies {
    idSF: number,
    codeSF: string,
    labelSF: string,
    idF?: number,
    codeF?: string,
    labelF?: string
}

export const customerToForm = (customer: Customer): CustomerForm => {
    return {
        id: customer.id,
        societyId: customer.society.id,
        code: customer.code,
        businessFormId: customer.businessForm?.id,
        businessName: customer.businessName,
        languageId: customer.language?.id,
        currencyId: customer.currency?.id,
        accountingCodeId: customer.accountingCode?.id,
        siret: customer.siret,
        intraCommunityVAT: customer.intraCommunityVAT,
        apeNaf: customer.apeNaf,
        eanCode: customer.eanCode,
        stockCapital: customer.stockCapital,
        rcs: customer.rcs,
        address1: customer.address1,
        address2: customer.address2,
        address3: customer.address3,
        zipCode1: customer.zipCode1,
        zipCode2: customer.zipCode2,
        city: customer.city,
        countryId: customer.country?.id,
        phone: customer.phone,
        phone2: customer.phone2,
        fax: customer.fax,
        mail: customer.mail,
        website: customer.website,
        iban: customer.iban,
        bic: customer.bic,
        isBlocked: customer.isBlocked,
        rateCodeId: customer.rateCode?.id,
        statusId: customer.status?.id,
        saleRepresentative1Id: customer.saleRepresentative1?.id,
        vatManagement: customer.vatManagement?.value,
        superFamilyId: customer.superFamily?.id,
        familyId: customer.family?.id,
        categoryId: customer.category?.id,
        paymentChoiceId: customer.paymentChoice?.id,
        transitTime: customer.transitTime,
        acceptRemainder: customer.acceptRemainder,
        saleRatePercentage: customer.saleRatePercentage,
        promotionalRateCodeId: customer.promotionalRateCode?.id,
        sendEmailAtOrderSubmission: customer.sendEmailAtOrderSubmission,
        customerOutstanding:customer.customerOutstanding,
        amountDue:customer.amountDue,
        promotionalRatePercentage: customer.promotionalRatePercentage,
    }
}