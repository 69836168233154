import React, {FC, useEffect, useState} from 'react';
import {Button, Grid, LinearProgress, Paper, Tab, Tabs, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Address,
    Customer,
    CustomerAllFormData,
    CustomerForm,
    customerToForm
} from "../../../../../../corelogic/models/customer";
import {CustomerFormComponent} from "./CustomerFormComponent";
import {
    addCustomerDeliveryAddress,
    createCustomer,
    deleteCustomerDeliveryAddress,
    retrieveCustomerById,
    retrieveCustomerFormDataValues,
    updateCustomer,
    updateCustomerDeliveryAddress
} from "../../../../../../corelogic/usecase/customer/customersActions";
import {useDispatch, useSelector} from "react-redux";
import {
    getCustomerFormSelector,
    getCustomerSelectedSelector
} from "../../../../../../store/selectors/customersSelector";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import CustomerHeader from "../CustomerHeader";
import InfoIcon from "@mui/icons-material/Info";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {CustomerView} from "./CustomerView";
import {Rib} from "../../../Utils/Rib";
import {CustomerAddressTab} from "./CustomerAddressesTab";
import {MODES_ACTION} from "../../../../../secondary/config";
import {User} from "../../../../../../corelogic/models/user";
import Container from "@mui/material/Container";

// ---------------------- TABS INTERFACE & DEFINITIONS ----------------------
interface TabPanelProps {
    index: number
    value: number
}

const TabPanel: FC<TabPanelProps> = ({children, value, index, ...other}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

// ---------------------------------------------------------------------------
export const CustomerPageStore = () => {
    const {customerSelected, fetchingOnAction} = useSelector(getCustomerSelectedSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {dataFormFields} = useSelector(getCustomerFormSelector)
    const dispatch = useDispatch()
    const paramsUrl = useParams()
    const location = useLocation()

    useEffect(() => {
        if (userLogged?.linkedCustomer) {
            dispatch(retrieveCustomerById(userLogged?.linkedCustomer.id))
        } else if (paramsUrl.customerId) {
            dispatch(retrieveCustomerById(Number(paramsUrl.customerId)))
        }
        return () => {
            dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_SELECTION})
        }
    }, [dispatch, paramsUrl.customerId, userLogged?.linkedCustomer])

    useEffect(() => {
        dispatch(retrieveCustomerFormDataValues())
    }, [dispatch])

    return (
        fetchingOnAction ?
            <LinearProgress sx={{
                margin: "auto",
                marginBottom: "5px",
                height: "15px"
            }}/>
            :
            <CustomerPage userLogged={userLogged} dataCustomer={customerSelected}
                          dataFormFields={dataFormFields} creation={location.pathname === "/customers/creation"}/>
    )
}

interface CustomerPageInterface {
    userLogged: User | null
    creation?: boolean
    dataCustomer: Customer | null
    dataFormFields: CustomerAllFormData
}

const CustomerPage = (props: CustomerPageInterface) => {
    const {userLogged, dataCustomer, dataFormFields, creation} = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const intl = useIntl()
    const [tabIndex, setTabIndex] = useState(0)
    const [actionMode, setActionMode] = useState(creation ? MODES_ACTION.CREATE : MODES_ACTION.READ)
    const [customerForm, setCustomerForm] = useState<CustomerForm>({societyId: userLogged?.defaultSociety.id})
    const [formError, setFormError] = useState(false)

    // ---------------------- CHANGE TABS ----------------------
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue)
    }

    // ---------------------- ACTION PAGE BUTTONS FUNCTIONS ----------------------
    const handleEnterEditMode = () => {
        setActionMode(MODES_ACTION.UPDATE)
        if (dataCustomer) {
            setCustomerForm(customerToForm(dataCustomer))
        }
    }

    const handleExitEditMode = (mode: MODES_ACTION) => {
        if (mode === MODES_ACTION.UPDATE) {
            setActionMode(MODES_ACTION.READ)
        } else if (mode === MODES_ACTION.CREATE) {
            navigate(-1)
        }
    }

    // ---------------------- DELIVERY ADDRESSES TAB FUNCTIONS ----------------------
    const handleAddCustomerDeliveryAddress = (address: Address) => {
        if (dataCustomer?.id) {
            dispatch(addCustomerDeliveryAddress(dataCustomer?.id, address))
        }
    }

    const handleUpdateCustomerDeliveryAddress = (address: Address) => {
        dispatch(updateCustomerDeliveryAddress(address))
    }

    const handleDeleteDeliveryAddress = (id: number) => {
        dispatch(deleteCustomerDeliveryAddress(id))
    }

    // ---------------------- CUSTOMER FORM FUNCTIONS ----------------------
    const handleValidateForm = () => {
        if (customerForm && actionMode === MODES_ACTION.UPDATE) {
            dispatch(updateCustomer(customerForm))
        } else if (customerForm && actionMode === MODES_ACTION.CREATE) {
            dispatch(createCustomer(customerForm))
            navigate(-1)
        }
    }

    const handleChangeCustomerFormValues = (form: CustomerForm) => {
        setCustomerForm({...customerForm, ...form})
    }

    const handleChangeRIBValues = (target: string, value: any) => {
        if (customerForm) {
            setCustomerForm({...customerForm, [target]: value})
        }
    }

    const handleChangeCustomerFormErrorState = (formErrorState: boolean) => {
        setFormError(formErrorState)
    }

    return (
        <>
            <Box sx={{position: "sticky", top: "77px", zIndex: 1000}}>
                <CustomerHeader dataClient={dataCustomer}/>
            </Box>
            <Container maxWidth="xl">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid container justifyContent="center" alignItems="center" position="relative" flexWrap="wrap"
                          spacing={2} sx={{position: "sticky", top: "120px", zIndex: 1000}}>
                        {/***************** TABS ******************/}
                        <Grid item sm={tabIndex === 2 ? 12 : 11}>
                            <Paper sx={{display: "flex", justifyContent: "center"}}>
                                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab
                                        label={intl.formatMessage(getMessageDescriptor("customerPageGeneralInformationTitle"))}
                                        icon={<InfoIcon/>}
                                        iconPosition="start" {...a11yProps(0)} />
                                    <Tab
                                        label={intl.formatMessage(getMessageDescriptor("customerPageBankIdentificationTitle"))}
                                        icon={<AccountBalanceWalletIcon/>}
                                        iconPosition="start" {...a11yProps(1)} />
                                    {actionMode === MODES_ACTION.READ &&
                                        <Tab
                                            label={intl.formatMessage(getMessageDescriptor("customerPageDeliveryAddressTitle"))}
                                            icon={<LocalShippingIcon/>}
                                            iconPosition="start" {...a11yProps(2)} />}
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid item sm={1}>
                            {([0, 1].includes(tabIndex)) && (
                                <ActionsPageButton
                                    editMode={actionMode === MODES_ACTION.UPDATE || actionMode === MODES_ACTION.CREATE}
                                    onCancel={actionMode === MODES_ACTION.CREATE ? () => handleExitEditMode(MODES_ACTION.CREATE) : () => handleExitEditMode(MODES_ACTION.UPDATE)}
                                    onEnterEditMode={handleEnterEditMode}
                                    onValidate={handleValidateForm}
                                    disableValidate={formError}/>)}
                        </Grid>
                    </Grid>
                    <Grid container minHeight="75vh" alignItems="center" justifyContent="center">
                        {actionMode === MODES_ACTION.CREATE ?
                            <Grid item container width="100%">
                                <TabPanel value={tabIndex} index={0}>
                                    {/***************** CUSTOMER CREATION FORM ******************/}
                                    <CustomerFormComponent data={customerForm} dataFormFields={dataFormFields}
                                                           onChangeCustomerFormValues={handleChangeCustomerFormValues}
                                                           onChangeCustomerFormErrorState={handleChangeCustomerFormErrorState}/>
                                </TabPanel>
                                <Grid item width="100%">
                                    <TabPanel value={tabIndex} index={1}>
                                        {/***************** RIB ******************/}
                                        <Rib editMode={true} onChange={handleChangeRIBValues}/>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                            :
                            <Grid item container width="100%">
                                <TabPanel value={tabIndex} index={0}>
                                    {/***************** CUSTOMER FORM/VIEW ******************/}
                                    {actionMode === MODES_ACTION.UPDATE && dataCustomer && customerForm ?
                                        <CustomerFormComponent data={customerForm}
                                                               dataFormFields={dataFormFields}
                                                               onChangeCustomerFormValues={handleChangeCustomerFormValues}
                                                               onChangeCustomerFormErrorState={handleChangeCustomerFormErrorState}/>
                                        : dataCustomer &&
                                        <CustomerView data={dataCustomer}
                                                      userLoggedRoleCode={userLogged?.role?.code}/>

                                    }
                                </TabPanel>
                                <Grid item width="100%">
                                    <TabPanel value={tabIndex} index={1}>
                                        {/***************** RIB ******************/}
                                        {customerForm &&
                                            <Rib editMode={actionMode === MODES_ACTION.UPDATE}
                                                 onChange={handleChangeRIBValues}
                                                 iban={actionMode === MODES_ACTION.UPDATE ? customerForm.iban : dataCustomer?.iban}
                                                 bic={actionMode === MODES_ACTION.UPDATE ? customerForm.bic : dataCustomer?.bic}/>
                                        }
                                    </TabPanel>
                                </Grid>
                                {/***************** DELIVERY ADRESSES TAB ******************/}
                                <Grid item width="100%">
                                    <TabPanel value={tabIndex} index={2}>
                                        {dataCustomer && <CustomerAddressTab
                                            dataCustomerId={dataCustomer.id}
                                            onAdd={handleAddCustomerDeliveryAddress}
                                            onUpdate={handleUpdateCustomerDeliveryAddress}
                                            onDelete={handleDeleteDeliveryAddress}/>}
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

interface ActionsPageButtonInterface {
    editMode?: boolean,
    onValidate?: () => void,
    onCancel?: () => void,
    onEnterEditMode?: () => void
    disableValidate?: boolean
}

export const ActionsPageButton = (props: ActionsPageButtonInterface) => {
    const {editMode, onValidate, onCancel, onEnterEditMode, disableValidate} = props
    const intl = useIntl()

    return (
        <Paper>
            {editMode ?
                <Grid container direction="column">
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericValidate"))}>
                        <Button onClick={onValidate}
                                disabled={onValidate === undefined || disableValidate}><CheckCircleIcon/></Button>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericCancel"))}>
                        <Button onClick={onCancel} disabled={onCancel === undefined}><CancelIcon
                            sx={{fill: "rgba(255, 82, 82, 1)"}}/></Button>
                    </Tooltip>
                </Grid>
                :
                <Grid container direction="column">
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericEditMode"))}>
                        <Button onClick={onEnterEditMode}
                                disabled={onEnterEditMode === undefined}><EditIcon/></Button>
                    </Tooltip>
                    <Button disabled><DeleteIcon sx={{fill: "grey"}}/></Button>
                </Grid>}
        </Paper>
    )
}

export default CustomerPage;