import React, {useEffect} from 'react';
import {LinearProgress, Paper} from "@mui/material";
import UserPageHeader from "./UserPageHeader";
import {useDispatch, useSelector} from "react-redux";
import {
    retrieveSelectedUser,
    retrieveUserFormDataValues,
    updateUser,
    updateUserPassword,
    updateUserPasswordAsAdmin
} from "../../../../../../corelogic/usecase/user/userActions";
import {User, UserAllFormData, UserPasswordForm} from "../../../../../../corelogic/models/user";
import {getUserFormSelector, getUserSelectedSelector} from "../../../../../../store/selectors/usersSelectors";
import {useNavigate, useParams} from "react-router-dom";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import Container from "@mui/material/Container";
import {UserGeneralInformationForm} from "./UserGeneralInformationForm";
import Box from "@mui/material/Box";

export const UserPageStore = () => {
    const {userSelected, fetchingSelected} = useSelector(getUserSelectedSelector)
    const {dataFormFields} = useSelector(getUserFormSelector)

    const dispatch = useDispatch()
    const paramsUrl = useParams()

    useEffect(() => {
        dispatch(retrieveUserFormDataValues())
    }, [dispatch])

    useEffect(() => {
        if (paramsUrl.userId) {
            dispatch(retrieveSelectedUser(Number(paramsUrl.userId)))
        }
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.users.USER_RESET_SELECTING})
    }, [dispatch, paramsUrl.userId])

    return (
        fetchingSelected ?
            <LinearProgress sx={{
                margin: "auto",
                marginBottom: "5px",
                height: "15px"
            }}/>
            : userSelected ?
                <UserPage data={userSelected} dataFormFields={dataFormFields}/>
                : <Box>No data found</Box>


    )
}

interface UserPageInterface {
    data: User
    dataFormFields: UserAllFormData
}

const UserPage = (props: UserPageInterface) => {
    const dispatch = useDispatch()
    const {data, dataFormFields} = props
    const navigate = useNavigate()

    const handleUpdateUser = (user: User) => {
        dispatch(updateUser(user))
        navigate(-1)
    }

    const handleUpdateUserPassword = (passwordForm: UserPasswordForm) => {
        dispatch(updateUserPassword(passwordForm))
    }

    const handleUpdateUserAdminPassword = (password: string) => {
        dispatch(updateUserPasswordAsAdmin(password, data.id))
    }
    return (
        <>
            <Paper elevation={3}
                   sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <UserPageHeader data={data} onPasswordSubmit={handleUpdateUserPassword}
                                onPasswordSubmitAdmin={handleUpdateUserAdminPassword}/>
            </Paper>
            <Container maxWidth="xl">
                <UserGeneralInformationForm data={data} dataFormFields={dataFormFields}
                                            onValidate={handleUpdateUser}/>
            </Container>
        </>
    )
}

export default UserPage;