import {GridRenderEditCellParams, useGridApiContext} from "@mui/x-data-grid";
import * as React from "react";
import {useLayoutEffect, useRef, useState} from "react";
import {NumericFormat} from "react-number-format";
import {TextField, TextFieldProps, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../i18n/messages";

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} children={props.children}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

const CustomInput = (inputProps: TextFieldProps) => {

    const {error, title} = inputProps;
    return <StyledTooltip open={!!error} title={title}><TextField {...inputProps} title={""} inputRef={inputProps.ref}
                                                                  autoFocus/></StyledTooltip>
}

interface CustomEditComponentProps extends GridRenderEditCellParams {
    suffix?: string,
    decimalScale?: number
}

export const CustomEditComponentPercent = (props: GridRenderEditCellParams) => {
    const {suffix = "%", value, decimalScale = 2} = props
    return <CustomNumericEditComponent {...props} value={value * 100} suffix={suffix} decimalScale={decimalScale}/>
}
export const CustomEditComponentCurrency = (props: CustomEditComponentProps) => {
    const {suffix = "€", decimalScale = 2} = props
    return <CustomNumericEditComponent {...props} suffix={suffix} decimalScale={decimalScale}/>
}

export const CustomNumericEditComponent = (props: CustomEditComponentProps) => {
    const {id, value, field, hasFocus, error, errorTextId, suffix, decimalScale = 0} = props;
    const apiRef = useGridApiContext();
    let ref = useRef<HTMLInputElement>(null)
    const [val, setVal] = useState<number | undefined>(value)
    const {formatters, locale, formatMessage} = useIntl()
    const decimalSepLocal = formatters.getNumberFormat(locale).formatToParts(1000.1).find(p => p.type === "decimal")?.value || ","
    const thousandSepLocal = formatters.getNumberFormat(locale).formatToParts(1000.1).find(p => p.type === "group")?.value || " "

    useLayoutEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
        }
    }, [hasFocus, val]);
    const handleValueChange = (event: { target: { name: string; value?: number } }) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue({id, field, value: event.target.value})
        setVal(newValue)
    }

    return <NumericFormat
        error={error}
        title={errorTextId ? formatMessage(getMessageDescriptor(errorTextId)) : ""}
        customInput={CustomInput}
        onValueChange={(values) => {
            handleValueChange({
                target: {
                    name: props.name,
                    value: values.value === "" ? undefined : Number(values.value),
                }
            })
        }}
        value={val}
        decimalScale={decimalScale}
        decimalSeparator={decimalSepLocal}
        thousandSeparator={thousandSepLocal}
        allowNegative={false}
        suffix={` ${suffix}`}
    />
}

export const CustomEditComponent = (props: GridRenderEditCellParams) => {
    const {id, value, field, hasFocus} = props
    const apiRef = useGridApiContext()
    const ref = useRef<HTMLInputElement>(null)

    React.useLayoutEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
        }
    }, [hasFocus])

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue({id, field, value: newValue})
    };

    return <TextField ref={ref} type="text" value={value} onChange={handleValueChange}
                      autoFocus/>
}