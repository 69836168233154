import React from 'react';
import {Grid, Paper} from "@mui/material";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {useIntl} from "react-intl";

const UserCreationPageHeader = () => {
    const intl = useIntl()

    return (
        <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
            <Grid container alignItems="center">
                <Grid item>
                    <ReturnButton/>
                </Grid>
                <Grid item mx={2}>
                    <DividingHR
                        subContent
                        style={{height: "30px", width: 1, margin: 0}}/>
                </Grid>
                <Grid item>
                    <TextValueField
                        style={{
                            fontWeight: "bolder"
                        }}
                        text={intl.formatMessage(getMessageDescriptor("userHeaderTitle"))}
                        value={"En cours de création"}/>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserCreationPageHeader;