import {Parameter} from "../../../../corelogic/models/parameter";
import {Interlocutor, Society} from "../../../../corelogic/models/society";

export interface SocietyApiResponse {
    id: number
    code: string
    label?: string
    siret?: string
    intraVat?: string
    eanCode?: string
    apeNaf?: string
    rcs?: string
    stockCapital?: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    phone?: string
    fax?: string
    mail?: string
    website?: string
    sendEmailAtOrderSubmission?: boolean
    blockAllEmailShipmentsAtOrderSubmission?: boolean
    freeManagementOrderLine?: boolean
    country?: Parameter
    language?: Parameter
    currency?: Parameter
    comInterlocutor?: Interlocutor
    techInterlocutor?: Interlocutor
    adminInterlocutor?: Interlocutor
    orderSubmissionEmails?:string
    sendingMailAtUserCreation?: boolean
    authorizationToChangeOrderLineItemLabel?: boolean
    replaceOrderLineItemLabelFromOrderScheduler?: boolean
    defaultOrderUnitPackage?: boolean
}

export const mapSocietyApiResponseToSociety = (society: SocietyApiResponse): Society => {
    return {
        ...society,
        language: society.language,
        currency: society.currency,
        country: society.country,
        comInterlocutor: society.comInterlocutor,
        techInterlocutor: society.techInterlocutor,
        adminInterlocutor: society.adminInterlocutor
    }
}