import {AppState} from "store/appState";

export const getUserLoggedSelector = (state: AppState) => {
    return {userLogged: state.session.userLogged, fetching: state.session.fetching}
}

export const getActiveAuthTokenSelector = (state: AppState) => {
    return {activeAuthToken: state.session.activeAuthToken, fetching: state.session.fetching}
}

export const getCurrentEnvironment = (state: AppState) => {
    return {currentDepot: state.session.currentDepot}
}