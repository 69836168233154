import {AuthenticationGateway} from "corelogic/gateways/AuthenticationGateway"
import {GatewayInitialize, IGateways} from "../config"
import {ApiAuthenticationGateway} from "./ApiAuthenticationGateway"
import {ItemGateway} from "../../../corelogic/gateways/ItemGateway";
import {ApiItemGateway} from "./ApiItemGateway";
import {OrderGateway} from "../../../corelogic/gateways/OrderGateway";
import {ApiOrderGateway} from "./ApiOrderGateway";
import {UserGateway} from "../../../corelogic/gateways/UserGateway";
import {ApiUserGateway} from "./ApiUserGateway";
import {OrderLineGateway} from "../../../corelogic/gateways/OrderLineGateway";
import {ApiOrderLineGateway} from "./ApiOrderLineGateway";
import {CustomerGateway} from "../../../corelogic/gateways/CustomerGateway";
import {ApiCustomerGateway} from "./ApiCustomerGateway";
import {CustomerAddressesGateway} from "../../../corelogic/gateways/CustomerAddressesGateway";
import {ApiCustomerAddressesGateway} from "./ApiCustomerAddressesGateway";
import {ApiUserItemFavoritesGateway} from "./ApiUserItemFavoritesGateway";
import {UserItemFavoritesGateway} from "../../../corelogic/gateways/UserItemFavoritesGateway";
import {ItemPackagingGateway} from "../../../corelogic/gateways/ItemPackagingGateway";
import {ApiItemPackagingGateway} from "./ApiItemPackagingGateway";
import {CustomerItemReferenceGateway} from "../../../corelogic/gateways/CustomerItemReferenceGateway";
import {ApiCustomerItemReferenceGateway} from "./ApiCustomerItemReferencesGateway";
import {SocietyGateway} from "../../../corelogic/gateways/SocietyGateway";
import {ApiSocietyGateway} from "./ApiSocietyGateway";
import {ParameterGateway} from "../../../corelogic/gateways/ParameterGateway";
import {ApiParameterGateway} from "./ApiParameterGateway";
import {StatsGateway} from "../../../corelogic/gateways/StatsGateway";
import {ApiStatsGateway} from "./ApiStatsGateway";
import {ToolsGateway} from "../../../corelogic/gateways/ToolsGateway";
import {ApiToolsGateway} from "./ApiToolsGateway";
import {ServerGateway} from "../../../corelogic/gateways/ServerGateway";
import {ApiServerGateway} from "./ApiCustomerSpecificApi";

export class ApiInitialize implements GatewayInitialize {
    private readonly authenticationGateway: AuthenticationGateway | null
    private readonly customerAddressesGateway: CustomerAddressesGateway | null
    private readonly customerGateway: CustomerGateway | null
    private readonly customerItemReferenceGateway: CustomerItemReferenceGateway | null
    private readonly itemGateway: ItemGateway | null
    private readonly userItemFavoritesGateway: UserItemFavoritesGateway | null
    private readonly orderGateway: OrderGateway | null
    private readonly orderLineGateway: OrderLineGateway | null
    private readonly userGateway: UserGateway | null
    private readonly itemPackagingGateway: ItemPackagingGateway | null
    private readonly societyGateway: SocietyGateway | null
    private readonly parameterGateway: ParameterGateway | null
    private readonly statsGateway: StatsGateway | null
    private readonly toolsGateway: ToolsGateway | null
    private readonly serverGateway: ServerGateway | null

    constructor() {
        this.customerAddressesGateway = new ApiCustomerAddressesGateway()
        this.customerGateway = new ApiCustomerGateway()
        this.customerItemReferenceGateway = new ApiCustomerItemReferenceGateway()
        this.itemGateway = new ApiItemGateway()
        this.userItemFavoritesGateway = new ApiUserItemFavoritesGateway()
        this.orderGateway = new ApiOrderGateway()
        this.orderLineGateway = new ApiOrderLineGateway()
        this.userGateway = new ApiUserGateway()
        this.userGateway = new ApiUserGateway()
        this.itemPackagingGateway = new ApiItemPackagingGateway()
        this.societyGateway = new ApiSocietyGateway()
        this.authenticationGateway = ApiAuthenticationGateway.getInstance()
        this.parameterGateway = new ApiParameterGateway()
        this.statsGateway = new ApiStatsGateway()
        this.toolsGateway = new ApiToolsGateway()
        this.serverGateway = new ApiServerGateway()
    }

    getAllGateways(): IGateways {
        return {
            authenticationGateway: this.authenticationGateway,
            itemGateway: this.itemGateway,
            orderGateway: this.orderGateway,
            orderLineGateway: this.orderLineGateway,
            userItemFavoritesGateway: this.userItemFavoritesGateway,
            customerGateway: this.customerGateway,
            customerAddressesGateway: this.customerAddressesGateway,
            customerItemReferenceGateway: this.customerItemReferenceGateway,
            userGateway: this.userGateway,
            societyGateway: this.societyGateway,
            itemPackagingGateway: this.itemPackagingGateway,
            parameterGateway: this.parameterGateway,
            statsGateway: this.statsGateway,
            toolsGateway: this.toolsGateway,
            serverGateway: this.serverGateway,
        }
    }
}