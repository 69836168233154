import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "store/appState";
import {SocietyGateway} from "../../gateways/SocietyGateway";
import {SocietyForm} from "../../models/society";

export const retrieveAllSocieties = ():
    ThunkResult<{ societyGateway: SocietyGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {societyGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETIES_RETRIEVING})
        try {
            const societiesData = await societyGateway.retrieveAll()
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETIES_RETRIEVED, payload: societiesData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSelectedSociety = (societyId: number):
    ThunkResult<{ societyGateway: SocietyGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {societyGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_FETCHING})
        try {
            const societiesData = await societyGateway.retrieveById(societyId)
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_SELECTING, payload: societiesData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSocietyFormDataValues = ():
    ThunkResult<{ societyGateway: SocietyGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {societyGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_FETCHING})
        try {
            const societiesFormDataVal = await societyGateway.retrieveAllDataFormField()
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETIES_FORM_DATA_FIELDS, payload: societiesFormDataVal})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const createSociety = (society: SocietyForm):
    ThunkResult<{ societyGateway: SocietyGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {societyGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_FETCHING})
        try {
            const societyData = await societyGateway.create(society)
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_ADD, payload: societyData})
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_SELECTING, payload: societyData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateSociety = (society: SocietyForm):
    ThunkResult<{ societyGateway: SocietyGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {societyGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const societiesData = await societyGateway.update(society)
            dispatch({type: APP_ACTIONS_TYPES.societies.SOCIETY_UPDATE, payload: societiesData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

// export const deleteUser = (id: number):
//     ThunkResult<{ userGateway: UserGateway }> => {
//     return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
//         dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
//         try {
//             await userGateway.delete(id)
//             dispatch({type: APP_ACTIONS_TYPES.users.USER_DELETE, payload: id})
//             const usersData = await userGateway.retrieveAll()
//             dispatch({type: APP_ACTIONS_TYPES.users.USERS_RETRIEVED, payload: usersData})
//         } catch (e) {
//             dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
//         }
//     }
// }