import {OrderStatusButton} from "../../../Utils/OrderStatusButton";
import {OrderStatus} from "../../../../../secondary/InMemory/Data/enums";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {Grid} from "@mui/material";
import React from "react";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {Order} from "../../../../../../corelogic/models/order";

interface OrderVisualisationPageHeaderProps {
    order: Order
    isCustomer?: boolean
}

export const OrderVisualisationPageHeader = (props: OrderVisualisationPageHeaderProps) => {
    const {order,isCustomer} = props
    const intl = useIntl()

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextValueField
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("orderVisualisationPageHeaderTitle"))}
                                        value={order.sodiwinNumOrder ? order.sodiwinNumOrder : order.numOrder ? order.numOrder : ""}/>
                                </Grid>
                                {!isCustomer && <Grid item>
                                    <TextValueField style={{fontWeight: "bolder"}}
                                                    text={intl.formatMessage(getMessageDescriptor("orderGridColCodeClient"))}
                                                    value={order.orderedCustomer?.code || ""}/>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <OrderStatusButton
                        status={order.status ? order.status : OrderStatus.CREATION}/>
                </Grid>
            </Grid>
        </>
    )
}