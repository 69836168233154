import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useNavigate} from "react-router-dom";

interface NavbarButtons {
    label: string
    icon?: React.ReactNode
    path?: string
    pathDropDown?: NavbarDropDown[]
    onClick?: () => void
    isLogout?: boolean
}

interface NavbarDropDown {
    label: string
    path: string
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

export const NavbarDropDownButton = (props: NavbarButtons) => {
    const {label, icon, pathDropDown} = props
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleNavigate = (path: string) => {
        navigate(`${path}`)
        handleClose()
    }

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                fullWidth
                onClick={handleClick}
                sx={{backgroundColor: "rgb(31,141,229)"}}
                startIcon={icon}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                {label}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {pathDropDown?.map(dropDownItem =>
                    <MenuItem onClick={() => handleNavigate(dropDownItem.path)} disableRipple>
                        <NavbarClassicButton
                            label={dropDownItem.label}
                        />
                    </MenuItem>)
                }
            </StyledMenu>
        </>
    );
}

export const NavbarClassicButton = (props: NavbarButtons) => {
    const {label, icon, path} = props
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`${path}`)
    }
    return (
        <>
            <Button
                id="demo-customized-button"
                variant="contained"
                disableElevation
                fullWidth
                sx={{backgroundColor: "rgb(31,141,229)"}}
                startIcon={icon}
                onClick={handleClick}>
                {label}
            </Button>
        </>
    );
}

export const NavbarClassicButtonPersonnalized = (props: NavbarButtons) => {
    const {label, icon, onClick, isLogout} = props
    const handleClick = () => {
        if (onClick) {
            onClick()
        }
    }
    return (
        <>
            <Button
                id="demo-customized-button"
                variant="contained"
                disableElevation
                fullWidth
                startIcon={icon}
                color={isLogout ? "secondary" : "primary"}
                onClick={handleClick}>
                {label}
            </Button>
        </>
    );
}