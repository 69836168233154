import React from "react";
import {Grid, Paper, TextField} from "@mui/material";
import {DividingHR} from "./DividingHR";
import Typography from "@mui/material/Typography";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../i18n/messages";
import Container from "@mui/material/Container";

interface RibComponentProps {
    iban?: string,
    bic?: string,
    editMode?: boolean,
    onChange?: (target: string, value: any) => void
}

export interface RibDetails {
    bankCode: string
    branchCode: string
    accountNum: string
    ribKey: string
}

export function extractIbanData(iban: string): RibDetails {
    let splitIban = {bankCode: "", branchCode: "", accountNum: "", ribKey: ""}

    if (!iban || iban.length < 9 || iban.length > 27)
        return splitIban

    splitIban.bankCode = iban.substring(4, 9)
    if (iban.length > 13)
        splitIban.branchCode = iban.substring(9, 14)
    if (iban.length > 24)
        splitIban.accountNum = iban.substring(14, 25)
    if (iban.length === 27)
        splitIban.ribKey = iban.substring(25)
    return splitIban
}

export function diplayIban(iban: string): string {
    if (!iban || iban.length !== 27)
        return ""

    return iban.substring(0, 4) + " " +
        iban.substring(4, 8) + " " +
        iban.substring(8, 12) + " " +
        iban.substring(12, 16) + " " +
        iban.substring(16, 20) + " " +
        iban.substring(20, 24) + " " +
        iban.substring(24)
}

export const Rib = (props: RibComponentProps) => {
    const {bic, iban, onChange, editMode} = props
    const intl = useIntl()

    const ibanDetails = extractIbanData(iban ? iban : "")

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (onChange) {
            onChange(e.target.name, e.target.value)
        }
    }

    return (
        <Container maxWidth="xl">
            <Paper elevation={2} sx={{margin: "10px", padding: "15px", width: "100%"}}>
                <Grid container direction="column" spacing={2}>
                    <Grid container item justifyContent="center" alignItems="center">
                        <AccountBalanceWalletIcon className="img-title-card" sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                        <Typography variant="h5" component="h2">
                            {intl.formatMessage(getMessageDescriptor("ribTitle"))}
                        </Typography>
                    </Grid>
                    <DividingHR/>
                    <Grid container item justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <Paper sx={{minHeight: "97px"}}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <Typography color="text.primary">
                                            {intl.formatMessage(getMessageDescriptor("ribBankCode"))}
                                        </Typography>
                                    </Grid>
                                    <DividingHR subContent/>
                                    <Grid item>
                                        <p className="field-data">{ibanDetails.bankCode}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <Paper sx={{minHeight: "97px"}}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <Typography color="text.primary">
                                            {intl.formatMessage(getMessageDescriptor("ribCounterCode"))}
                                        </Typography>
                                    </Grid>
                                    <DividingHR subContent/>
                                    <Grid item>
                                        <p className="field-data">{ibanDetails.branchCode}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <Paper sx={{minHeight: "97px"}}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <Typography color="text.primary">
                                            {intl.formatMessage(getMessageDescriptor("ribAccountNumber"))}
                                        </Typography>
                                    </Grid>
                                    <DividingHR subContent/>
                                    <Grid item>
                                        <p className="field-data">{ibanDetails.accountNum}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <Paper sx={{minHeight: "97px"}}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <Typography color="text.primary">
                                            {intl.formatMessage(getMessageDescriptor("ribKey"))}
                                        </Typography>
                                    </Grid>
                                    <DividingHR subContent/>
                                    <Grid item>
                                        <p className="field-data">{ibanDetails.ribKey}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <Paper className="rib-paper-container">
                                <Typography color="text.primary">
                                    {intl.formatMessage(getMessageDescriptor("ribIban"))}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Paper className="rib-paper-container">
                                {editMode ?
                                    <TextField onChange={handleChange} name="iban" value={iban} label="IBAN" fullWidth
                                               variant="filled" autoFocus/>
                                    :
                                    <Typography color="secondary">{iban ? diplayIban(iban) : ""}</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className="rib-paper-container">
                                <Typography color="text.primary">
                                    {intl.formatMessage(getMessageDescriptor("ribBicSwift"))}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Paper className="rib-paper-container">
                                {editMode ?
                                    <TextField onChange={handleChange} name="bic" value={bic} label="BIC / SWIFT"
                                               fullWidth
                                               variant="filled"/>
                                    :
                                    <Typography className="field-data">{bic || ""}</Typography>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}