import React from 'react';
import {App} from "./App";
import {Provider} from 'react-redux'
import {configureStore} from "./store";
import {ApiInitialize} from "./adapters/secondary/Api/ApiInitialize";
import {createRoot} from 'react-dom/client';

const apiGateways = new ApiInitialize().getAllGateways()
// const inMemoryItems = new InMemoryDataInitialize()
// inMemoryItems.feedWithData()
// const inMemoryGateways = inMemoryItems.getAllGateways()

const store = configureStore(apiGateways)
const root=createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <App/>
    </Provider>,
)
