import React, {useEffect, useRef, useState} from 'react';
import {Autocomplete, Checkbox, FormControlLabel, Grid, Paper, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DividingHR} from "../../../Utils/DividingHR";
import {CustomerAllFormData, CustomerForm} from "../../../../../../corelogic/models/customer";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {Controller, useForm} from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import Container from "@mui/material/Container";
import {
    ContactPhoneOutlined,
    DiscountOutlined,
    FolderOutlined,
    InfoOutlined,
    LocalShippingOutlined,
    LocationOnOutlined,
    PaymentsOutlined,
    WorkspacesOutlined
} from "@mui/icons-material";
import {MonetaryField, PercentField} from "../../../Utils/TextValueField";

export interface CustomerFormProps {
    data?: CustomerForm
    dataFormFields: CustomerAllFormData
    onChangeCustomerFormValues?: (form: CustomerForm) => void
    onChangeCustomerFormErrorState: (formErrorState: boolean) => void
}

export const CustomerFormComponent = (props: CustomerFormProps) => {
    const {
        data,
        dataFormFields,
        onChangeCustomerFormValues,
        onChangeCustomerFormErrorState
    } = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const [form, setForm] = useState<CustomerForm | undefined>(data)
    const [formError, setFormError] = useState<boolean>(false)
    const businessNameRef = useRef<HTMLInputElement | null>(null)
    const intl = useIntl()

    const {watch, control} = useForm<CustomerForm>(
        {
            defaultValues: {
                code: form?.code,
                businessName: form?.businessName,
                businessFormId: form?.businessFormId,
                languageId: form?.languageId,
                currencyId: form?.currencyId,
                siret: form?.siret,
                intraCommunityVAT: form?.intraCommunityVAT,
                apeNaf: form?.apeNaf,
                eanCode: form?.eanCode,
                address1: form?.address1,
                address2: form?.address2,
                address3: form?.address3,
                zipCode1: form?.zipCode1,
                zipCode2: form?.zipCode2,
                city: form?.city,
                countryId: form?.countryId,
                phone: form?.phone,
                phone2: form?.phone2,
                fax: form?.fax,
                website: form?.website,
                mail: form?.mail,
                sendEmailAtOrderSubmission: form?.sendEmailAtOrderSubmission,
                vatManagement: form?.vatManagement,
                accountingCodeId: form?.accountingCodeId,
                categoryId: form?.categoryId,
                familyId: form?.familyId,
                paymentChoiceId: form?.paymentChoiceId,
                saleRepresentative1Id: form?.saleRepresentative1Id,
                promotionalRateCodeId: form?.promotionalRateCodeId,
                superFamilyId: form?.superFamilyId,
                saleRatePercentage: form?.saleRatePercentage,
                rateCodeId: form?.rateCodeId,
                transitTime: form?.transitTime,
                acceptRemainder: form?.acceptRemainder,
                customerOutstanding: form?.customerOutstanding,
                amountDue: form?.amountDue,
                promotionalRatePercentage: form?.promotionalRatePercentage
            }
        }
    )

    useEffect(() => {
        const subscription = watch(data => {
            if (form) {
                const dataChange = {
                    ...form,
                    ...data
                }
                if (onChangeCustomerFormValues) {
                    onChangeCustomerFormValues(dataChange)
                }
                setForm(dataChange)
            }
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [form, onChangeCustomerFormValues, watch])

    useEffect(() => {
        const emailRegex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(;|$))*$/

        if (form?.id) {
            !form?.businessName || form?.businessName.trim().length === 0 || !emailRegex.test(form?.mail || "") ? setFormError(true) : setFormError(false)
        } else if (!form?.id) {
            !form?.businessName || form?.businessName.trim().length === 0 || !form.code || !emailRegex.test(form?.mail || "") ? setFormError(true) : setFormError(false)
        } else if (!emailRegex.test(form?.mail || "")) {
            setFormError(true)
        }
        onChangeCustomerFormErrorState(formError)
    }, [form, formError, onChangeCustomerFormErrorState])

    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={1}>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <InfoOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageGeneralInformationTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="code"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        autoFocus={!form?.id}
                                                        fullWidth
                                                        required
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldCode"))}
                                                        type="text"
                                                        disabled={!!form?.id}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="businessName"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        inputRef={businessNameRef}
                                                        autoFocus={!!form?.id}
                                                        fullWidth
                                                        required
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldBusinessName"))}
                                                        type="text"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="businessFormId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.businessForms}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.businessForms.find(l => l.id === form?.businessFormId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldBusinessForm"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="languageId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.languages}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.languages.find(l => l.id === form?.languageId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldLangage"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="currencyId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.currencies}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.currencies.find(l => l.id === form?.currencyId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldCurrency"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <WorkspacesOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageClassificationTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="superFamilyId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.superCustomerFamilies}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.superCustomerFamilies.find(l => l.id === form?.superFamilyId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormSuperFamily"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="familyId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.customerFamilies}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.customerFamilies.find(l => l.id === form?.familyId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFamily"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="categoryId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.customerCategories}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.customerCategories.find(l => l.id === form?.categoryId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormCategory"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <LocationOnOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageViewFieldMainAddress.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container direction="column" p={2} spacing={1}>
                                <Grid item>
                                    <Controller
                                        name="address1"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerAddressAddress1"))}
                                                type="text"
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        name="address2"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerAddressAddress2"))}
                                                type="text"
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        name="address3"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerAddressAddress3"))}
                                                type="text"
                                            />}
                                    />
                                </Grid>
                                <Grid container item spacing={1} p={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="zipCode1"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="standard"
                                                    label={intl.formatMessage(getMessageDescriptor("customerAddressZipCode1"))}
                                                    type="text"
                                                />}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="zipCode2"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="standard"
                                                    label={intl.formatMessage(getMessageDescriptor("customerAddressZipCode2"))}
                                                    type="text"
                                                />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={1} p={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="standard"
                                                    label={intl.formatMessage(getMessageDescriptor("customerAddressCity"))}
                                                    type="text"
                                                />}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="countryId"
                                            control={control}
                                            render={({field: {onChange, value}}) =>
                                                <Autocomplete
                                                    options={dataFormFields.countries}
                                                    onChange={(event, param) => {
                                                        onChange(param?.id)
                                                    }}
                                                    value={dataFormFields.countries.find(l => l.id === form?.countryId)}
                                                    getOptionLabel={(option => option.label || "")}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressCountry"))}
                                                                                        variant="standard"
                                                    />}
                                                />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <ContactPhoneOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageContactTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container direction="column" p={2} spacing={1}>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PhoneIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <Controller
                                        name="phone2"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone2"))}
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PhoneIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <Controller
                                        name="fax"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldFax"))}
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FaxIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} p={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Controller
                                        name="website"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldWebsite"))}
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LanguageIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Controller
                                        name="mail"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                type="email"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={intl.formatMessage(getMessageDescriptor("userPageFormFieldSendEmailAtOrderSubmission"))}
                                        control={
                                            <Controller
                                                name="sendEmailAtOrderSubmission"
                                                control={control}
                                                render={({field: props}) => (
                                                    <Checkbox
                                                        {...props}
                                                        checked={props.value}
                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <DiscountOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageRateTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="rateCodeId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.rateCodes}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.rateCodes.find(l => l.id === form?.rateCodeId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldRate"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="saleRatePercentage"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormSaleRatePercentage"))}
                                                        InputProps={{
                                                            inputComponent: PercentField as any,
                                                        }}
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="promotionalRateCodeId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.promotionalRateCodes}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.promotionalRateCodes.find(l => l.id === form?.promotionalRateCodeId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormPromotionalRateCode"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="promotionalRatePercentage"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormPromotionalSaleRatePercentage"))}
                                                        InputProps={{
                                                            inputComponent: PercentField as any,
                                                        }}
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <PaymentsOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageBillTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="paymentChoiceId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.paymentChoices}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.paymentChoices.find(l => l.id === form?.paymentChoiceId)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormPaymentChoice"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="vatManagement"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.vatManagements}
                                                        onChange={(event, param) => {
                                                            onChange(param?.value)
                                                        }}
                                                        value={dataFormFields.vatManagements.find(l => l.value === form?.vatManagement)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormVatManagement"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="saleRepresentative1Id"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                        options={dataFormFields.representatives}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields.representatives.find(l => l.id === form?.saleRepresentative1Id)}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldSaleRepresentative1Code"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container item alignItems="center" p={1}>
                                <FolderOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageAdministrativeTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="siret"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldSiret"))}
                                                        type="text"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="intraCommunityVAT"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldVat"))}
                                                        type="text"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="apeNaf"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldApeNaf"))}
                                                        type="text"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="eanCode"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEan"))}
                                                        type="text"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container alignItems="center" p={1}>
                                <LocalShippingOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageLogisticTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="transitTime"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormTransitTime"))}
                                                        type="number"
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                label={intl.formatMessage(getMessageDescriptor("customerPageFormAcceptRemainder"))}
                                                control={
                                                    <Controller
                                                        name="acceptRemainder"
                                                        control={control}
                                                        render={({field: props}) => (
                                                            <Checkbox
                                                                {...props}
                                                                checked={props.value}
                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                                disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                            />
                                                        )}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container sm={6}>
                        <Paper sx={{width: "100%"}}>
                            <Grid container alignItems="center" p={1}>
                                <LocalShippingOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                <Typography variant="h6" component="h2" fontWeight="bolder">
                                    <FormattedMessage id={messages.customerPageOutstandingTitle.id}/>
                                </Typography>
                            </Grid>
                            <DividingHR style={{marginTop: 0}}/>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="customerOutstanding"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormCustomerOutstanding"))}
                                                        InputProps={{
                                                            inputComponent: MonetaryField as any,
                                                        }}
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="amountDue"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormAmountDue"))}
                                                        InputProps={{
                                                            inputComponent: MonetaryField as any,
                                                        }}
                                                        disabled={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}