export interface CustomerSpecificData {
    customerSpecificCode: CustomerSpecificCode
    top1?: string
    top2?: string
    top3?: string
    top4?: string
}

export enum CustomerSpecificCode {
    NONE,
    XAVIER,
}