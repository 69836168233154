import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material"
import {APIError} from "corelogic/interfaces"
import {cleaningAPINetworkError} from "corelogic/usecase/generics/genericsActions"
import React, {useCallback, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getAPINetworkErrorSelector} from "store/selectors/genericsSelector"
import Box from "@mui/material/Box";
import {DividingHR} from "./DividingHR";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {toast} from "react-toastify";
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {TextValueField} from "./TextValueField";

export const ErrorDialogAPIStore = () => {
    const dispatch = useDispatch()
    const {apiNetworkError} = useSelector(getAPINetworkErrorSelector)

    function handleClose() {
        dispatch(cleaningAPINetworkError())
    }

    return apiNetworkError ? <ErrorDialogAPI apiError={apiNetworkError} onClose={handleClose}/> : null
}

export const ErrorDialogAPI = (props: { apiError: APIError, onClose(): void }) => {
    const {apiError, onClose} = props
    const CopyToClipboard = require("react-copy-to-clipboard")
    const [copy, setCopy] = useState(false)
    const dispatch = useDispatch()

    const handleLogout = useCallback(() => {
        /*dispatch<any>(userLogout())
        localStorage.removeItem('sodiwin-logout')*/
    }, [dispatch])

    function handleClose() {
        if (onClose) {
            onClose()
            handleLogout()
        }
    }

    const handleCopyContent = () => {
        setCopy(true)
        toast.success("Le contenu a été copié", {autoClose: 4000})
        setTimeout(() => {
            setCopy(false)
        }, 5000)
    }

    interface MailToInterface {
        email: string
        subject: string
        body: string
        body2: string
        body3: string
        body4: string
        body5: string
        body6: string
        children?: any
    }

    const Mailto = ({email, subject, body, body2, body3, body4, body5, body6, children}: MailToInterface) => {
        return (
            <Button variant="outlined" color="warning" sx={{display: "flex", alignItems: "center"}}
                    href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body) + "%0A" + encodeURIComponent(body2) + "%0A" + encodeURIComponent(body3) + "%0A" + encodeURIComponent(body4) + "%0A" + encodeURIComponent(body5) + "%0A" + encodeURIComponent(body6)}`}>
                <MailOutlineIcon/>SEND MAIL RAPPORT</Button>
        )
    }

    return <Dialog
        open={true}
        fullWidth={true}
        maxWidth='md'
    >
        <DialogTitle>
            <Grid container justifyContent='space-between'>
                <Box sx={{display: "flex", alignItems: "center"}}><InfoIcon sx={{marginRight: "10px"}}/>
                    L'application a rencontrée une erreur lors du traitement.
                </Box>
                <DividingHR/>
                <Box>Merci d'envoyer ce rapport par mail en cliquant sur le bouton SEND MAIL RAPPORT </Box>
                <Box>(le mail sera généré automatiquement).</Box>
                <Box>{apiError.type}</Box>
                {/*{apiError.gravity === 'DATA' ? <Warning color='secondary'/> : <ErrorOutline color='secondary'/>}*/}
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid container direction="column">
                <Grid item>
                    <TextValueField text={"Message"} value={apiError.message}/>
                </Grid>
                <Grid item>
                    <TextValueField text={"Path"} value={apiError.url}/>
                </Grid>
                <Grid item>
                    <TextValueField text={"Method"} value={apiError.method}/>
                </Grid>
                <Grid item>
                    <TextValueField text={"Error"} value={apiError.error}/>
                </Grid>
                <Grid item>
                    <TextValueField text={"Error message"} value={apiError.errorMessage}/>
                </Grid>
                <Grid item>
                    <TextValueField text={"Timestamp"} value={apiError.timestamp}/>
                </Grid>
            </Grid>
            {apiError.trace !== undefined ? <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Trace</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        fontSize: "5px",
                        whiteSpace: "normal",
                        overflow: "hidden"
                    }}>
                        {apiError.trace}
                    </Box>
                </AccordionDetails>
            </Accordion> : ""}
        </DialogContent>
        <DividingHR subContent/>
        <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
            <Box sx={{display: "flex"}}>
                {apiError.trace !== undefined ?
                    <Box sx={{margin: "2px"}}>
                        <CopyToClipboard text={apiError.trace} onCopy={handleCopyContent}>
                            {copy ?
                                <Button variant="outlined" color="success"><ContentPasteIcon/>Copied to
                                    clipboard</Button>
                                : <Button variant="outlined"><ContentCopyIcon/>Copy to clipboard</Button>
                            }
                        </CopyToClipboard>
                    </Box> : ""}
                <Box sx={{margin: "2px"}}>
                    <Mailto email="sodiwin@sodiwin.com" subject={"[Error Sodiwin App]" + " - " + apiError.message}
                            body={"PATH : " + apiError.url}
                            body2={"METHOD : " + apiError.method?.toUpperCase()}
                            body3={"ERROR : " + apiError.error}
                            body4={"ERROR MESSAGE : " + apiError.errorMessage}
                            body5={"TIME STAMP : " + apiError.timestamp}
                            body6={"TRACE : " + "collez la trace ici ..."}>
                    </Mailto>
                </Box>
            </Box>
            <Box sx={{margin: "2px"}}>
                <Button variant='outlined' color="error" onClick={handleClose}>Fermer</Button>
            </Box>
        </DialogActions>
    </Dialog>
}