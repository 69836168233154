import {CSSProperties} from "react";

interface DividingHRProps {
    style?: CSSProperties
    subContent?: boolean
}

export const DividingHR = (props: DividingHRProps) => {
    const {subContent} = props
    return (
        subContent ?
            <hr className="style-hr-grey" style={props.style}/>
            :
            <hr className="style-hr-blue" style={props.style}/>
    )
}