import React, {useEffect, useState} from "react";
import {Fab} from "@mui/material";
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import '../Style/Utils/_ScrollUpButton.scss';

export const ScrollUpButton = () => {

    const rootElement = document.documentElement
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        })
    }, [])

    //Fonction pour retourner au top de la page
    const scrollToTop = () => {
        rootElement.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        showButton ? <Fab className="scrollUpButton" variant="extended" onClick={scrollToTop} color="primary"><ArrowUp
            style={{fontSize: "24px"}}/></Fab> : <></>
    )
}
