import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {
    OrderLine,
    OrderLineFilter,
    OrderLinePricing,
    OrderLineWithOrderTotals,
    OrderTotals
} from "../../../corelogic/models/order";
import {OrderLineGateway} from "../../../corelogic/gateways/OrderLineGateway";
import {buildUrl} from "../../../corelogic/utils/tools";
import {
    mapOrderLineWithOrderTotalsApiResponseToOrderLineWithOrderTotals,
    OrderLineWithOrderTotalsApiResponse
} from "./Model/orderLineApi";

export class ApiOrderLineGateway implements OrderLineGateway {

    async retrieveOrderLinesByOrderId(orderId: number): Promise<OrderLine[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.orderLine, orderId.toString())})
    }

    async addOrderLine(orderId: number, idItem: number, quantity: number, unit: string): Promise<OrderLineWithOrderTotals> {
        const data = await apiFetchApiResponse<OrderLineWithOrderTotalsApiResponse>({
            url: buildUrl(API_PREFIX.orderLine, orderId.toString()),
            method: "POST",
            data: {itemId: idItem, quantity: quantity, unit: unit}
        });
        return mapOrderLineWithOrderTotalsApiResponseToOrderLineWithOrderTotals(data);
    }

    async removeOrderLine(orderLineId: number): Promise<OrderTotals> {
        return apiFetchApiResponse<OrderTotals>({
            url: buildUrl(API_PREFIX.orderLine, orderLineId.toString()),
            method: "DELETE"
        })
    }

    async updateOrderLineQuantity(orderLineId: number, newQuantity: number): Promise<OrderLineWithOrderTotals> {
        return apiFetchApiResponse<OrderLineWithOrderTotalsApiResponse>({
            url: buildUrl(API_PREFIX.order, "line-qty", orderLineId.toString()),
            method: "PATCH",
            data: {newQuantity: newQuantity}
        })
            .then((data) => mapOrderLineWithOrderTotalsApiResponseToOrderLineWithOrderTotals(data))

    }

    async updateOrderLinePricing(orderLineId: number, orderLinePricing: OrderLinePricing): Promise<OrderLineWithOrderTotals> {
        return apiFetchApiResponse<OrderLineWithOrderTotalsApiResponse>({
            url: buildUrl(API_PREFIX.orderLine, "pricing", orderLineId.toString()),
            method: "PATCH",
            data: {...orderLinePricing}
        })
            .then((data) => mapOrderLineWithOrderTotalsApiResponseToOrderLineWithOrderTotals(data))
    }

    async updateOrderLineItemLabel(orderLineId: number, label: string): Promise<OrderLine> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.orderLine, "label", orderLineId.toString()),
            method: "PATCH",
            params: {label: label}
        })
    }

    async retrieveAllLinesByOrderIdFiltered(orderId: number, filterValue: OrderLineFilter): Promise<OrderLine[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.orderLine, orderId.toString(), "filter"),
            params: {...filterValue}
        })
    }
}