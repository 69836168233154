import {HashRouter,} from "react-router-dom";
import './App.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Main} from "./adapters/primary/Components/Main";
import messages_fr from "./i18n/fr-FR.json";
import messages_en from "./i18n/en.json";
import {IntlProvider} from "react-intl";
import {ToastContainer} from "react-toastify";
import {enUS, frFR} from "@mui/material/locale";

export const App = () => {
    const messages = {
        'fr': messages_fr,
        'en': messages_en
    }

    const localsMui = {
        'fr': frFR,
        'en': enUS
    }

    const lang = navigator.language.split(/[-_]/)[0];  // language without region code
    // const lang = 'en'

    const [darkThemeForApp, setDarkthemeForApp] = useState(false)

    const theme = createTheme(
        {
            palette: {
                mode: "light",
                primary: {
                    main: "rgba(33, 150, 243, 1)",
                    dark: "rgb(31,141,229)",
                    light: "rgb(35,157,253)"
                },
                secondary: {
                    main: "rgba(255, 82, 82, 1)",
                    dark: "rgb(232,74,74)",
                    light: "rgb(253,92,92)"
                },
                background: {
                    main: "hsl(0, 0%, 87%)",
                    default: "hsl(0,0%,95%)"
                },
                common: {
                    main: "hsl(0, 0%, 96%)",
                    black: "hsl(0,0%,100%)"
                },
                text: {
                    primary: "#232323",
                    secondary: "#444444"
                }
            }
        },
        localsMui[lang]
    )

    const darkTheme = createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: "rgba(33, 150, 243, 1)",
                    dark: "rgb(31,141,229)",
                    light: "rgb(35,157,253)"
                },
                secondary: {
                    main: "rgba(255, 82, 82, 1)",
                    dark: "rgb(232,74,74)",
                    light: "rgb(253,92,92)"
                },
                background: {
                    main: "hsl(210, 0%, 10%)",
                    default: "hsl(210,5%,16%)"
                },
                common: {
                    main: "hsl(210, 0%, 13%)",
                    black: "hsl(0,0%,12%)"
                },
                text: {
                    primary: "#fff",
                    secondary: "#d9d9d9",
                    main: "hsl(0,0%,88%)"
                }
            },
        },
        localsMui[lang]
    )

    useEffect(() => {
        const themeSodiwinAppValue = localStorage.getItem("themeSodiwinApp")

        if (themeSodiwinAppValue === "light") {
            setDarkthemeForApp(false)
        } else {
            setDarkthemeForApp(true)
        }
    }, [])

    return (
        <ThemeProvider theme={darkThemeForApp ? darkTheme : theme}>
            <IntlProvider locale={lang} defaultLocale={'en'} messages={messages[lang]}>
                <React.StrictMode>
                    <HashRouter>
                        <CssBaseline/>
                        <ToastContainer/>
                        <Main/>
                    </HashRouter>
                </React.StrictMode>
            </IntlProvider>
        </ThemeProvider>
    )
}
