import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogProps, DialogTitle, Grid, Paper, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {UserStatusV2} from "../../../../../secondary/InMemory/Data/enums";
import {getUserSelectedSelector} from "../../../../../../store/selectors/usersSelectors";
import GetUserStatusButton from "../../../Utils/GetUserStatusButton";
import GetUserRoleButton from "../../../Utils/GetUserRoleButton";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import {User, UserPasswordForm} from "../../../../../../corelogic/models/user";
import PasswordIcon from "@mui/icons-material/Password";
import {UserPasswordFormComponent} from "./UserPasswordFormComponent";

interface UserPageHeaderInterface {
    data: User
    onPasswordSubmit: (passwordForm: UserPasswordForm) => void
    onPasswordSubmitAdmin: (password: string) => void
}

const UserPageHeader = (props: UserPageHeaderInterface) => {
    const {data, onPasswordSubmit, onPasswordSubmitAdmin} = props
    const {userSelected} = useSelector(getUserSelectedSelector)
    const intl = useIntl()
    const [fullWidth, setFullWidth] = React.useState(true)
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md')
    const [openPasswordFormDialog, setOpenPasswordFormDialog] = useState(false)

    const handleChangePassword = () => {
        setOpenPasswordFormDialog(true)
    }

    const handleCancelChangePassword = () => {
        setOpenPasswordFormDialog(false)
    }

    const handlePasswordFormSubmit = (passwordForm: UserPasswordForm) => {
        onPasswordSubmit(passwordForm)
        setOpenPasswordFormDialog(false)
    }

    const handlePasswordFormSubmitAdmin = (password: string) => {
        onPasswordSubmitAdmin(password)
        setOpenPasswordFormDialog(false)
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <TextValueField style={{fontWeight: "bolder"}}
                                            text={intl.formatMessage(getMessageDescriptor("userPageHeaderTitle"))}
                                            value={userSelected?.firstName && userSelected?.lastName ? userSelected?.firstName.toUpperCase() + " " + userSelected?.lastName.toUpperCase() : ""}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <GetUserStatusButton userStatus={userSelected?.status?.value as UserStatusV2}/>
                        </Grid>
                        <Grid item>
                            <GetUserRoleButton userRoleCode={userSelected?.role?.code}/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleChangePassword}>
                                <PasswordIcon sx={{mr: 1}}/>
                                {intl.formatMessage(getMessageDescriptor("userPageFormPasswordTitle"))}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openPasswordFormDialog &&
                <Dialog open fullWidth={fullWidth} maxWidth={maxWidth}>
                    <DialogTitle>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <PasswordIcon sx={{fill: "rgba(33, 150, 243, 1)", marginRight: "10px"}}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <FormattedMessage id={messages.userPageFormPasswordTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Paper>
                            <UserPasswordFormComponent data={data} onSubmit={handlePasswordFormSubmit}
                                                       onSubmitAdmin={handlePasswordFormSubmitAdmin}
                                                       onReset={handleCancelChangePassword}/>
                        </Paper>
                    </DialogContent>
                </Dialog>}
        </>
    )
}

export default UserPageHeader