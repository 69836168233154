import {AppState} from "store/appState";

export const getSocietySelector = (state: AppState) => {
    return {
        data: state.societies.data,
        fetching: state.societies.fetching
    }
}

export const getSocietySelectedSelector = (state: AppState) => {
    return {
        societySelected: state.societies.selected,
        fetchingSelected: state.societies.fetching,
    }
}

export const getSocietyFormSelector = (state: AppState) => {
    return {
        dataFormFields: state.societies.dataFormFields
    }
}