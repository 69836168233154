import jwtDecode from "jwt-decode"

export function buildUrl(...parts: string[]) {
    let finalUrl = ""
    parts.forEach((p, idx) => {
        if (idx > 0 && idx < parts.length)
            finalUrl += "/"
        finalUrl += p
    })
    return finalUrl
}

export function getExpirationDateFromAuthToken(token: string, defaultDate: Date): Date {
    let tokenAuthExpirationDate = defaultDate
    try {
        const tokenDecoded = jwtDecode<any>(token)
        if (tokenDecoded.exp)
            tokenAuthExpirationDate = new Date(tokenDecoded.exp * 1000)
    } catch (error) {
        console.info("ERROR DECODING TOKEN: ", token)
    }
    return tokenAuthExpirationDate
}