import {APIError} from "corelogic/interfaces";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";

export const catchingAPINetworkError = (apiError: APIError) => {
    return {type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: apiError}
}

export const cleaningAPINetworkError = () => {
    return {type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CLEAN}
}

export const catchingAPIError = (apiError: APIError) => {
    return {type: APP_ACTIONS_TYPES.generics.API_ERROR_CATCH, payload: apiError}
}

export const cleaningAPIError = () => {
    return {type: APP_ACTIONS_TYPES.generics.API_ERROR_CLEAN}
}
