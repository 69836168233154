import {AnyAction, combineReducers} from "redux"
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes"
import {OrderStats} from "../../corelogic/models/stats";
import {Representative} from "../../corelogic/models/parameter";


const order = (state: OrderStats | null = null, action: AnyAction): OrderStats | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.stats.STATS_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.stats.STATS_RESET_DATA:
            return null
        default:
            return state;
    }
}
const representative = (state: Representative[]  = [], action: AnyAction): Representative[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.stats.STATS_REPR_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.stats.STATS_REPR_RESET_DATA:
            return []
        default:
            return state;
    }
}


const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("STATS_")) {
        return action.type === APP_ACTIONS_TYPES.stats.STATS_RETRIEVING
    }
    return state
}

const fetchingRepresentative = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("STATS_")) {
        return action.type === APP_ACTIONS_TYPES.stats.STATS_REPR_RETRIEVING
    }
    return state
}

export default combineReducers({
    order,
    fetching,
    representative,
    fetchingRepresentative
})