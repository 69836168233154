import * as React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Grid, Tooltip} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const columnsCustomerReferencingItemListDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID'
    },
    {
        field: 'itemPicture',
        headerAlign: "center",
        sortable: false,
        filterable: false,
        width: 100,
        renderHeader: () => {
            return (
                <ImageIcon/>
            )
        },
        renderCell: (cellValues) => {
            return (
                <Grid container justifyContent="center">
                    <img alt={cellValues.row.item?.label || ""}
                         src={`${process.env.REACT_APP_API_ENDPOINT}img/items/${cellValues.row.item?.code.replace("/","_")}.jpg`}                         onError={({currentTarget}) => {
                             currentTarget.onerror = null
                             currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                         }}
                         style={{
                             height: "60px",
                             width: "auto",
                             backgroundSize: "cover",
                             objectFit: "cover"
                         }}/>
                </Grid>
            )
        }
    },
    {
        field: 'itemCode',
        headerName: 'orderVisualisationPageGridOrderLinesItemCode',
        headerAlign: "left",
        align: "left",
        type: "string",
        width: 150,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.code || ''}>
                        <span>
                            {cellValues.row.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.code
    },
    {
        field: 'itemLabel',
        headerName: 'orderVisualisationPageGridOrderLinesItemLabel',
        headerAlign: "left",
        align: "left",
        type: "string",
        width: 300,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.label || ''}>
                        <span>
                            {cellValues.row.label || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.label
    },
    {
        field: 'superfamily',
        headerName: 'orderVisualisationPageGridOrderLinesItemSuperFamily',
        headerAlign: "left",
        align: "left",
        type: "string",
        width: 250,
        renderCell: (cellValues) => {
            const labelWithCode = `${cellValues.row.superFamily?.label || ''} (${cellValues.row.superFamily?.code || ''})`
            return (
                <Tooltip title={labelWithCode}>
                        <span>
                            {labelWithCode}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.superFamily?.code
    },
    {
        field: 'family',
        headerName: 'orderVisualisationPageGridOrderLinesItemFamily',
        headerAlign: "left",
        align: "left",
        type: "string",
        width: 250,
        renderCell: (cellValues) => {
            const labelWithCode = `${cellValues.row.family?.label || ''} (${cellValues.row.family?.code || ''})`
            return (
                <Tooltip title={labelWithCode}>
                        <span>
                            {labelWithCode}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.family?.code
    },
    {
        field: 'brand',
        headerName: 'orderVisualisationPageGridOrderLinesItemBrand',
        headerAlign: "left",
        align: "left",
        type: "string",
        width: 250,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.brand?.label || ''}>
                        <span>
                            {cellValues.row.brand?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.brand?.code
    },
    {
        field: 'isBlocked',
        headerName: 'orderVisualisationPageGridOrderLinesItemIsBlocked',
        headerAlign: "center",
        align: "center",
        type: "boolean",
        width: 125,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <>
                    {!!cellValues.row.isBlocked ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                </>
            )
        }
    },
    {
        field: 'authorizationToSellByPackage',
        headerName: 'orderVisualisationPageGridOrderLinesItemAuthorizationToSellByPackage',
        headerAlign: "center",
        align: "center",
        type: "boolean",
        width: 125,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <>
                    {!!cellValues.row.authorizationToSellByPackage ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                </>
            )
        }
    },
    {
        field: 'authorizationToSellByConsumerUnit',
        headerName: 'orderVisualisationPageGridOrderLinesItemAuthorizationToSellByUnit',
        headerAlign: "center",
        align: "center",
        type: "boolean",
        width: 125,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <>
                    {!!cellValues.row.authorizationToSellByConsumerUnit ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                </>
            )
        }
    }
]