import {UserItemFavoritesGateway} from "../../../corelogic/gateways/UserItemFavoritesGateway";
import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {buildUrl} from "../../../corelogic/utils/tools";
import {API_PREFIX} from "../../../corelogic/config";

export class ApiUserItemFavoritesGateway implements UserItemFavoritesGateway {
    add(itemId: number): Promise<boolean> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.itemFavorites, itemId.toString()), method: "POST"})
    }

    remove(itemId: number): Promise<boolean> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.itemFavorites, itemId.toString()), method: "DELETE"})
    }
}