import React from 'react';
import {Grid} from "@mui/material";
import ReturnButton from "../../Utils/ReturnButton";
import {DividingHR} from "../../Utils/DividingHR";
import {TextValueField} from "../../Utils/TextValueField";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import {useIntl} from "react-intl";
import {Society} from "../../../../../corelogic/models/society";

interface SocietyHeaderInterface {
    societySelected?: Society
}

const SocietyHeader = (props: SocietyHeaderInterface) => {
    const {societySelected} = props
    const intl = useIntl()

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <TextValueField style={{fontWeight: "bolder"}}
                                            text={intl.formatMessage(getMessageDescriptor("societyHeaderTitle"))}
                                            value={societySelected?.label || ""}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SocietyHeader;