import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {StatsGateway} from "../../../corelogic/gateways/StatsGateway";
import {OrderStats} from "../../../corelogic/models/stats";
import {buildUrl} from "../../../corelogic/utils/tools";
import qs from "qs";

export class ApiStatsGateway implements StatsGateway {

    retrieveOrderStats(startDate: Date, endDate: Date, reprId?: number): Promise<OrderStats> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.stats, "orders"),
            params: {startDate: startDate.getTime(), endDate: endDate.getTime(), reprId: reprId},
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }
}