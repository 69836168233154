import React from 'react';
import Typography from "@mui/material/Typography";
import {CircularProgress, Grid} from "@mui/material";
import {FormattedNumber} from "react-intl";

interface HomeTextInfoProps {
    title?: string
    primaryText?: string
    secondaryText?: string
    fetching?: boolean
}

export const HomeTextInfo = (props: HomeTextInfoProps) => {
    const {title, primaryText, secondaryText, fetching} = props
    return (
        <>
            {fetching ? <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress sx={{
                        margin: "auto",
                        marginBottom: "5px",
                        height: "15px",
                        textAlign: "center"
                    }}/></Grid>
                : <Typography variant="h4" color="secondary" textAlign="center"
                              fontWeight="bolder">
                    {title}
                </Typography>}
            <Typography fontWeight="bolder" textAlign="center">
                {primaryText}
            </Typography>
            <Typography fontWeight="lighter" textAlign="center">
                {secondaryText}
            </Typography>
        </>
    );
}

interface HomeAmountInfoProps {
    title?: number
    primaryText?: string
    secondaryText?: string
    fetching?: boolean
    styleAmount: "currency" | "unit" | "decimal" | "percent"
    currency?: "EUR"
    nbDigits?: number
}

export const HomeAmountInfo = (props: HomeAmountInfoProps) => {
    const {title, primaryText, secondaryText, fetching, styleAmount, currency, nbDigits} = props
    return (
        <>
            {fetching ? <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress sx={{
                        margin: "auto",
                        marginBottom: "5px",
                        height: "15px",
                        textAlign: "center"
                    }}/></Grid>
                : <Typography variant="h4" color="secondary" textAlign="center"
                              fontWeight="bolder">
                    {title && <FormattedNumber
                        value={title}
                        maximumFractionDigits={nbDigits}
                        style={styleAmount} currency={currency}/>}
                </Typography>}
            <Typography fontWeight="bolder" textAlign="center">
                {primaryText}
            </Typography>
            <Typography fontWeight="lighter" textAlign="center">
                {secondaryText}
            </Typography>
        </>
    );
}