import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../i18n/messages";
import {DividingHR} from "./DividingHR";
import {TextValueFieldBillingBox} from "./TextValueField";
import {useSelector} from "react-redux";
import {getCustomerSpecificData} from "../../../../store/selectors/serverSelector";
import {CustomerSpecificCode, CustomerSpecificData} from "../../../../corelogic/models/CustomerSpecificData";
import {Customer} from "../../../../corelogic/models/customer";

interface OrderTotalProps {
    totalGrossTaxExcluded?: number
    totalShippingCost?: number
    discount?: number
    totalTaxExcluded?: number
    totalVat?: number
    totalWithTax?: number
    vatExcluded?: boolean
    customer?: Customer | null
}

const OrderTotal = (props: OrderTotalProps) => {
    const {
        totalGrossTaxExcluded,
        totalShippingCost,
        discount,
        totalTaxExcluded,
        totalVat,
        totalWithTax,
        vatExcluded,
        customer
    } = props
    const intl = useIntl()
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    return (
        <Grid container direction="column" py={1} px={5}>
            <Grid item>
                <Typography fontWeight="bolder" textAlign="center">
                    <FormattedMessage id={messages.orderTotalTitle.id}/>
                </Typography>
                {customerSpecificData?.customerSpecificCode === CustomerSpecificCode.XAVIER &&
                    <XavierSpeComponent customerSpecificData={customerSpecificData}
                                        customerCategoryCode={customer?.category?.code}/>}
            </Grid>
            <DividingHR/>
            <Grid container item>
                <Grid item lg={5} sm={5}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalGrossExcludingTaxes"))}
                                value={totalGrossTaxExcluded || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalShippingCosts"))}
                                value={totalShippingCost || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalDiscount"))}
                                value={discount || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container lg={2} sm={2} alignItems="center" justifyContent="center">
                    <DividingHR
                        subContent
                        style={{height: "60px", width: 1, margin: 0}}/>
                </Grid>
                <Grid item lg={5} sm={5}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalExcludingTaxes"))}
                                value={totalTaxExcluded || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={`${intl.formatMessage(getMessageDescriptor("orderTotalVat"))} ${vatExcluded ? "(Exonéré)" : ""}`}
                                value={totalVat || 0}/>
                        </Grid>
                        <Grid item>
                            <TextValueFieldBillingBox
                                style={{
                                    marginBottom: "0px",
                                    fontWeight: "bolder",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("orderTotalIncludingTaxes"))}
                                value={totalWithTax || 0}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface XavierSpeComponentProps {
    customerSpecificData: CustomerSpecificData
    customerCategoryCode?: string
}

const XavierSpeComponent = (props: XavierSpeComponentProps) => {
    const {customerSpecificData, customerCategoryCode} = props
    const excludedCategories = customerSpecificData.top4 ? customerSpecificData.top4.split(',').map(c => c.trim()) : []
    const intl = useIntl()
    return (customerCategoryCode && !excludedCategories.includes(customerCategoryCode)) ? <>
            {!Number.isNaN(Number(customerSpecificData?.top1)) && <Typography variant="body2" gutterBottom>
                <FormattedMessage id={messages.specificXavierTop1.id}
                                  values={{
                                      top1: intl.formatNumber(Number(customerSpecificData?.top1), {
                                          style: 'percent',
                                          maximumFractionDigits: 2
                                      })
                                  }}
                />
            </Typography>}
            {(!Number.isNaN(Number(customerSpecificData?.top2)) && !Number.isNaN(Number(customerSpecificData?.top3))) &&
                <Typography variant="body2" gutterBottom>
                    <FormattedMessage id={messages.specificXavierTop2AndTop3.id}
                                      values={{
                                          top1: intl.formatNumber(Number(customerSpecificData?.top1), {
                                              style: 'percent',
                                              maximumFractionDigits: 2
                                          }),
                                          top2: intl.formatNumber(Number(customerSpecificData?.top2), {
                                              style: 'percent',
                                              maximumFractionDigits: 2
                                          }),
                                          top3: intl.formatNumber(Number(customerSpecificData?.top3), {
                                              style: 'decimal',
                                              maximumFractionDigits: 0
                                          })
                                      }}
                    />
                </Typography>}
        </>
        : <></>
}
export default OrderTotal