import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Item} from "../../corelogic/models/item";
import {CustomerSuperFamiliesAndFamilies} from "../../corelogic/models/customer";

const data = (state: Item[] = [], action: AnyAction): Item[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RESET_DATA:
            return []
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_ADD_REFERENCE:
            return state.map(i => i.id === action.payload ? {...i, referenced: true} : i)
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_REMOVE_REFERENCE:
            return state.map(i => i.id === action.payload ? {...i, referenced: false} : i)
        default:
            return state
    }
}

const dataSuperFamiliesAndFamiliesReferenced = (state: CustomerSuperFamiliesAndFamilies[] = [], action: AnyAction): CustomerSuperFamiliesAndFamilies[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RESET_DATA_SF_AND_F:
            return []
        default:
            return state;
    }
}
const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("CUSTOMER_ITEM_REF")) {
        return action.type === APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RETRIEVING
    }
    return state
}
const fetchingSuperFamiliesAndFamilies = (state: boolean = false, action: AnyAction) => {
    return action.type === APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVING
}

export default combineReducers({
    data,
    fetching,
    dataSuperFamiliesAndFamiliesReferenced,
    fetchingSuperFamiliesAndFamilies
})