import {Address} from "../../../../../../corelogic/models/customer";
import {FormattedMessage, useIntl} from "react-intl";
import React, {MouseEvent, useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DividingHR} from "../../../Utils/DividingHR";
import {AddressDisplay1Line, AddressDisplayBlock, AddressForm} from "../../../Utils/Address";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {retrieveCustomerAddresses} from "../../../../../../corelogic/usecase/customer/customersActions";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerSelectedDeliveryAddresses} from "../../../../../../store/selectors/customersSelector";

interface CustomerAddressTabInterface {
    dataCustomerId: number
    onDelete: (id: number) => void
    onUpdate: (address: Address) => void
    onAdd: (address: Address) => void
}

export const CustomerAddressTab = (props: CustomerAddressTabInterface) => {
    const {dataCustomerId, onDelete, onAdd, onUpdate} = props
    const {customerSelectedAddresses, fetchingAddressesSelected} = useSelector(getCustomerSelectedDeliveryAddresses)
    const dispatch = useDispatch()
    const intl = useIntl()
    const [idForDeletion, setIdForDeletion] = useState<null | number>(null)
    const [formData, setFormData] = useState<Address>({
        title: "",
        businessName: "",
        address1: "",
        address2: "",
        address3: "",
        zipCode1: "",
        zipCode2: "",
        city: ""
    })

    useEffect(() => {
        dispatch(retrieveCustomerAddresses(dataCustomerId))
    }, [dataCustomerId, dispatch])

    const handleCancel = () => {
        setFormData({
            title: "",
            businessName: "",
            address1: "",
            address2: "",
            address3: "",
            zipCode1: "",
            zipCode2: "",
            city: ""
        })
    }

    const handleUpdateDeliveryAddress = (address: Address) => {
        setFormData(address)
    }

    const handleDeleteDeliveryAddress = () => {
        if (idForDeletion) {
            onDelete(idForDeletion)
        }
        setIdForDeletion(null)
    }

    const handleDuplicateDeliveryAddress = (address: Address) => {
        setFormData({...address, id: undefined, title: ""})
    }

    const handleDeleteAction = (addressId: number | null) => {
        if (addressId !== null) {
            setIdForDeletion(addressId)
        }
    }

    const handleSubmit = (address: Address) => {
        address.id ? onUpdate(address) : onAdd(address)
        setFormData({
            title: "",
            businessName: "",
            address1: "",
            address2: "",
            address3: "",
            zipCode1: "",
            zipCode2: "",
            city: ""
        })
    }

    const handleCancelDeleteAction = () => {
        setIdForDeletion(null)
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={6} sm={12}>
                    <Paper elevation={2}>
                        <Grid container justifyContent="center" alignItems="center" p={1}>
                            {formData?.id ?
                                <EditIcon style={{fill: "rgba(33, 150, 243, 1)"}}
                                          className="img-title-card"/>
                                :
                                <AddCircleIcon style={{fill: "rgba(33, 150, 243, 1)"}}
                                               className="img-title-card"/>
                            }
                            <Typography variant="h5" component="h2">
                                <FormattedMessage
                                    id={formData?.id ? messages.userPageFormDeliveryAddressUpdateTitle.id : messages.orderCreationPageHeaderDeliveryAddressCreation.id}/>
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Grid container>
                            <Grid item container p={3}>
                                <Paper sx={{width: "100%"}}>
                                    <AddressForm data={formData}
                                                 onConfirmClick={handleSubmit}
                                                 onCancelClick={handleCancel}/>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={6} sm={12}>
                    <Paper elevation={2}>
                        <Grid container justifyContent="center" alignItems="center" padding={1}>
                            <LocalShippingIcon style={{fill: "rgba(33, 150, 243, 1)"}}
                                               className="img-title-card"/>
                            <Typography variant="h5" component="h2">
                                {intl.formatMessage(getMessageDescriptor("customerAdressesTabComponentTitle"))}
                            </Typography>
                        </Grid>
                        <DividingHR style={{marginTop: 0}}/>
                        <Grid container>
                            <Grid item container>
                                <Grid item xs={12} p={3}>
                                    {fetchingAddressesSelected ?
                                        <Grid container justifyContent="center" alignItems="center">
                                            <CircularProgress sx={{
                                                margin: "auto",
                                                marginBottom: "5px",
                                                height: "15px",
                                                textAlign: "center"
                                            }}/>
                                        </Grid>
                                        : customerSelectedAddresses && customerSelectedAddresses.length >= 1 ?
                                            customerSelectedAddresses.map(da => <AddressAccordion key={da.id}
                                                                                                  address={da}
                                                                                                  onUpdateClick={handleUpdateDeliveryAddress}
                                                                                                  onDeleteClick={() => handleDeleteAction(da.id ? da.id : null)}
                                                                                                  onDuplicateClick={handleDuplicateDeliveryAddress}/>)
                                            :
                                            <Alert severity="info">
                                                {intl.formatMessage(getMessageDescriptor("customerAdressesTabComponentNoAddressFound"))}
                                            </Alert>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                maxWidth="lg"
                open={idForDeletion !== null}
            >
                <DialogTitle>
                    <FormattedMessage id={messages.userPageFormDeliveryAddressDeleteTitle.id}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("userPageFormDeliveryAddressDeleteConfirm"))}</Alert>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <Alert
                            severity="info">{intl.formatMessage(getMessageDescriptor("userPageFormDeliveryAddressDeleteCancel"))}</Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCancelDeleteAction}><FormattedMessage
                        id={messages.genericCancel.id}/></Button>
                    <Button variant="outlined" onClick={handleDeleteDeliveryAddress}><FormattedMessage
                        id={messages.genericConfirm.id}/></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

interface AddressAccordionInterface {
    address: Address,
    onUpdateClick: (address: Address) => void,
    onDuplicateClick: (address: Address) => void,
    onDeleteClick: (addressId: number) => void
}

const AddressAccordion = (props: AddressAccordionInterface) => {
    const {address, onUpdateClick, onDuplicateClick, onDeleteClick} = props

    const handleUpdate = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onUpdateClick(address)
    }
    const handleDelete = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (address.id)
            onDeleteClick(address.id)
    }
    const handleDuplicate = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onDuplicateClick(address)
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
            >
                <Grid container alignItems="center">
                    <Grid item sm={9}>
                        <AddressDisplay1Line onlyDisplayTitleAndBusinessName withLocationIcon title={address.title}
                                             businessName={address.businessName}/>
                    </Grid>
                    <Grid item sm={3} pr={2}>
                        <Paper elevation={3}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <Button onClick={handleUpdate}><EditIcon/></Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button onClick={handleDuplicate}><FileCopyIcon/></Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button onClick={handleDelete}><DeleteIcon
                                        sx={{fill: "rgba(255, 82, 82, 1)"}}/></Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <DividingHR/>
            <AccordionDetails>
                <Box sx={{padding: "5px"}}>
                    <AddressDisplayBlock title={address.businessName}
                                         address1={address.address1}
                                         address2={address.address2}
                                         address3={address.address3}
                                         city={address.city}
                                         zipCode1={address.zipCode1}
                                         zipCode2={address.zipCode2}
                                         country={address.country?.label}
                                         hasFocus={true}/>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}