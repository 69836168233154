import * as React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {GridPreProcessEditCellProps, GridRenderEditCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import {Paper, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {FormattedNumber} from "react-intl";
import InventoryIcon from '@mui/icons-material/Inventory';
import {
    CustomEditComponent,
    CustomEditComponentCurrency,
    CustomEditComponentPercent,
    CustomNumericEditComponent
} from "../Utils/EditCellsComponent";
import {UserRoleCode} from "../../../../corelogic/models/user";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const getOrderLineId = (params: GridValueGetterParams) => {
    return `${params.row.id}`
}

const gridPreProcessValidityForPercentField = (params: GridPreProcessEditCellProps) => {
    const isValid = (isNaN(params.props.value)) || (!isNaN(Number(params.props.value)) && params.props.value >= 0 && params.props.value <= 100)
    if (isValid)
        return {...params.props, error: false}
    return {...params.props, error: !isValid, errorTextId: "genericIncorrectValue"}
}

export const getColumnsOrderBillingDetailsLinesDescription = (userRole: string = "", grossPriceEditable: boolean = false, discountsEditable: boolean = false, isFreeEditable: boolean = false, labelEditable: boolean = false): GridColDef[] => {
    return [
        {
            field: 'id',
            valueGetter: getOrderLineId
        },
        {
            field: 'label',
            headerName: "orderLineGridColItemCodeLabel",
            description: "orderLineGridColItemCodeLabelDesc",
            headerAlign: "center",
            flex: 1.5,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            editable: userRole !== UserRoleCode.CUSTOMER && labelEditable,
            preProcessEditCellProps: (params) => {
                const isValid = (params.props.value !== undefined && params.props.value !== null)
                if (isValid)
                    return {...params.props, error: false}
                return {...params.props, error: !isValid, errorTextId: "genericIncorrectValue"}
            },
            renderCell: (cellValues) => {
                return ((userRole !== UserRoleCode.CUSTOMER && labelEditable) ? <Paper elevation={5} sx={[
                            {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(33, 150, 243, 1)",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            },
                        ]}>
                            <Tooltip title={`${cellValues.row.item.code || ''} - ${cellValues.row.label || ''}`} sx={{width:"100%",padding:1}}>
                                <Box>
                                    <Typography fontSize="small"  textOverflow="ellipsis" noWrap>
                                        {cellValues.row.item.code || ''} - {cellValues.row.label || ''}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Paper>
                        : <Tooltip title={`${cellValues.row.item.code || ''} - ${cellValues.row.label || ''}`} sx={{width:"100%",padding:1}}>
                            <Box>
                                <Typography fontSize="small" textOverflow="ellipsis" noWrap>
                                    {cellValues.row.item.code || ''} - {cellValues.row.label || ''}
                                </Typography>
                            </Box>
                        </Tooltip>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CustomEditComponent {...params}/>
            }
        },
        {
            field: 'quantity',
            headerName: 'orderLineGridColItemQuantity',
            headerAlign: "center",
            description: 'orderLineGridColItemQuantityDesc',
            type: 'number',
            flex: 1,
            sortable: false,
            editable: true,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            preProcessEditCellProps: (params) => {
                const isValid = (params.props.value !== undefined && params.props.value !== null) && !isNaN(Number(params.props.value)) && params.props.value >= 0 && params.props.value <= 999999
                if (isValid)
                    return {...params.props, error: false}
                return {...params.props, error: !isValid, errorTextId: "genericIncorrectValue"}
            },
            renderCell: (cellValues) => {
                return (
                    <Paper elevation={5} sx={[
                        {
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "rgba(33, 150, 243, 1)",
                            "&:hover": {
                                cursor: "pointer"
                            }
                        },
                    ]}>
                        <FormattedNumber value={cellValues.value}/>
                    </Paper>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CustomNumericEditComponent {...params} suffix={""} decimalScale={2}/>
            }
        },
        {
            field: 'packaging',
            headerName: 'orderLineGridColPackageOrdered',
            headerAlign: "center",
            align: "center",
            type: "string",
            description: 'orderLineGridColPackageDesc',
            flex: 0.3,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Tooltip title="Conditionnement">
                        <InventoryIcon fontSize="small"/>
                    </Tooltip>
                )
            },
            renderCell: (cellValues) => {
                return (
                    <Tooltip title={cellValues.row.unit}>
                        <span>
                            {cellValues.row.unit || ""}
                        </span>
                    </Tooltip>
                )
            }
        },
        {
            field: 'grossPrice',
            headerName: 'orderLineGridColUnitPriceExclTax',
            headerAlign: "center",
            align: "center",
            type: "number",
            description: 'orderLineGridColUnitPriceExclTaxDesc',
            flex: 1,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            editable: userRole !== UserRoleCode.CUSTOMER && grossPriceEditable,
            preProcessEditCellProps: (params) => {
                const isValid = params.props.value === undefined || !isNaN(Number(params.props.value))
                if (isValid)
                    return {...params.props, error: false}
                return {...params.props, error: !isValid, errorTextId: "genericIncorrectValue"}
            },
            renderCell: (cellValues) => {
                return (
                    (userRole !== UserRoleCode.CUSTOMER && grossPriceEditable) ? <Paper elevation={5} sx={[
                            {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(33, 150, 243, 1)",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            },
                        ]}>
                            <FormattedNumber
                                value={cellValues.row.grossPrice || 0}
                                maximumFractionDigits={2}
                                style="currency" currency="EUR"/>
                        </Paper>
                        : <FormattedNumber
                            value={cellValues.row.grossPrice || 0}
                            maximumFractionDigits={2}
                            style="currency" currency="EUR"/>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CustomEditComponentCurrency {...params} suffix="€"/>
            }
        },
        {
            field: 'discount1',
            headerName: 'orderLineGridColDiscount',
            headerAlign: "center",
            align: "center",
            type: "number",
            description: 'orderLineGridColDiscountDesc',
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            editable: userRole !== UserRoleCode.CUSTOMER && discountsEditable,
            preProcessEditCellProps: gridPreProcessValidityForPercentField,
            renderCell: (cellValues) => {
                return (
                    (userRole !== UserRoleCode.CUSTOMER && discountsEditable) ? <Paper elevation={5} sx={[
                            {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(33, 150, 243, 1)",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            },
                        ]}>
                            <FormattedNumber value={cellValues.row.discount1 || 0} maximumFractionDigits={2}
                                             style="percent"/>
                        </Paper>
                        : <FormattedNumber value={cellValues.row.discount1 || 0} maximumFractionDigits={2}
                                           style="percent"/>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CustomEditComponentPercent {...params}/>
            }
        },
        {
            field: 'discount2',
            headerName: 'orderLineGridColDiscount2',
            headerAlign: "center",
            align: "center",
            type: "number",
            description: 'orderLineGridColDiscountDesc',
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            editable: userRole !== UserRoleCode.CUSTOMER && discountsEditable,
            preProcessEditCellProps: gridPreProcessValidityForPercentField,
            renderCell: (cellValues) => {
                return (
                    (userRole !== UserRoleCode.CUSTOMER && discountsEditable) ? <Paper elevation={5} sx={[
                            {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(33, 150, 243, 1)",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            },
                        ]}>
                            <FormattedNumber value={cellValues.row.discount2 || 0} maximumFractionDigits={2}
                                             style="percent"/>
                        </Paper>
                        : <FormattedNumber value={cellValues.row.discount2 || 0} maximumFractionDigits={2}
                                           style="percent"/>
                )
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CustomEditComponentPercent {...params}/>
            }
        },
        {
            field: 'free',
            headerName: 'orderLineGridColFree',
            headerAlign: "center",
            align: "center",
            type: "boolean",
            description: 'orderLineGridColFreeDesc',
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            editable: userRole !== UserRoleCode.CUSTOMER && isFreeEditable,
            renderCell: (cellValues) => {
                return (
                    (userRole !== UserRoleCode.CUSTOMER && isFreeEditable) ? <Paper elevation={5} sx={[
                            {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(33, 150, 243, 1)",
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            },
                        ]}>
                            {cellValues.row.free ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                        </Paper>
                        : <Box>
                            {cellValues.row.free ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                        </Box>
                )
            }
        },
        {
            field: 'priceTaxIncluded',
            headerName: 'orderLineGridColUnitPriceInclTax',
            headerAlign: "center",
            align: "center",
            type: "number",
            description: 'orderLineGridColUnitPriceExclTaxDesc',
            flex: 1,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                    <FormattedNumber
                        value={cellValues.row.netPrice || 0}
                        maximumFractionDigits={2}
                        style="currency" currency="EUR"/>
                )
            }
        },
        {
            field: 'itemTotalExTax',
            headerName: 'orderLineGridColTotalExclTax',
            description: `orderLineGridColTotalExclTaxDesc`,
            headerAlign: "center",
            align: "center",
            type: 'number',
            flex: 1.2,
            sortable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                    <FormattedNumber
                        value={(cellValues.row.netPrice) * (cellValues.row.quantity) || 0}
                        maximumFractionDigits={2}
                        style="currency" currency="EUR"/>
                )
            }
        }
    ]
}