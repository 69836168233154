import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {Address} from "../../../corelogic/models/customer";
import {CustomerAddressesGateway} from "../../../corelogic/gateways/CustomerAddressesGateway";
import {
    CustomerAddressApiResponse,
    mapCustomerAddressApiResponseToCustomerAddress,
    mapCustomerAddressToCustomerAddressApiRequest
} from "./Model/customerAddressesApi";

export class ApiCustomerAddressesGateway implements CustomerAddressesGateway {
    retrieveAllByCustomerId(customerId: number): Promise<Address[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customerAddress, customerId.toString())})
    }

    add(customerId: number, address: Address): Promise<Address> {
        return apiFetchApiResponse<CustomerAddressApiResponse>({
            url: buildUrl(API_PREFIX.customerAddress),
            method: "POST",
            data: {...mapCustomerAddressToCustomerAddressApiRequest(address), customerId: customerId}
        })
            .then(x => mapCustomerAddressApiResponseToCustomerAddress(x))
    }

    update(address: Address): Promise<Address> {
        return apiFetchApiResponse<CustomerAddressApiResponse>({
            url: buildUrl(API_PREFIX.customerAddress),
            method: "PUT",
            data: mapCustomerAddressToCustomerAddressApiRequest(address)
        })
            .then(x => mapCustomerAddressApiResponseToCustomerAddress(x))
    }

    delete(addressId: number): Promise<void> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.customerAddress, addressId.toString()), method: "DELETE"})
    }
}