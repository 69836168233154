import * as React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Box, Tooltip} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LinkMailTo from "../Utils/LinkMailTo";
import {FormattedNumber} from "react-intl";

export const columnsCustomersListDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID'
    },
    {
        field: 'code',
        headerName: 'customerGridColCode',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.code || ''}>
                        <span style={{fontWeight: "bolder"}}>
                            {cellValues.row.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.code
    },
    {
        field: 'businessName',
        headerName: 'customerGridColBusinessName',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 250,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.businessName ? cellValues.row.businessName : ''}
                            ${cellValues.row.businessForm ? ' - ' + cellValues.row.businessForm.label : ''}`}>
                        <span>
                            {`${cellValues.row.businessName ? cellValues.row.businessName : ''}
                            ${cellValues.row.businessForm ? ' - ' + cellValues.row.businessForm.label : ''}`}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.businessName || cellValues.row.businessForm
    },
    {
        field: 'category',
        headerName: 'customerGridColCategory',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 120,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.siret || ''}>
                        <span>
                            {cellValues.row.category?.label || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.siret
    },
    {
        field: 'superFamily',
        headerName: 'customerGridColSuperFamily',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.superFamily?.label || ''}>
                        <span>
                            {cellValues.row.superFamily?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.superFamily?.code || cellValues.row.superFamily?.label
    },
    {
        field: 'family',
        headerName: 'customerGridColFamily',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.family?.label || ''}>
                        <span>
                            {cellValues.row.family?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.family?.code
    },
    {
        field: 'address',
        headerName: 'customerGridColAddress1',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 200,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={`${cellValues.row.address1 || ""}, ${cellValues.row.address2 || ""} `}>
                        <span>
                            {cellValues.row.address1 && cellValues.row.address2 ? `${cellValues.row.address1 || ""}, ${cellValues.row.address2 || ""}` : cellValues.row.address1}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.address1 && cellValues.row.address2 ? `${cellValues.row.address1 || ""}, ${cellValues.row.address2 || ""}` : cellValues.row.address1
    },
    {
        field: 'zipCode1',
        headerName: 'customerGridColZipCode1',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 60,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.zipCode1 || ''}>
                        <span>
                            {cellValues.row.zipCode1 || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.zipCode1
    },
    {
        field: 'city',
        headerName: 'customerGridColCity',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.city || ''}>
                        <span>
                            {cellValues.row.city || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.city
    },
    {
        field: 'isBlocked',
        headerName: 'customerGridColBlocked',
        headerAlign: "left",
        align: "left",
        type: 'boolean',
        width: 50,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <Box>
                    {cellValues.row.isBlocked ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                </Box>
            )
        },
    },
    {
        field: 'mail',
        headerName: 'customerGridColMail',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 150,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.mail || ''}>
                        <span>
                            <LinkMailTo label={cellValues.row.mail || ''} mailto={cellValues.row.mail || ''}/>
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.mail
    },
    {
        field: 'representant',
        headerName: 'customerGridColRepresentative',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.saleRepresentative1?.code || ''}>
                        <span>
                            {cellValues.row.saleRepresentative1?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.saleRepresentative1?.code
    },
    {
        field: 'rateCode',
        headerName: 'customerGridColRateCode',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.rateCode?.code || ''}>
                        <span>
                            {cellValues.row.rateCode?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.rateCode?.code
    },
    {
        field: 'paymentChoice',
        headerName: 'customerGridColPaymentChoice',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.paymentChoice?.label || ''}>
                        <span>
                            {cellValues.row.paymentChoice?.code || ''}
                        </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.paymentChoice?.code
    },
    {
        field: 'customerOutstanding',
        headerName: 'customerGridColCustomerOutstanding',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.customerOutstanding}>
                    <FormattedNumber
                        value={cellValues.row.customerOutstanding || 0}
                        maximumFractionDigits={2}
                        style="currency" currency="EUR"/>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.customerOutstanding
    },
    {
        field: 'amountDue',
        headerName: 'customerGridColAmountDue',
        headerAlign: "left",
        align: "left",
        type: 'string',
        width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.amountDue}>
                    <FormattedNumber
                        value={cellValues.row.amountDue || 0}
                        maximumFractionDigits={2}
                        style="currency" currency="EUR"/>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.amountDue
    }
]