import React from 'react';
import {Button, Grid} from "@mui/material";
import {UserStatusV2} from "../../../secondary/InMemory/Data/enums";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {getMessageDescriptor} from "../../../../i18n/messages";
import {useIntl} from "react-intl";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

interface GetUserStatusButtonInterface {
    userStatus: UserStatusV2
    dataGridMode?: boolean
}

const GetUserStatusButton = (props: GetUserStatusButtonInterface) => {
    const {userStatus, dataGridMode} = props
    const intl = useIntl()

    switch (userStatus) {
        case UserStatusV2.ACTIVE:
            return (
                <Button variant="outlined" disabled sx={{
                    color: "white !important",
                    backgroundColor: "green",
                    textTransform: "none",
                    width: "100%",
                }}>
                    {dataGridMode ? <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Grid container alignItems="center">
                                    <TaskAltIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {intl.formatMessage(getMessageDescriptor("userStatusButtonActive"))}
                            </Grid>
                        </Grid>
                        :
                        <>
                            <TaskAltIcon sx={{marginRight: "10px"}}/>
                            {intl.formatMessage(getMessageDescriptor("userStatusButtonActive"))}
                        </>
                    }
                </Button>
            )
        case UserStatusV2.INACTIVE:
            return (
                <Button variant="outlined" disabled sx={{
                    color: "white !important",
                    backgroundColor: "red",
                    textTransform: "none",
                    width: "100%",
                }}>
                    {dataGridMode ?
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Grid container alignItems="center">
                                    <DoNotDisturbIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {intl.formatMessage(getMessageDescriptor("userStatusButtonInactive"))}
                            </Grid>
                        </Grid>
                        :
                        <>
                            <DoNotDisturbIcon sx={{marginRight: "10px"}}/>
                            {intl.formatMessage(getMessageDescriptor("userStatusButtonInactive"))}
                        </>
                    }
                </Button>
            )
        case UserStatusV2.IN_PROGRESS:
            return (
                <Button variant="outlined" disabled sx={{
                    color: "white !important",
                    backgroundColor: "orange",
                    textTransform: "none",
                    width: "100%",
                }}>
                    {dataGridMode ?
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Grid container alignItems="center">
                                    <PublishedWithChangesIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {intl.formatMessage(getMessageDescriptor("userStatusButtonCreation"))}
                            </Grid>
                        </Grid>
                        :
                        <>
                            <PublishedWithChangesIcon sx={{marginRight: "10px"}}/>
                            {intl.formatMessage(getMessageDescriptor("userStatusButtonCreation"))}
                        </>
                    }
                </Button>
            )
        default:
            return (
                <Button variant="outlined" disabled sx={{
                    color: "white !important",
                    backgroundColor: "grey",
                    textTransform: "none",
                    width: "100%",
                }}>
                    {dataGridMode ?
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Grid container alignItems="center">
                                    <HelpOutlineIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {intl.formatMessage(getMessageDescriptor("userStatusButtonUnknown"))}
                            </Grid>
                        </Grid>
                        :
                        <>
                            <HelpOutlineIcon sx={{marginRight: "10px"}}/>
                            {intl.formatMessage(getMessageDescriptor("userStatusButtonUnknown"))}
                        </>
                    }
                </Button>
            )
    }
}

export default GetUserStatusButton;