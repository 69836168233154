import {CustomerSpecificCode, CustomerSpecificData} from "../../../../corelogic/models/CustomerSpecificData";

export interface CustomerSpecificDataResponse {
    customerSpecificCode: string
    top1?: string
    top2?: string
    top3?: string
    top4?: string
}

export const mapCustomerSpecificDataResponseApiResponseToCustomerSpecificDataResponse = (customerSpecificDataResponse: CustomerSpecificDataResponse): CustomerSpecificData => {
    return {
        customerSpecificCode: CustomerSpecificCode[customerSpecificDataResponse.customerSpecificCode as keyof typeof CustomerSpecificCode],
        top1:customerSpecificDataResponse.top1,
        top2:customerSpecificDataResponse.top2,
        top3:customerSpecificDataResponse.top3,
        top4:customerSpecificDataResponse.top4
    }
}