import {
    ItemPackaging,
} from "../../../../corelogic/models/item";
import {Vat} from "../../../../corelogic/models/vat";
import {
    ItemBrand,
    ItemFamily,
    ItemGeographicalOrigin,
    SuperItemFamily,
    Unit
} from "../../../../corelogic/models/parameter";

export const unitsData: Unit[] = [
    {
        "id": 1,
        "code": "KG",
        "label": "KG"
    },
    {
        "id": 2,
        "code": "L",
        "label": "L"
    },
    {
        "id": 3,
        "code": "UC",
        "label": "UC"
    }
]

export const brandsData: ItemBrand[] = [
    {
        "id": 1,
        "code": "IB1",
        "label": "Dell"
    },
    {
        "id": 2,
        "code": "IB2",
        "label": "HP"
    },
    {
        "id": 3,
        "code": "IB3",
        "label": "Lenovo"
    }
]

export const superItemFamilyData: SuperItemFamily[] = [
    {
        "id": 1,
        "code": "SIF1",
        "label": "Super Famille 1",
    },
    {
        "id": 2,
        "code": "SIF2",
        "label": "Super Famille 2",
    }
]

export const itemFamilyData: ItemFamily[] = [
    {
        "id": 1,
        "code": "IF1",
        "label": "Famille 1",
        "superItemFamily": superItemFamilyData[2]
    },
    {
        "id": 2,
        "code": "IF2",
        "label": "Famille 2",
        "superItemFamily": superItemFamilyData[2]
    },
    {
        "id": 3,
        "code": "IF3",
        "label": "Famille 3",
        "superItemFamily": superItemFamilyData[1]
    }
]

export const geographicalOriginData: ItemGeographicalOrigin[] = [
    {
        "id": 1,
        "code": "IGO1",
        "label": "Europe"
    },
    {
        "id": 2,
        "code": "IGO2",
        "label": "France"
    },
]

export const packagingTypeData: ItemPackaging[] = [
    {
        "id": 1,
        "code": "CT",
        "label": "Carton",
        authorizedForSale: true,
        coefficient: 1
    },
    {
        "id": 3,
        "code": "UC",
        "label": "Unité consomateur",
        authorizedForSale: false,
        coefficient: 2
    },
    {
        "id": 2,
        "code": "PAL",
        "label": "Palette",
        authorizedForSale: true,
        coefficient: 5
    }
]

export const vatData: Vat[] = [
    {
        "id": 1,
        "code": "E",
        "label": "Exonéré",
        "rate": 0
    },
    {
        "id": 2,
        "code": "R",
        "label": "Réduite",
        "rate": 0.055
    },
    {
        "id": 3,
        "code": "C",
        "label": "Corse",
        "rate": 0.1
    },
    {
        "id": 4,
        "code": "N",
        "label": "Normale",
        "rate": 0.2
    }
]

export const getEnumKeyFromValue = (enumType: { [k: string]: any }, value: any) => {
    const keys = Object.keys(enumType).filter((x) => enumType[x] === value)
    return keys.length > 0 ? keys[0] : ''
}