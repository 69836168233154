import React from 'react';
import {Button} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import {useNavigate} from "react-router-dom";

const ReturnButton = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(-1)
    }
    
    return (
        <Button variant="contained" onClick={handleNavigate}>
            <ArrowBackIosNewOutlinedIcon/>
        </Button>
    );
};

export default ReturnButton;