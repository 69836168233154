import {APIException, APINetworkException} from "corelogic/utils/api"
import {Dispatch} from "react"
import {APP_ACTIONS_TYPES} from "./AppActionsTypes"

export function apiErrorsGestion(e: Error, dispatch: Dispatch<any>) {
    if (e instanceof APIException) {
        dispatch({type: APP_ACTIONS_TYPES.generics.API_ERROR_CATCH, payload: e.getError()})
    } else if (e instanceof APINetworkException)
        dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e.getError()})
    else
        console.error(e)
}