import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {apiErrorsGestion} from "../../../store/utils";
import {ToolsGateway} from "../../gateways/ToolsGateway";

export const launchImport = ():
    ThunkResult<{ toolsGateway: ToolsGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {toolsGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.admin.ADMIN_RUN_IMPORT})
        try {
            await toolsGateway.launchImport()
            dispatch({type: APP_ACTIONS_TYPES.admin.ADMIN_IMPORTS_RUNNING})
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}

