import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {OrderGateway} from "../../../corelogic/gateways/OrderGateway";
import {Order, OrderComment, OrderFilter, OrderInitialize} from "../../../corelogic/models/order";
import {
    mapOrderApiResponseToOrder,
    mapOrderInitializeResponseToOrderInitializeApi,
    mapOrderToOrderApiRequest,
    OrderApiResponse
} from "./Model/orderApi";
import qs from "qs";

export class ApiOrderGateway implements OrderGateway {
    async retrieveAll(): Promise<Order[]> {
        return apiFetchApiResponse({url: API_PREFIX.order})
    }

    async retrieveById(id: number): Promise<Order | undefined> {
        return apiFetchApiResponse<OrderApiResponse>({url: buildUrl(API_PREFIX.order, id.toString())})
            .then((x) => mapOrderApiResponseToOrder(x))
    }

    async initNewOrder(idCustomer: number, orderInit?: OrderInitialize ): Promise<Order> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order, "initialize", idCustomer.toString()), method: 'POST',
            data:mapOrderInitializeResponseToOrderInitializeApi(orderInit)
        })
            .then((x) => mapOrderApiResponseToOrder(x))
    }

    async retrieveOrderCommentsByOrderId(orderId: number): Promise<OrderComment[]> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.order, "comment", orderId.toString())})
    }

    async addOrderComment(orderId: number, comment: string): Promise<OrderComment> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.order, "comment", orderId.toString()),
            data: {id: orderId + 1, message: comment, title: "Title test", orderId: orderId}
        })
    }

    async deleteOrder(idOrder: number): Promise<Order | undefined> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order, idOrder.toString()),
            method: 'DELETE'
        })
            .then((x) => {
                if (x) {
                    return mapOrderApiResponseToOrder(x)
                }
            })
    }

    async submitOrder(orderId: number): Promise<Order> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order, "submit", orderId.toString()),
            method: 'POST'
        })
            .then((x) => mapOrderApiResponseToOrder(x))
    }

    async validateOrder(order: Order, submit: undefined | boolean): Promise<Order> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order, "validate"),
            method: 'POST',
            data: {order: mapOrderToOrderApiRequest(order), submitOrder: submit}
        })
            .then((x) => mapOrderApiResponseToOrder(x))
    }

    async duplicateOrder(orderId: number): Promise<Order> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order, "duplicate", orderId.toString()),
            method: 'POST'
        })
            .then((x) => mapOrderApiResponseToOrder(x))
    }

    async retrieveAllFiltered(filterValue: OrderFilter): Promise<Order[]> {
        return apiFetchApiResponse<OrderApiResponse[]>({
            url: buildUrl(API_PREFIX.order, "filter"),
            params: {...filterValue},
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
            .then((x) => x.map(o => mapOrderApiResponseToOrder(o)))
    }

    async saveData(order: Order): Promise<Order> {
        return apiFetchApiResponse<OrderApiResponse>({
            url: buildUrl(API_PREFIX.order),
            method: 'PUT',
            data: mapOrderToOrderApiRequest(order)
        })
            .then((x) => mapOrderApiResponseToOrder(x))
    }
}