import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {ItemGateway} from "../../gateways/ItemGateway";
import {UserItemFavoritesGateway} from "../../gateways/UserItemFavoritesGateway";
import {ItemFilter, ItemPackagingForm, PaginationFilter} from "../../models/item";
import {apiErrorsGestion} from "../../../store/utils";
import {ItemPackagingGateway} from "../../gateways/ItemPackagingGateway";

export const retrieveAllItems = ():
    ThunkResult<{ itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.items.ITEMS_RETRIEVING})
        try {
            const itemsData = await itemGateway.retrieveAllWithFavorite()
            dispatch({
                type: APP_ACTIONS_TYPES.items.ITEMS_RETRIEVED,
                payload: {count: itemsData.length, items: itemsData}
            })
        } catch (e) {
            apiErrorsGestion(e as Error, dispatch)
        }
    }
}

export const retrieveAllItemsFiltered = (filterValue: ItemFilter, paginationFilter: PaginationFilter, customerId?: number):
    ThunkResult<{ itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.items.ITEMS_RETRIEVING})
        try {
            const itemsList = await itemGateway.retrieveAllFiltered(filterValue, paginationFilter, customerId)
            dispatch({type: APP_ACTIONS_TYPES.items.ITEMS_RETRIEVED, payload: itemsList})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllItemDataFormFields = ():
    ThunkResult<{ itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemGateway}) => {
        try {
            const itemFormDataVal = await itemGateway.retrieveAllItemFormFields()
            dispatch({type: APP_ACTIONS_TYPES.items.ITEM_FORM_DATA_FIELDS, payload: itemFormDataVal})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllItemDataFormFieldsForFilter = (idCustomer?: number):
    ThunkResult<{ itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemGateway}) => {
        try {
            const itemFormDataVal = await itemGateway.retrieveAllItemFormFieldsForFilter(idCustomer)
            dispatch({type: APP_ACTIONS_TYPES.items.ITEM_FORM_DATA_FIELDS, payload: itemFormDataVal})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSelectedItem = (itemId: number, customerId?: number):
    ThunkResult<{ itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.items.ITEM_FETCHING})
        try {
            const item = await itemGateway.retrieveById(itemId,customerId)
            dispatch({type: APP_ACTIONS_TYPES.items.ITEM_SELECTING, payload: item})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addUserItemFavorites = (itemId: number):
    ThunkResult<{ itemGateway: ItemGateway, userItemFavoritesGateway: UserItemFavoritesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userItemFavoritesGateway}) => {
        try {
            const addOk = await userItemFavoritesGateway.add(itemId)
            if (addOk)
                dispatch({type: APP_ACTIONS_TYPES.items.ITEM_ADD_FAVORITE, payload: itemId})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeUserItemFavorites = (itemId: number):
    ThunkResult<{ itemGateway: ItemGateway, userItemFavoritesGateway: UserItemFavoritesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userItemFavoritesGateway}) => {
        try {
            const removeOK = await userItemFavoritesGateway.remove(itemId)
            if (removeOK)
                dispatch({type: APP_ACTIONS_TYPES.items.ITEM_REMOVE_FAVORITE, payload: itemId})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveAllItemPackagings = (itemId: number):
    ThunkResult<{ itemPackagingGateway: ItemPackagingGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemPackagingGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_FETCHING})
        try {
            const itemPackagingTypes = await itemPackagingGateway.retrieveAllByItemId(itemId)
            dispatch({
                type: APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_RETRIEVED,
                payload: itemPackagingTypes,
                target: itemId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addItemPackaging = (itemId: number, itemPackaging: ItemPackagingForm):
    ThunkResult<{ itemPackagingGateway: ItemPackagingGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemPackagingGateway}) => {
        try {
            const itemPackagingTypeAdded = await itemPackagingGateway.add(itemId, itemPackaging)
            dispatch({
                type: APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_ADD,
                payload: itemPackagingTypeAdded,
                target: itemId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateItemPackaging = (itemPackaging: ItemPackagingForm):
    ThunkResult<{ itemPackagingGateway: ItemPackagingGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemPackagingGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_FETCHING})
        try {
            const itemPackagingTypes = await itemPackagingGateway.update(itemPackaging)
            dispatch({
                type: APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_UPDATE,
                payload: itemPackagingTypes
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const deleteItemPackaging = (itemPackagingId: number):
    ThunkResult<{ itemPackagingGateway: ItemPackagingGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {itemPackagingGateway}) => {
        try {
            await itemPackagingGateway.delete(itemPackagingId)
            dispatch({
                type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_DELETE,
                payload: itemPackagingId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}