import {defineMessages} from "react-intl";

type MessageDescriptor = { id: string, defaultMessage: string, description?: string | object }

const messages = defineMessages({
    "msgUnkown": {
        id: "app.msgUnkown",
        defaultMessage: "Message id not found in translation",
        description: "Message id not found in translation file"
    },
    "appLoginCredentialsError": {
        id: "app.login.credentials-error",
        defaultMessage: "User information does not allow login",
        description: "Login message caused by credentials error"
    },
    "appLoginSignIn": {
        id: "app.login.signin",
        defaultMessage: "Sign in",
        description: "Sign in"
    },
    "appLoginFormLogin": {
        id: "app.login.form.login",
        defaultMessage: "Login",
        description: "Signin form login"
    },
    "appLoginFormPassword": {
        id: "app.login.form.password",
        defaultMessage: "Password",
        description: "Signin form password"
    },
    "appLoginTokenExp": {
        id: "app.logout.token-exp",
        defaultMessage: "You have been logged out due to the expiry of your token",
        description: "Logout message caused by token expiration"
    },
    "appLogoutOtherTab": {
        id: "app.logout.other-tab",
        defaultMessage: "You have been logged out from another tab",
        description: "Logout message caused by logout from another tab"
    },
    "appVersion": {
        id: "app.version",
        defaultMessage: "v{version}",
        description: "The version of the application"
    },
    "customerHeaderTitle": {
        id: "customer-header-title",
        defaultMessage: "Customer",
        description: "The title for the header"
    },
    "customerListPageHeaderTitle": {
        id: "customer-list-page-header-title",
        defaultMessage: "CUSTOMERS",
        description: "The title for the header of the customer list page"
    },
    "customerListPageHeaderTitleSearchBar": {
        id: "customer-list-page-header-title-searchBar",
        defaultMessage: "Search customer",
        description: "The title for the search bar of the customer list page"
    },
    "customerPageGeneralInformationTitle": {
        id: "customer-page-general-information-title",
        defaultMessage: "General informations",
        description: "The title of the general information bloc in customer page visualisation"
    },
    "customerPageClassificationTitle": {
        id: "customer-page-classification-title",
        defaultMessage: "Classification",
        description: "The title of the classification bloc in customer page visualisation"
    },
    "customerPageContactTitle": {
        id: "customer-page-contact-title",
        defaultMessage: "Contact",
        description: "The title of the contact bloc in customer page visualisation"
    },
    "customerPageRateTitle": {
        id: "customer-page-rate-title",
        defaultMessage: "Tarifs",
        description: "The title of the rate bloc in customer page visualisation"
    },
    "customerPageBillTitle": {
        id: "customer-page-bill-title",
        defaultMessage: "Bill",
        description: "The title of the bill bloc in customer page visualisation"
    },
    "customerPageAdministrativeTitle": {
        id: "customer-page-administrative-title",
        defaultMessage: "Administrative",
        description: "The title of the administrative bloc in customer page visualisation"
    },
    "customerPageLogisticTitle": {
        id: "customer-page-logistic-title",
        defaultMessage: "Logistic",
        description: "The title of the logistic bloc in customer page visualisation"
    },
    "customerPageOutstandingTitle": {
        id: "customer-page-outstanding-title",
        defaultMessage: "Outstanding",
        description: "The title of the outstanding bloc in customer page visualisation"
    },
    "customerPageContactInformationTitle": {
        id: "customer-page-contact-information-title",
        defaultMessage: "Contact informations",
        description: "The title of the contact information bloc in customer page form"
    },
    "customerPageBankIdentificationTitle": {
        id: "customer-page-bank-identification-title",
        defaultMessage: "Bank Identification Statement",
        description: "The title of the bank identification bloc in customer page visualisation"
    },
    "customerPageDeliveryAddressTitle": {
        id: "customer-page-delivery-address-title",
        defaultMessage: "Delivery addresses",
        description: "The title of the delivery address bloc in customer page visualisation"
    },
    "customerPageTitle": {
        id: "customer-page-general-information-title",
        defaultMessage: "General informations",
        description: "The title of the general information bloc in customer page visualisation"
    },
    "customerPageFormFieldBusinessForm": {
        id: "customer-page-form-field-business-form",
        defaultMessage: "Business form",
        description: "The title of the field business form in customer page form"
    },
    "customerPageFormFieldLangage": {
        id: "customer-page-form-field-langage",
        defaultMessage: "Langage",
        description: "The title of the field langage in customer page form"
    },
    "customerPageFormFieldBusinessName": {
        id: "customer-page-form-field-business-name",
        defaultMessage: "Business name",
        description: "The title of the field business name in customer page form"
    },
    "customerPageFormFieldCode": {
        id: "customer-page-form-field-code",
        defaultMessage: "Code",
        description: "The title of the field code in customer page form"
    },
    "customerPageFormFieldCurrency": {
        id: "customer-page-form-field-currency",
        defaultMessage: "Currency",
        description: "The title of the field currency in customer page form"
    },
    "customerPageFormFieldSiret": {
        id: "customer-page-form-field-siret",
        defaultMessage: "Siret",
        description: "The title of the field siret in customer page form"
    },
    "customerPageFormFieldVat": {
        id: "customer-page-form-field-vat",
        defaultMessage: "Vat intracom",
        description: "The title of the field vat in customer page form"
    },
    "customerPageFormFieldApeNaf": {
        id: "customer-page-form-field-apeNaf",
        defaultMessage: "Ape / Naf code",
        description: "The title of the field ape / naf in customer page form"
    },
    "customerPageFormFieldEan": {
        id: "customer-page-form-field-ean",
        defaultMessage: "Ean code",
        description: "The title of the field ean in customer page form"
    },
    "customerPageFormFieldRate": {
        id: "customer-page-form-field-rate",
        defaultMessage: "Client's rate code",
        description: "The title of the field rate in customer page form"
    },
    "customerPageFormFieldAddress": {
        id: "customer-page-form-field-address",
        defaultMessage: "Address",
        description: "The title of the field rate in customer page form"
    },
    "customerPageFormPaymentChoice": {
        id: "customer-page-form-field-payment-choice",
        defaultMessage: "Payment choice",
        description: "The title of the field payment choice in customer page form"
    },
    "customerPageFormVatManagement": {
        id: "customer-page-form-field-vat-management",
        defaultMessage: "Vat management",
        description: "The title of the field vat management in customer page form"
    },
    "customerPageFormTransitTime": {
        id: "customer-page-form-field-transit-time",
        defaultMessage: "Transit time",
        description: "The title of the field transit time in customer page form"
    },
    "customerPageFormAcceptRemainder": {
        id: "customer-page-form-field-accept-remainder",
        defaultMessage: "Accept remainder",
        description: "The title of the field accept remainder in customer page form"
    },
    "customerPageFormCustomerOutstanding": {
        id: "customer-page-form-field-customer-outstanding",
        defaultMessage: "Customer outstanding",
        description: "The title of the field customer outstanding in customer page form"
    },
    "customerPageFormAmountDue": {
        id: "customer-page-form-field-amount-due",
        defaultMessage: "Amount Due",
        description: "The title of the field amount due in customer page form"
    },
    "customerPageFormSaleRatePercentage": {
        id: "customer-page-form-field-sale-rate-percentage",
        defaultMessage: "Sale rate",
        description: "The title of the field sale rate in customer page form"
    },
    "customerPageFormPromotionalRateCode": {
        id: "customer-page-form-field-promotional-rate-code",
        defaultMessage: "Promotional rate code",
        description: "The title of the field promotional rate code in customer page form"
    },
    "customerPageFormPromotionalSaleRatePercentage": {
        id: "customer-page-form-field-promotional-sale-rate-percentage",
        defaultMessage: "Promotional sale rate",
        description: "The title of the field promotional sale rate in customer page form"
    },
    "customerPageFormSuperFamily": {
        id: "customer-page-form-field-super-family",
        defaultMessage: "Family",
        description: "The title of the field super family in customer page form"
    },
    "customerPageFormFamily": {
        id: "customer-page-form-field-family",
        defaultMessage: "Sub family",
        description: "The title of the field family in customer page form"
    },
    "customerPageFormCategory": {
        id: "customer-page-form-field-category",
        defaultMessage: "Category",
        description: "The title of the field category in customer page form"
    },
    "customerPageFormFieldSaleRepresentative1Code": {
        id: "customer-page-form-field-sale-representative-1-code",
        defaultMessage: "Sale representative code",
        description: "The title of the field sale representative 1 code in customer page form"
    },
    "customerPageFormFieldAddress1": {
        id: "customer-page-form-field-address1",
        defaultMessage: "Address 1",
        description: "The title of the field address 1 in customer page form"
    },
    "customerPageFormFieldAddress2": {
        id: "customer-page-form-field-address2",
        defaultMessage: "Address 1",
        description: "The title of the field address 2 in customer page form"
    },
    "customerPageFormFieldAddress3": {
        id: "customer-page-form-field-address3",
        defaultMessage: "Address 3",
        description: "The title of the field address 3 in customer page form"
    },
    "customerPageFormFieldPostalCode": {
        id: "customer-page-form-field-postalCode",
        defaultMessage: "Postal code",
        description: "The title of the field postal code in customer page form"
    },
    "customerPageFormFieldPostalCode2": {
        id: "customer-page-form-field-postalCode2",
        defaultMessage: "Postal code 2",
        description: "The title of the field postal code 2 in customer page form"
    },
    "customerPageFormFieldCity": {
        id: "customer-page-form-field-city",
        defaultMessage: "City",
        description: "The title of the field city in customer page form"
    },
    "customerPageFormFieldCountry": {
        id: "customer-page-form-field-country",
        defaultMessage: "Country",
        description: "The title of the field country in customer page form"
    },
    "customerPageFormFieldPhone": {
        id: "customer-page-form-field-phone",
        defaultMessage: "Phone number",
        description: "The title of the field phone number in customer page form"
    },
    "customerPageFormFieldPhone2": {
        id: "customer-page-form-field-phone2",
        defaultMessage: "Phone number 2",
        description: "The title of the field phone number 2 in customer page form"
    },
    "customerPageFormFieldFax": {
        id: "customer-page-form-field-fax",
        defaultMessage: "Fax number",
        description: "The title of the field fax number in customer page form"
    },
    "customerPageFormFieldWebsite": {
        id: "customer-page-form-field-website",
        defaultMessage: "Website",
        description: "The title of the field website in customer page form"
    },
    "customerPageFormFieldEmail": {
        id: "customer-page-form-field-email",
        defaultMessage: "Email address",
        description: "The title of the field email address in customer page form"
    },
    "customerPageViewFieldMainAddress": {
        id: "customer-page-view-mainAddress",
        defaultMessage: "Head office address",
        description: "The title of the field head office address in customer page view"
    },
    "customerGridCustomerDeletion": {
        id: "customer-grid-customer-deletion",
        defaultMessage: "Delete existing customer",
        description: "The title for the delete customer"
    },
    "customerGridCustomerDeletionConfirm": {
        id: "customer-grid-customer-deletion-confirm",
        defaultMessage: "Confirm : client will be deleted",
        description: "The title for the confirm delete client"
    },
    "customerGridCustomerDeletionCancel": {
        id: "customer-grid-customer-deletion-cancel",
        defaultMessage: "Cancel : client will be saved",
        description: "The title for the cancel delete client"
    },
    "customerGridColCode": {
        id: "customer-grid-col-code",
        defaultMessage: "Code",
        description: "Customer grid code column"
    },
    "customerGridColCategory": {
        id: "customer-grid-col-category",
        defaultMessage: "Category",
        description: "Customer grid category column"
    },
    "customerGridColSuperFamily": {
        id: "customer-grid-col-super-family",
        defaultMessage: "Family",
        description: "Customer grid super family column"
    },
    "customerGridColFamily": {
        id: "customer-grid-col-family",
        defaultMessage: "Sub family",
        description: "Customer grid family column"
    },
    "customerGridColBusinessName": {
        id: "customer-grid-col-business-name",
        defaultMessage: "Business Name",
        description: "Customer grid business name"
    },
    "customerGridColAddress1": {
        id: "customer-grid-col-address-1",
        defaultMessage: "Address 1",
        description: "Customer grid address 1"
    },
    "customerGridColAddress2": {
        id: "customer-grid-col-address-2",
        defaultMessage: "Address 2",
        description: "Customer grid address 2"
    },
    "customerGridColZipCode1": {
        id: "customer-grid-col-zipcode-1",
        defaultMessage: "ZipCode 1",
        description: "Customer grid zipcode 1"
    },
    "customerGridColCity": {
        id: "customer-grid-col-city",
        defaultMessage: "City",
        description: "Customer grid city"
    },
    "customerGridColCountry": {
        id: "customer-grid-col-country",
        defaultMessage: "Country",
        description: "Customer grid country"
    },
    "customerGridColSiret": {
        id: "customer-grid-col-siret",
        defaultMessage: "Siret",
        description: "Customer grid siret"
    },
    "customerGridColBlocked": {
        id: "customer-grid-col-blocked",
        defaultMessage: "Blocked",
        description: "Customer blocked siret"
    },
    "customerGridColMail": {
        id: "customer-grid-col-mail",
        defaultMessage: "Mail",
        description: "Customer blocked mail"
    },
    "customerGridColRepresentative": {
        id: "customer-grid-col-representative",
        defaultMessage: "Representative",
        description: "Customer blocked representative"
    },
    "customerGridColRateCode": {
        id: "customer-grid-col-rate-code",
        defaultMessage: "Rate code",
        description: "Customer blocked rate code"
    },
    "customerGridColPaymentChoice": {
        id: "customer-grid-col-payment-choice",
        defaultMessage: "Payment choice",
        description: "Customer blocked payment choice"
    },
    "customerGridColCustomerOutstanding": {
        id: "customer-grid-col-customer-outstanding",
        defaultMessage: "Customer outstanding",
        description: "Customer blocked customer outstanding"
    },
    "customerGridColAmountDue": {
        id: "customer-grid-col-amount-due",
        defaultMessage: "Amount due",
        description: "Customer blocked amount due"
    },
    "customerNewCreation": {
        id: "customer-new-creation",
        defaultMessage: "Create a new customer",
        description: "Creating a new customer"
    },
    "customerNewCreationTitle": {
        id: "customer-new-creation-title",
        defaultMessage: "You are about to create a new customer",
        description: "Creating a new customer"
    },
    "customerAddressTitle": {
        id: "customer-address-title",
        defaultMessage: "Title",
        description: "Customer address title"
    },
    "customerAddressBusinessName": {
        id: "customer-address-business-name",
        defaultMessage: "Business name",
        description: "Customer address business name"
    },
    "customerAddressAddress1": {
        id: "customer-address-address-1",
        defaultMessage: "Address 1",
        description: "Customer address address 1"
    },
    "customerAddressAddress2": {
        id: "customer-address-address-2",
        defaultMessage: "Address 2",
        description: "Customer address address 2"
    },
    "customerAddressAddress3": {
        id: "customer-address-address-3",
        defaultMessage: "Address 3",
        description: "Customer address address 3"
    },
    "customerAddressZipCode1": {
        id: "customer-address-zipcode-1",
        defaultMessage: "ZipCode 1",
        description: "Customer address zipcode 1"
    },
    "customerAddressZipCode2": {
        id: "customer-address-zipcode-2",
        defaultMessage: "ZipCode 2",
        description: "Customer address zipcode 2"
    },
    "customerAddressCity": {
        id: "customer-address-city",
        defaultMessage: "City",
        description: "Customer address city"
    },
    "customerAddressCountry": {
        id: "customer-address-country",
        defaultMessage: "Country",
        description: "Customer address country"
    },
    "customerCode": {
        id: "customer-code",
        defaultMessage: "Customer code",
        description: "Customer code"
    },
    "customerActivateReferencing": {
        id: "customer-activate-referencing",
        defaultMessage: "Activate referencing",
        description: "Description for the toggle button to activate customer's item referencing"
    },
    "genericSeeMore": {
        id: "generic-see-more",
        defaultMessage: "See more",
        description: "Title for the button to see more details"
    },
    "genericApplyFilters": {
        id: "generic-apply-filters",
        defaultMessage: "Apply filters",
        description: "Title for the button to apply selected filters"
    },
    "genericDataGridChangeDensity": {
        id: "generic-data-grid-change-density",
        defaultMessage: "Change density",
        description: "Description for the datagrid's button to change the density of the grid"
    },
    "genericDataGridRefresh": {
        id: "generic-data-grid-refresh",
        defaultMessage: "Refresh data",
        description: "Description for the datagrid's button to refresh the data of the grid"
    },
    "genericStatusCREATION": {
        id: "generic-status-creation",
        defaultMessage: "Order creation",
        description: "Go back text for buttons"
    },
    "genericRetry": {
        id: "generic-retry",
        defaultMessage: "Retry",
        description: "Retry text for buttons"
    },
    "genericGoBack": {
        id: "generic-go-back",
        defaultMessage: "Go back",
        description: "Go back text for buttons"
    },
    "genericClose": {
        id: "generic-close",
        defaultMessage: "Close",
        description: "Close text for buttons"
    },
    "genericConfirm": {
        id: "generic-confirm",
        defaultMessage: "Confirm",
        description: "Confirm text for buttons"
    },
    "genericChange": {
        id: "generic-change",
        defaultMessage: "Change",
        description: "Change text for buttons"
    },
    "genericSubmit": {
        id: "generic-submit",
        defaultMessage: "Submit",
        description: "Submit text for buttons"
    },
    "genericNotSubmit": {
        id: "generic-not-submit",
        defaultMessage: "Don't submit",
        description: "Don't submit text for buttons"
    },
    "genericCancel": {
        id: "generic-cancel",
        defaultMessage: "Cancel",
        description: "Cancel text for buttons"
    },
    "genericValidate": {
        id: "generic-validate",
        defaultMessage: "Validate",
        description: "Validate text for buttons"
    },
    "genericTitleMyProfile": {
        id: "generic-title-myProfile",
        defaultMessage: "My profile",
        description: "Generic text for titles"
    },
    "genericTitleUserManual": {
        id: "generic-title-user-manual",
        defaultMessage: "See user manual",
        description: "Generic text for titles"
    },
    "genericTitleLogout": {
        id: "generic-title-logout",
        defaultMessage: "Log out",
        description: "Generic text for titles"
    },
    "genericTitleSwitchTheme": {
        id: "generic-title-switchTheme",
        defaultMessage: "Switch theme",
        description: "Generic text for titles"
    },
    "genericEditMode": {
        id: "generic-edit-mode",
        defaultMessage: "Enter edit mode",
        description: "Edit button text to enter edit mode"
    },
    "genericEditModeOrder": {
        id: "generic-edit-mode-order",
        defaultMessage: "Edit order's information",
        description: "Edit button tooltip to enter edit mode in order creation page header"
    },
    "genericViewBillingDetails": {
        id: "generic-view-billing-details",
        defaultMessage: "View basket",
        description: "Button tooltip in orderCreationPage to change the display"
    },
    "genericViewItemList": {
        id: "generic-view-item-list",
        defaultMessage: "View item list",
        description: "Button tooltip in orderCreationPage to change the display"
    },
    "userRoleButtonAdmin": {
        id: "user-role-button-admin",
        defaultMessage: "Administrator",
        description: "The title for the admin role button"
    },
    "userRoleButtonCustomer": {
        id: "user-role-button-customer",
        defaultMessage: "Customer",
        description: "The title for the customer role button"
    },
    "userRoleButtonCommercial": {
        id: "user-role-button-commercial",
        defaultMessage: "Commercial",
        description: "The title for the commercial role button"
    },
    "userRoleButtonUnknown": {
        id: "user-role-button-unknown",
        defaultMessage: "Unknown role",
        description: "The title for the unknown role button"
    },
    "userStatusButtonActive": {
        id: "user-status-button-active",
        defaultMessage: "Active",
        description: "The title for the active status button"
    },
    "userStatusButtonInactive": {
        id: "user-status-button-inactive",
        defaultMessage: "Inactive",
        description: "The title for the inactive status button"
    },
    "userStatusButtonCreation": {
        id: "user-status-button-creation",
        defaultMessage: "Creation",
        description: "The title for the creation status button"
    },
    "userStatusButtonUnknown": {
        id: "user-status-button-unknown",
        defaultMessage: "Unknown status",
        description: "The title for the unknown status button"
    },
    "userHeaderTitle": {
        id: "user-header-title",
        defaultMessage: "User",
        description: "The title for the header"
    },
    "userListHeaderTitle": {
        id: "user-list-header-title",
        defaultMessage: "Users",
        description: "The title for the user list"
    },
    "userToastMessageFormError": {
        id: "user-toastMessage-form-error",
        defaultMessage: "You must change at least one field before you can confirm",
        description: "The error message when you need to update at least one field before confirming"
    },
    "userToastMessageFormUpdateSuccess": {
        id: "user-toastMessage-form-update-success",
        defaultMessage: "Your user profile has been updated",
        description: "The success message you get when successfully updated user account"
    },
    "userListPageHeaderCreateUser": {
        id: "user-list-page-header-create-user",
        defaultMessage: "Create user",
        description: "The title for the button of the user list header"
    },
    "userListPageHeaderSearchUser": {
        id: "user-list-page-header-search-user",
        defaultMessage: "Search user",
        description: "The title for the search bar of the user list header"
    },
    "userListPageGridColRole": {
        id: "user-list-page-grid-col-role",
        defaultMessage: "Role",
        description: "The title for the role col of user list grid"
    },
    "userListPageGridColStatus": {
        id: "user-list-page-grid-col-status",
        defaultMessage: "Status",
        description: "The title for the status col of user list grid"
    },
    "userPageFormGeneralInformationTitle": {
        id: "user-page-form-general-information-title",
        defaultMessage: "Edit account information",
        description: "The title of the general information form for updating the user"
    },
    "userPageFormDeliveryAddressUpdateTitle": {
        id: "user-page-form-delivery-address-update-title",
        defaultMessage: "Update existing delivery address",
        description: "The title of the update form for updating the delivery address"
    },
    "userPageFormDeliveryAddressDeleteTitle": {
        id: "user-page-form-delivery-address-delete-title",
        defaultMessage: "You are about to delete this delivery address",
        description: "The title for the delete address modal from the delivery address tab of the customer page"
    },
    "userPageFormDeliveryAddressDeleteConfirm": {
        id: "user-page-form-delivery-address-delete-confirm",
        defaultMessage: "Confirm : the delivery address will be deleted",
        description: "The title for the delete confirm click address modal from the delivery address tab of the customer page"
    },
    "userPageFormDeliveryAddressDeleteCancel": {
        id: "user-page-form-delivery-address-delete-cancel",
        defaultMessage: "Cancel : the delivery address will be keeped",
        description: "The title for the delete cancel click address modal from the delivery address tab of the customer page"
    },
    "userPageFormAvatarTitle": {
        id: "user-page-form-avatar-title",
        defaultMessage: "Edit avatar",
        description: "The title of the avatar form for updating the user"
    },
    "userPageFormAvatarUploadError": {
        id: "user-page-form-avatar-upload-error",
        defaultMessage: "You can't upload files that are not image type",
        description: "The error message of the avatar upload file"
    },
    "userPageFormPasswordTitle": {
        id: "user-page-form-password-title",
        defaultMessage: "Edit password",
        description: "The title of the password form for updating the user"
    },
    "userPageFormPassword": {
        id: "user-page-form-password",
        defaultMessage: "Password",
        description: "The title of the password field from the user password form"
    },
    "userPageFormPasswordConfirm": {
        id: "user-page-form-password-confirm",
        defaultMessage: "Confirm password",
        description: "The title of the confirm password field from the user password form"
    },
    "userPageFormPasswordActual": {
        id: "user-page-form-password-actual",
        defaultMessage: "Actual password",
        description: "The title of the actual password field from the user password form"
    },
    "userPageFormPasswordNew": {
        id: "user-page-form-password-new",
        defaultMessage: "New password",
        description: "The title of the new password field from the user password form"
    },
    "userPageFormPasswordNewConfirmation": {
        id: "user-page-form-password-newConfirmation",
        defaultMessage: "Confirm new password",
        description: "The title of the new confirmation password field from the user password form"
    },
    "userPageFormFieldLogin": {
        id: "user-page-form-field-login",
        defaultMessage: "Login",
        description: "The title of the login field for the form"
    },
    "userPageFormFieldEmail": {
        id: "user-page-form-field-email",
        defaultMessage: "Email",
        description: "The title of the email field for the form"
    },
    "userPageFormFieldName": {
        id: "user-page-form-field-name",
        defaultMessage: "Last name",
        description: "The title of the name field for the form"
    },
    "userPageFormFieldFirstName": {
        id: "user-page-form-field-firstName",
        defaultMessage: "First name",
        description: "The title of the first name field for the form"
    },
    "userPageFormFieldLanguage": {
        id: "user-page-form-field-language",
        defaultMessage: "Language",
        description: "The title of the language field for the form"
    },
    "userPageFormFieldRole": {
        id: "user-page-form-field-role",
        defaultMessage: "Role",
        description: "The title of the role field for the form"
    },
    "userPageFormFieldStatus": {
        id: "user-page-form-field-status",
        defaultMessage: "Status",
        description: "The title of the status field for the form"
    },
    "userPageFormFieldRepresentative": {
        id: "user-page-form-field-representative",
        defaultMessage: "Representative",
        description: "The title of the representative field for the form"
    },
    "userPageFormFieldLinkedCustomer": {
        id: "user-page-form-field-linked-customer",
        defaultMessage: "Linked customer",
        description: "The title of the linked customer field for the form"
    },
    "userPageFormFieldSendEmailAtOrderSubmission": {
        id: "user-page-form-field-send-email-at-order-submission",
        defaultMessage: "Send email at order submission",
        description: "The title of the radio button send email at order submission field for the form"
    },
    "userPageFormErrorEmail": {
        id: "user-page-form-error-email",
        defaultMessage: "The email is not valid",
        description: "The error message of the user form when the email is not valid"
    },
    "userPageFormErrorName": {
        id: "user-page-form-error-name",
        defaultMessage: "The name is not valid",
        description: "The error message of the user form when the name is not valid"
    },
    "userPageFormErrorFirstName": {
        id: "user-page-form-error-firstName",
        defaultMessage: "The first name is not valid",
        description: "The error message of the user form when the first name is not valid"
    },
    "userPageFormErrorLangage": {
        id: "user-page-form-error-langage",
        defaultMessage: "Langage",
        description: "The text  message of the user form when the first name is not valid"
    },
    "userDeletionWarningConfirm": {
        id: "user-deletion-warning-confirm",
        defaultMessage: "Cancel : the user will be deleted",
        description: "The warning message to inform that the confirm click will delete the user"
    },
    "userDeletionWarningCancel": {
        id: "user-deletion-warning-cancel",
        defaultMessage: "Cancel : cancel the deletion of the user",
        description: "The warning message to inform that the confirm click will delete the user"
    },
    "userDeletionInfoTitle": {
        id: "user-deletion-info-title",
        defaultMessage: "You are about to delete a user",
        description: "The title of the deletion modale for user"
    },
    "orderGridLineActionDuplicate": {
        id: "order-grid-line-action-duplicate",
        defaultMessage: "Duplicate this order",
        description: "The text for the button action to duplicate the selected order"
    },
    "orderGridLineActionDelete": {
        id: "order-grid-line-action-delete",
        defaultMessage: "Delete this order",
        description: "The text for the button action to delete the selected order"
    },
    "orderGridLineActionSubmit": {
        id: "order-grid-line-action-submit",
        defaultMessage: "Submit this order",
        description: "The text for the button action to submit the selected order"
    },
    "orderGridLineActionPrintOrderBill": {
        id: "order-grid-line-action-printOrderBill",
        defaultMessage: "Print the order bill",
        description: "The text for the button action to print bill of the selected order"
    },
    "orderGridLineActionPrintDeliveryBill": {
        id: "order-grid-line-action-printOrderDeliveryBill",
        defaultMessage: "Print the order delivery bill",
        description: "The text for the button action to print delivery bill of the selected order"
    },
    "orderGridLineActionSeeComment": {
        id: "order-grid-line-action-seeComment",
        defaultMessage: "See comments for this order",
        description: "The text for the button action to see the comments for the selected order"
    },
    "orderGridLineActionSeeOrder": {
        id: "order-grid-line-action-seeOrder",
        defaultMessage: "See this order",
        description: "The text for the button action to see the order in order visualisation page"
    },
    "orderGridLineActionUpdateOrder": {
        id: "order-grid-line-action-updateOrder",
        defaultMessage: "Update this order",
        description: "The text for the button action to update the order in order creation page"
    },
    "orderGridOrderDeletion": {
        id: "order-grid-order-deletion",
        defaultMessage: "You are about to delete this order",
        description: "The title for the delete order"
    },
    "orderGridColId": {
        id: "order-grid-col-id",
        defaultMessage: "Order ID",
        description: "The unique order id"
    },
    "orderGridColNum": {
        id: "order-grid-col-num",
        defaultMessage: "Order number",
        description: "The unique order code"
    },
    "orderGridColRef": {
        id: "order-grid-col-ref",
        defaultMessage: "Reference",
        description: "The order reference"
    },
    "orderGridColCodeClient": {
        id: "order-grid-col-code-client",
        defaultMessage: "Code client",
        description: "The order code client"
    },
    "orderGridColLabelClient": {
        id: "order-grid-col-label-client",
        defaultMessage: "Order's client label",
        description: "The order's client label"
    },
    "orderFilterByCreationDate": {
        id: "order-filter-by-crtdate",
        defaultMessage: "From that creation date",
        description: "The title for the date picker for filtering order by creation date"
    },
    "orderFilterByDeliveryDate": {
        id: "order-filter-by-deliveryDate",
        defaultMessage: "To that delivery date",
        description: "The title for the date picker for filtering order by delivery date"
    },
    "orderGridColCrtDate": {
        id: "order-grid-col-crtdate",
        defaultMessage: "Creation date",
        description: "The date of the order's creation"
    },
    "orderGridColDeliveryDate": {
        id: "order-grid-col-deliveryDate",
        defaultMessage: "Delivery date",
        description: "The delivery date of the order"
    },
    "orderGridColTotalExclTax": {
        id: "order-grid-col-total-excl-tax",
        defaultMessage: "Total excluding tax",
        description: "The total excluding tax for an order"
    },
    "orderGridColTotalTaxes": {
        id: "order-grid-col-total-taxes",
        defaultMessage: "Total taxes",
        description: "The total taxes for an order"
    },
    "orderGridColTotalIncludingShippingTax": {
        id: "order-grid-col-total-including-shipping-tax",
        defaultMessage: "Total shipping excluding tax",
        description: "The total port excluding tax for an order"
    },
    "orderGridColTotalProductWithoutShippingTaxes": {
        id: "order-grid-col-total-product-without-shipping-taxes",
        defaultMessage: "Tot. excl. tax without shipping fees",
        description: "The total excluding tax without shipping fees of the order"
    },
    "orderGridColTotalGrossExclTax": {
        id: "order-grid-col-total-gross-excl-tax",
        defaultMessage: "Total gross excl. tax",
        description: "The total gross excluding tax of the order"
    },
    "orderGridColTotalShippingCost": {
        id: "order-grid-col-total-shipping-cost",
        defaultMessage: "Shipping costs",
        description: "The total shipping costs of the order"
    },
    "orderLineGridColTotalTax": {
        id: "order-line-grid-col-total-tax",
        defaultMessage: "Total taxes",
        description: "Total price without taxes for an order",
    },
    "orderLineGridColTotalInclTax": {
        id: "order-line-grid-col-total-incl-tax",
        defaultMessage: "Total taxes",
        description: "Total price with taxes for an order",
    },
    "orderGridColOrderStatus": {
        id: "order-grid-col-order-status",
        defaultMessage: "Order status",
        description: "The status of the order"
    },
    "orderLineGridColItemCodeLabel": {
        id: "order-line-grid-col-item-code-label",
        defaultMessage: "Item code/description",
        description: "The Code and short label of the article in order line for header column"
    },
    "orderLineGridColItemCodeLabelDesc": {
        id: "order-line-grid-col-item-code-label-desc",
        defaultMessage: "Code and short label of the article",
        description: "The Code and short label of the article in order line for tooltip"
    },
    "orderLineGridColItemQuantity": {
        id: "order-line-grid-col-item-quantity",
        defaultMessage: "Quantity",
        description: "The quantity of the article in order line"
    },
    "orderLineGridColItemQuantityDesc": {
        id: "order-line-grid-col-item-quantity-desc",
        defaultMessage: "You can change the quantity here",
        description: "The quantity of the article in order line for tooltip"
    },
    "orderLineGridColUnitPriceExclTax": {
        id: "order-line-grid-col-unit-price-excl-tax",
        defaultMessage: "Price excl. tax",
        description: "The Unit price excluding tax of the order line for header column"
    },
    "orderLineGridColUnitPriceInclTax": {
        id: "order-line-grid-col-unit-price-incl-tax",
        defaultMessage: "Price incl. tax",
        description: "The Unit price including tax of the order line for header column"
    },
    "orderLineGridColFree": {
        id: "order-line-grid-col-free",
        defaultMessage: "Free",
        description: "The free tag of the order line for header column"
    },
    "orderLineAddConfirmationTitle": {
        id: "order-line-add-confirmation-title",
        defaultMessage: "Confirm add line ?",
        description: "The title of the modal to confirm adding an existing order line"
    },
    "orderLineAddConfirmationInfo": {
        id: "order-line-add-confirmation-info",
        defaultMessage: "This item has already been added, confirm order line adding ?",
        description: "The info message of the modal to confirm adding an existing order line"
    },
    "orderLineGridDeleteQuantity0": {
        id: "order-line-grid-delete-quantity-0",
        defaultMessage: "The quantity for this item can't be to 0, confirm deletion ?",
        description: "The info message to inform the behavior behind the item's quantity to 0"
    },
    "orderLineGridDeleteClickConfirm": {
        id: "order-line-grid-delete-click-confirm",
        defaultMessage: "Confirm: the line will be deleted",
        description: "The info message to inform the behavior behind the confirm click on deletion modal"
    },
    "orderLineGridDeleteClickCancel": {
        id: "order-line-grid-delete-click-cancel",
        defaultMessage: "Cancel: the line will be keeped",
        description: "The info message to inform the behavior behind the cancel click on deletion modal"
    },
    "orderLineGridColPackageOrdered": {
        id: "order-line-grid-col-package-ordered",
        defaultMessage: "Package",
        description: "The package for the ordered item in the order line for header column"
    },
    "orderLineGridColFreeDesc": {
        id: "order-line-grid-col-free-desc",
        defaultMessage: "Free tag for a product",
        description: "The free tag of the order line for tooltip"
    },
    "orderLineGridColUnitPriceExclTaxDesc": {
        id: "order-line-grid-col-unit-price-excl-tax-desc",
        defaultMessage: "Unit price excluding tax for a product",
        description: "The Unit price excluding tax of the order line for tooltip"
    },
    "orderLineGridColPackageDesc": {
        id: "order-line-grid-col-package-desc",
        defaultMessage: "Item's package",
        description: "The item's package ordered for this order line"
    },
    "orderLineGridColDiscount": {
        id: "order-line-grid-col-discount",
        defaultMessage: "D1",
        description: "The discount 1 of the order line for billing grid's header column"
    },
    "orderLineGridColDiscount2": {
        id: "order-line-grid-col-discount2",
        defaultMessage: "D2",
        description: "The discount 2 of the order line for billing grid's header column"
    },
    "orderLineGridColDiscount3": {
        id: "order-line-grid-col-discount3",
        defaultMessage: "D3",
        description: "The discount 3 of the order line for billing grid's header column"
    },
    "orderLineGridColDiscountDesc": {
        id: "order-line-grid-col-discount-desc",
        defaultMessage: "Order line discount",
        description: "The discount of the order line for tooltip"
    },
    "orderLineGridColTotalExclTax": {
        id: "order-line-grid-col-total-excl-tax",
        defaultMessage: "Total excl. tax",
        description: "The total excluding tax of the order line for header column"
    },
    "orderLineGridColTotalExclTaxDesc": {
        id: "order-line-grid-col-total-excl-tax-desc",
        defaultMessage: "Total excluding tax for a product",
        description: "The total excluding tax of the order line for tooltip"
    },
    "orderListPageHeaderTitle": {
        id: "order-list-page-header-title",
        defaultMessage: "ORDERS",
        description: "The title for the header of the OrderList page"
    },
    "orderListPageHeaderButton": {
        id: "order-list-page-header-button",
        defaultMessage: "Create a new order",
        description: "The button to create a new order"
    },
    "orderCreationPageHeaderTitle": {
        id: "order-creation-page-header-button",
        defaultMessage: "Order's code",
        description: "The title for the header of the OrderCreation page"
    },
    "orderCreationPageWarningDeliveryAddress": {
        id: "order-creation-page-warning-delivery-address",
        defaultMessage: "Delivery address is incomplete",
        description: "The warning message for an order to inform the user that the delivery address is incomplete"
    },
    "orderCreationPageWarningNoOrderLines": {
        id: "order-creation-page-warning-no-order-lines",
        defaultMessage: "There are no order lines",
        description: "The warning message for an order to inform the user that there are no order lines"
    },
    "orderCreationPageWarningDeliveryDate": {
        id: "order-creation-page-warning-delivery-date",
        defaultMessage: "Delivery date is invalid",
        description: "The warning message for an order to inform the user that the delivery date is invalid"
    },
    "orderCreationPageHeaderDeliveryAddressButton": {
        id: "order-creation-page-delivery-address-button",
        defaultMessage: "Delivery address",
        description: "The description for the header of the OrderCreation page to choose a delivery address"
    },
    "orderCreationPageHeaderDeliveryAddressCreation": {
        id: "order-creation-page-delivery-address-creation",
        defaultMessage: "Create a new delivery address",
        description: "The button to create a new delivery address from the orderCreationPage header"
    },
    "orderCreationPageHeaderDeliveryAddressNotFound": {
        id: "order-creation-page-delivery-address-not-found",
        defaultMessage: "No registered address, please add one",
        description: "The informative message that the user doesn't have any registered address"
    },
    "orderCreationPageHeaderDeliveryDelay": {
        id: "order-creation-page-delivery-delay",
        defaultMessage: "Wished delivery date",
        description: "The datePicker title to choose a wished delivery date from the orderCreationPage header"
    },
    "orderCreationPageHeaderDialogSelectCustomer": {
        id: "order-creation-page-header-dialog-select-customer",
        defaultMessage: "Select a customer for the order",
        description: "The dialog title to select a customer for the order creation"
    },
    "orderCreationPageHeaderDialogSelectCustomerNotFound": {
        id: "order-creation-page-header-dialog-select-customer-not-found",
        defaultMessage: "Customer not found, please retry",
        description: "The dialog info message when you find no customer with the searchbar"
    },
    "orderCreationPageOrderDetailsTitle": {
        id: "order-creation-page-order-details-title",
        defaultMessage: "BASKET",
        description: "The title of the order creation billing bloc"
    },
    "orderCreationModalOrderRegistered": {
        id: "order-creation-modal-order-registered",
        defaultMessage: "Your order has been registered, would you like to submit it?",
        description: "The message of the modal when you validate an order with the status of : creation"
    },
    "orderCreationModalOrderRegisteredSubmittingInformation": {
        id: "order-creation-modal-order-registered-submitting-information",
        defaultMessage: "Submit: progress of the order to status : PENDING VALIDATION, no further changes possible",
        description: "The message of the modal when you validate an order with the status of : creation"
    },
    "orderCreationModalOrderRegisteredCancelSubmittingInformation": {
        id: "order-creation-modal-order-registered-cancel-submitting-information",
        defaultMessage: "Do not submit: order status to : NEW, later modifications possible",
        description: "The message of the modal when you cancel submitting an order with the status of : CREATION/NEW"
    },
    "orderCreationModalOrderLineDeleteConfirmation": {
        id: "order-creation-modal-order-line-delete-confirmation",
        defaultMessage: "Confirm order line deletion ?",
        description: "The message of the modal when you delete order line to ask for confirmation"
    },
    "orderCreationModalDeleteOrderConfirmationTitle": {
        id: "order-creation-modal-delete-order-confirmation-title",
        defaultMessage: "Confirm order deletion ?",
        description: "The message of the modal when you click on cancel click in the orderCreation page"
    },
    "orderCreationModalDeleteOrderConfirmation": {
        id: "order-creation-modal-delete-order-confirmation",
        defaultMessage: "Confirm : the order will be deleted",
        description: "The message of the modal to inform the user on the behavior behind the confirm click"
    },
    "orderCreationModalDeleteOrderCancel": {
        id: "order-creation-modal-delete-order-cancel",
        defaultMessage: "Cancel : the order will be saved",
        description: "The message of the modal to inform the user on the behavior behind the cancel click"
    },
    "orderCreationModalQuantityErrorInfoTitle": {
        id: "order-creation-modal-quantity-error-info-title",
        defaultMessage: "Quantity error",
        description: "Quantity error title for the modal"
    },
    "orderListModalErrorSubmitInfoTitle": {
        id: "order-list-modal-error-submit-info-title",
        defaultMessage: "Not allowed to submit this order",
        description: "Error title for the modal"
    },
    "orderListModalSubmitInfoTitle": {
        id: "order-list-modal-submit-info-title",
        defaultMessage: "Order submission",
        description: "Title for the order submit modal"
    },
    "orderListModalReSubmitInfoTitle": {
        id: "order-list-modal-resubmit-info-title",
        defaultMessage: "Order resubmission",
        description: "Title for the order resubmit modal"
    },
    "orderListModalErrorSubmitInfo": {
        id: "order-list-modal-error-submit-info",
        defaultMessage: "Please check the informations of the order before trying to submit again",
        description: "Error description for the modal"
    },
    "orderListModalSubmitInfo": {
        id: "order-list-modal-submit-info",
        defaultMessage: "Attention you are about to submit the order.{br}This action is irreversible and will make the order not modifiable later",
        description: "Description for the order submit modal"
    },
    "orderListModalReSubmitInfo": {
        id: "order-list-modal-resubmit-info",
        defaultMessage: "Attention you are about to resubmit the order.{br}This action should only be performed if the submission file has not already been integrated.",
        description: "Description for the order resubmit modal"
    },
    "orderCreationModalQuantityErrorInfo": {
        id: "order-creation-modal-quantity-error-info",
        defaultMessage: "Quantity can't be under 1 and above 999 999",
        description: "Message to warn about quantity error"
    },
    "orderCommentsToggleTooltip": {
        id: "order-comments-toggle-tooltip",
        defaultMessage: "See comments of the order",
        description: "The tooltip's toggle for the hamburger button to see comments for an order"
    },
    "orderCommentsToggleCloseTooltip": {
        id: "order-comments-toggle-close-tooltip",
        defaultMessage: "Close comments of the order",
        description: "The tooltip's toggle for the hamburger button to close comments for an order"
    },
    "orderListPageCommentsOrder": {
        id: "order-list-page-comments-order",
        defaultMessage: "Comment of an order",
        description: "The title for the modal to see comments for an order from the orderListPage"
    },
    "orderCommentInputLabel": {
        id: "order-comment-input-label",
        defaultMessage: "Your comment",
        description: "The label for the input of a command order"
    },
    "orderCommentsEmpty": {
        id: "order-comments-empty",
        defaultMessage: "No comment for this order",
        description: "The text to inform there are no comments for an order"
    },
    "orderListPageCommentsOrderAuthor": {
        id: "order-list-page-comments-order-author",
        defaultMessage: "From",
        description: "The author of the comment"
    },
    "orderListPageCommentsOrderDate": {
        id: "order-list-page-comments-order-date",
        defaultMessage: "The",
        description: "The date for the comment"
    },
    "orderVisualisationPageHeaderTitle": {
        id: "order-visualisation-page-header-title",
        defaultMessage: "Order",
        description: "The title of the header of the order visualisation page"
    },
    "orderVisualisationPageGridOrderLinesItemCode": {
        id: "order-visualisation-page-grid-order-lines-item-code",
        defaultMessage: "Item code",
        description: "The item code for this order"
    },
    "orderVisualisationPageGridOrderLinesItemLabel": {
        id: "order-visualisation-page-grid-order-lines-item-label",
        defaultMessage: "Item label",
        description: "The item label for this order"
    },
    "orderVisualisationPageGridOrderLinesItemSuperFamily": {
        id: "order-visualisation-page-grid-order-lines-item-super-family",
        defaultMessage: "Family",
        description: "The item's super family for this order"
    },
    "orderVisualisationPageGridOrderLinesItemFamily": {
        id: "order-visualisation-page-grid-order-lines-item-family",
        defaultMessage: "Sub family",
        description: "The item's family for this order"
    },
    "orderVisualisationPageGridOrderLinesItemBrand": {
        id: "order-visualisation-page-grid-order-lines-item-brand",
        defaultMessage: "Brand",
        description: "The item's brand for this order"
    },
    "orderVisualisationPageGridOrderLinesItemIsBlocked": {
        id: "order-visualisation-page-grid-order-lines-item-is-blocked",
        defaultMessage: "Blocked",
        description: "The item's blocked state"
    },
    "orderVisualisationPageGridOrderLinesItemAuthorizationToSellByPackage": {
        id: "order-visualisation-page-grid-order-lines-item-authorization-to-sell-by-package",
        defaultMessage: "Authorized to sell by package",
        description: "The item's AuthorizationToSellByPackage state"
    },
    "orderVisualisationPageGridOrderLinesItemAuthorizationToSellByUnit": {
        id: "order-visualisation-page-grid-order-lines-item-authorization-to-sell-by-unit",
        defaultMessage: "Authorized to sell by unit",
        description: "The item's AuthorizationToSellByUnit state"
    },
    "orderVisualisationPageGridOrderLinesItemQuantity": {
        id: "order-visualisation-page-grid-order-lines-item-quantity",
        defaultMessage: "Quantity",
        description: "The item quantity for this order"
    },
    "orderVisualisationPageGridOrderLinesItemPackaging": {
        id: "order-visualisation-page-grid-order-lines-item-packaging",
        defaultMessage: "Packaging",
        description: "The item packaging for this order"
    },
    "orderVisualisationPageGridOrderLinesItemQuantityDelivered": {
        id: "order-visualisation-page-grid-order-lines-item-quantity-delivered",
        defaultMessage: "Quantity delivered",
        description: "The item quantity delivered for this order"
    },
    "orderVisualisationPageGridOrderLinesItemGrossPrice": {
        id: "order-visualisation-page-grid-order-lines-item-gross-price",
        defaultMessage: "Gross price",
        description: "The item ross price for this order"
    },
    "orderVisualisationPageGridOrderLinesItemDiscount1": {
        id: "order-visualisation-page-grid-order-lines-item-discount1",
        defaultMessage: "D1",
        description: "The item discount 1 for this order"
    },
    "orderVisualisationPageGridOrderLinesItemDiscount2": {
        id: "order-visualisation-page-grid-order-lines-item-discount2",
        defaultMessage: "D2",
        description: "The item discount 2 for this order"
    },
    "orderVisualisationPageGridOrderLinesItemDiscount3": {
        id: "order-visualisation-page-grid-order-lines-item-discount3",
        defaultMessage: "D3",
        description: "The item discount 3 for this order"
    },
    "orderVisualisationPageGridOrderLinesItemTotalExcludingTaxes": {
        id: "order-visualisation-page-grid-order-lines-item-total-excluding-taxes",
        defaultMessage: "Total excluding taxes",
        description: "The item total excluding taxes for this order"
    },
    "orderInformations": {
        id: "order-informations",
        defaultMessage: "Order's informations",
        description: "The title of the form modal to edit order's informations"
    },
    "orderReference": {
        id: "order-reference",
        defaultMessage: "Reference",
        description: "The reference of an order"
    },
    "orderReferenceAdd": {
        id: "order-reference-add",
        defaultMessage: "Add reference",
        description: "Add a reference to an order"
    },
    "orderDelayRequested": {
        id: "order-delay-requested",
        defaultMessage: "Delivery delay requested",
        description: "The delivery delay requested of an order"
    },
    "orderDelayConfirmed": {
        id: "order-delay-confirmed",
        defaultMessage: "Delivery delay confirmed",
        description: "The delivery delay confirmed of an order"
    },
    "orderDeliveryAddress": {
        id: "order-delivery-address",
        defaultMessage: "Delivery address",
        description: "The delivery address of an order"
    },
    "orderTotalTitle": {
        id: "order-total-title",
        defaultMessage: "Total order",
        description: "The total price title for the order"
    },
    "orderTotalGrossExcludingTaxes": {
        id: "order-total-gross-excluding-taxes",
        defaultMessage: "Total gross excluding taxes",
        description: "The total gross excluding taxes's price for the order"
    },
    "orderTotalShippingCosts": {
        id: "order-total-shipping-costs",
        defaultMessage: "Total shipping costs",
        description: "The total gross excluding taxes's price for the order"
    },
    "orderTotalDiscount": {
        id: "order-total-discount",
        defaultMessage: "Discount",
        description: "The discount for the order"
    },
    "orderTotalExcludingTaxes": {
        id: "order-total-excluding-taxes",
        defaultMessage: "Total excluding taxes",
        description: "The total excluding taxes for the order"
    },
    "orderTotalVat": {
        id: "order-total-vat",
        defaultMessage: "Total VAT",
        description: "The total vat taxes for the order"
    },
    "orderTotalIncludingTaxes": {
        id: "order-total-including-taxes",
        defaultMessage: "Total including taxes",
        description: "The total including taxes for the order"
    },
    "searchBar": {
        id: "search-bar",
        defaultMessage: "Search...",
        description: "Search something"
    },
    "searchBarOrder": {
        id: "search-bar-order",
        defaultMessage: "Search Order...",
        description: "Search an order by Code or Reference"
    },
    "searchBarItem": {
        id: "search-bar-item",
        defaultMessage: "Search Item...",
        description: "Search an item by code or label"
    },
    "itemDetailsPageHeaderTitle": {
        id: "item-details-page-header-title",
        defaultMessage: "ITEM",
        description: "The title for the header of the item's details page"
    },
    "itemListPageHeaderTitle": {
        id: "item-list-page-header-title",
        defaultMessage: "ITEMS",
        description: "The title for the header of the itemList page"
    },
    "itemListFilterOpenTitle": {
        id: "item-list-filter-open-title",
        defaultMessage: "Open items filters",
        description: "The text for tooltip of hamburger button to open the filters"
    },
    "itemListFilterCloseTitle": {
        id: "item-list-filter-close-title",
        defaultMessage: "Close items filters",
        description: "The text for tooltip of hamburger button to close the filters"
    },
    "itemListFilterResetTitle": {
        id: "item-list-filter-reset-title",
        defaultMessage: "Reset filters",
        description: "The text for the button to reset the filters"
    },
    "itemListFilterNotFoundTitle": {
        id: "item-list-filter-not-found-title",
        defaultMessage: "No product found",
        description: "The title for the message when you found no item with the current filter combination"
    },
    "itemListFilterNotFound": {
        id: "item-list-filter-notFound",
        defaultMessage: "There are no items matching your search.",
        description: "The text for the message when you found no item with the current filter combination"
    },
    "itemListFilterFieldFavouriteTitle": {
        id: "item-list-filter-field-favourite-title",
        defaultMessage: "Favourite",
        description: "The text for the favourite field of the filters"
    },
    "itemListFilterFieldReferencedTitle": {
        id: "item-list-filter-field-referenced-title",
        defaultMessage: "Referenced",
        description: "The text for the referenced field of the filters"
    },
    "itemListFilterFieldSuperFamilyTitle": {
        id: "item-list-filter-field-superFamily-title",
        defaultMessage: "Family",
        description: "The text for the super family field of the filters"
    },
    "itemListFilterFieldFamilyTitle": {
        id: "item-list-filter-field-family-title",
        defaultMessage: "Sub family",
        description: "The text for the family field of the filters"
    },
    "itemListFilterFieldBrandTitle": {
        id: "item-list-filter-field-brand-title",
        defaultMessage: "Brand",
        description: "The text for the brand field of the filters"
    },
    "itemFieldByHowMuch": {
        id: "item-list-by-how-much",
        defaultMessage: "# of parts/parcels",
        description: "The text for the by how much field"
    },
    "itemListFilterFieldVatTypeTitle": {
        id: "item-list-filter-field-vatType-title",
        defaultMessage: "Vat type",
        description: "The text for the vat type field of the filters"
    },
    "itemListFilterFieldGeographicalOriginTitle": {
        id: "item-list-filter-field-geographicalOrigin-title",
        defaultMessage: "Geographical origin",
        description: "The text for the geographical origin field of the filters"
    },
    "itemListDisplayChangeTitle": {
        id: "item-list-display-change-title",
        defaultMessage: "Change items display",
        description: "The text for tooltip of button to switch display of items cards in the itemList"
    },
    "itemListCardSeeMoreDetails": {
        id: "item-list-card-seeMoreDetails",
        defaultMessage: "See detailed page product",
        description: "The text for tooltip of button to see full details for this item"
    },
    "itemListCardRateInformation": {
        id: "item-list-card-rateInformation",
        defaultMessage: "Rates for this product",
        description: "The text for tooltip of button to see detailed rates for this product"
    },
    "itemDetailRateGrossPrice": {
        id: "item-detail-rate-gross-price",
        defaultMessage: "Gross price",
        description: "The text for the value of the item's gross price field"
    },
    "itemDetailRateGrossPriceBuy": {
        id: "item-detail-rate-gross-price-buy",
        defaultMessage: "Gross price buy",
        description: "The text for the value of the item's gross buy price field"
    },
    "itemDetailRateNetPrice": {
        id: "item-detail-rate-net-price",
        defaultMessage: "Net price",
        description: "The text for the value of the item's net price field"
    },
    "itemDetailRateNetPriceBuy": {
        id: "item-detail-rate-net-price-buy",
        defaultMessage: "Net price buy",
        description: "The text for the value of the item's net price buy field"
    },
    "itemDetailRateCustomerRate": {
        id: "item-detail-rate-customer-rate",
        defaultMessage: "Customer's rate",
        description: "The text for the value of the item's and customer's rate"
    },
    "itemDetailRateCustomerPromotionalRate": {
        id: "item-detail-rate-customer-promotional-rate",
        defaultMessage: "Customer's promotional rate",
        description: "The text for the value of the item's and customer's promotional rate"
    },
    "itemDetailRateCustomerLineDiscount": {
        id: "item-detail-rate-customer-line-discount",
        defaultMessage: "Customer's line discount",
        description: "The text for the value of the item's and customer's line discount"
    },
    "itemDetailStockToDateWithDate": {
        id: "item-detail-stock-to-date-with-date",
        defaultMessage: "Stock to date: {date}",
        description: "The text for the value of the item's stock to date with date"
    },
    "itemDetailStockToDateWithoutDate": {
        id: "item-detail-stock-to-date-without-date",
        defaultMessage: "Stock to date",
        description: "The text for the value of the item's stock to date without date"
    },
    "itemDetailPhysicalStock": {
        id: "item-detail-physical-stock",
        defaultMessage: "Physical stock",
        description: "The text for the value of the item's physical stock"
    },
    "itemDetailPurchasesOutstanding": {
        id: "item-detail-purchases-outstanding",
        defaultMessage: "Purchases outstanding",
        description: "The text for the value of the item's purchases outstanding"
    },
    "itemDetailSalesOutstanding": {
        id: "item-detail-sales-outstanding",
        defaultMessage: "Sales outstanding",
        description: "The text for the value of the item's sales outstanding"
    },
    "itemListCardFieldPackaging": {
        id: "item-list-card-field-packaging",
        defaultMessage: "Packaging",
        description: "The text for description of the packaging field for this product"
    },
    "itemListCardFieldOrderingUnits": {
        id: "item-list-card-field-orderingUnits",
        defaultMessage: "Units",
        description: "The text for description of the ordering units field for this product"
    },
    "itemListCardFieldAuthorizationToSellByConsumerUnit": {
        id: "item-list-card-field-authorization-to-sell-by-consumer-unit",
        defaultMessage: "Authorization to sell by UC",
        description: "The text for description of the authorization to sell by uc field for this product"
    },
    "itemListCardFieldSold": {
        id: "item-list-card-field-sold",
        defaultMessage: "Sold",
        description: "The text for description of the sold field for this product"
    },
    "itemListCardFieldPurchased": {
        id: "item-list-card-field-purchased",
        defaultMessage: "Purchased",
        description: "The text for description of the purchased field for this product"
    },
    "itemListCardFieldBlocked": {
        id: "item-list-card-field-blocked",
        defaultMessage: "Blocked",
        description: "The text for description of the blocked field for this product"
    },
    "itemListCardFieldManagedInStock": {
        id: "item-list-card-field-managed-in-stock",
        defaultMessage: "Managed in stock",
        description: "The text for description of the managed in stock field for this product"
    },
    "itemListCardFieldPublishedInCatalog": {
        id: "item-list-card-field-published-in-catalog",
        defaultMessage: "Published in the catalog",
        description: "The text for description of the published in the catalog field for this product"
    },
    "itemListCardFieldExportECommerce": {
        id: "item-list-card-field-export-ecommerce",
        defaultMessage: "E-commerce export",
        description: "The text for description of the export e-commerce field for this product"
    },
    "itemListCardFieldPresentation": {
        id: "item-list-card-field-presentation",
        defaultMessage: "Presentation",
        description: "The text for button to see presentation of an item"
    },
    "itemListCardFieldPIngredients": {
        id: "item-list-card-field-ingredients",
        defaultMessage: "Ingredients",
        description: "The text for button to see ingredients of an item"
    },
    "itemListCardFieldNutritionalValues": {
        id: "item-list-card-field-nutritional-values",
        defaultMessage: "Nutritional Values",
        description: "The text for button to see nutritional values of an item"
    },
    "itemListCardFieldAuthorizationToSellByPackage": {
        id: "item-list-card-field-authorization-to-sell-by-package",
        defaultMessage: "Authorization to sell by package",
        description: "The text for description of the authorization to sell by package field for this product"
    },
    "itemListCardFieldBillingUnits": {
        id: "item-list-card-field-billingUnits",
        defaultMessage: "Billing units",
        description: "The text for description of the billing units field for this product"
    },
    "itemListCardFieldConversionCoeficient": {
        id: "item-list-card-field-conversionCoeficient",
        defaultMessage: "Conversion coeficient",
        description: "The text for description of the conversion coeficient field for this product"
    },
    "itemListCardFieldAlcoholDegree": {
        id: "item-list-card-field-alcoholDegree",
        defaultMessage: "Alcohol degree",
        description: "The text for description of the alcohol degree field for this product"
    },
    "itemListCardFieldisBio": {
        id: "item-list-card-field-isBio",
        defaultMessage: "Bio product",
        description: "The text for description of the is bio field for this product"
    },
    "itemListCardFieldbareCodeType": {
        id: "item-list-card-field-bareCodeType",
        defaultMessage: "Bare code type",
        description: "The text for description of the bare code type field for this product"
    },
    "itemListCardFieldbareCode": {
        id: "item-list-card-field-bareCode",
        defaultMessage: "Bare code",
        description: "The text for description of the bare code field for this product"
    },
    "itemListCardFieldWidth": {
        id: "item-list-card-field-width",
        defaultMessage: "Width",
        description: "The text for description of the width field for this product"
    },
    "itemListCardFieldHeight": {
        id: "item-list-card-field-height",
        defaultMessage: "Height",
        description: "The text for description of the height field for this product"
    },
    "itemListCardFieldDepth": {
        id: "item-list-card-field-depth",
        defaultMessage: "Depth",
        description: "The text for description of the depth field for this product"
    },
    "itemListCardFieldNumberOfPackagesPerLayer": {
        id: "item-list-card-field-numberOfPackagesPerLayer",
        defaultMessage: "Number of packages per layer",
        description: "The text for description of the Number of packages per layer field for this product"
    },
    "itemListCardFieldNumberOfLayersPerPallet": {
        id: "item-list-card-field-numberOfLayersPerPallet",
        defaultMessage: "Number of layers per pallet",
        description: "The text for description of the Number of layers per pallet field for this product"
    },
    "itemListCardFieldNumberOfUvcPerPallet": {
        id: "item-list-card-field-numberOfUvcPerPallet",
        defaultMessage: "Number of uvc per pallet",
        description: "The text for description of the Number of uvc per pallet field for this product"
    },
    "itemListCardFieldWeightNet": {
        id: "item-list-card-field-weightNet",
        defaultMessage: "Weight net",
        description: "The text for description of the net weight field for this product"
    },
    "itemListCardFieldWeightBrut": {
        id: "item-list-card-field-weightBrut",
        defaultMessage: "Weight brut",
        description: "The text for description of the brut weight field for this product"
    },
    "itemListCardFieldDensity": {
        id: "item-list-card-field-density",
        defaultMessage: "Density",
        description: "The text for description of the density field for this product"
    },
    "itemTitleDimensions": {
        id: "item-title-dimensions",
        defaultMessage: "Dimensions",
        description: "The title for the dimensions of this product"
    },
    "itemTitleDiscount": {
        id: "item-title-discount",
        defaultMessage: "Discount rates",
        description: "The title for the discount rates of this product"
    },
    "itemListCommercialInformationsTitle": {
        id: "item-list-commercial-informations-title",
        defaultMessage: "Commercial informations",
        description: "The title for the bloc Commercial informations"
    },
    "itemListCardFieldStock": {
        id: "item-list-card-field-stock",
        defaultMessage: "Stock",
        description: "The text for description of the stock field for this product"
    },
    "itemListCardFieldCommercialDesignation": {
        id: "item-list-card-field-commercialDesignation",
        defaultMessage: "Commercial designation",
        description: "The text for description of the commercial designation field for this product"
    },
    "itemListCardFieldProductCode": {
        id: "item-list-card-field-productCode",
        defaultMessage: "Product code",
        description: "The text for description of the code field for this product"
    },
    "itemDetailPackagingTabAddPackage": {
        id: "item-detail-packaging-tab-add-package",
        defaultMessage: "Add packaging",
        description: "The text for the header of the bloc form to add a package"
    },
    "itemDetailPackagingTabEditPackage": {
        id: "item-detail-packaging-tab-edit-package",
        defaultMessage: "Edit packaging",
        description: "The text for the header of the bloc form to edit a package"
    },
    "itemDetailPackagingTabPackageListHeader": {
        id: "item-detail-packaging-tab-list-header",
        defaultMessage: "My packagings",
        description: "The text for the header of the bloc list of packages"
    },
    "itemDetailPackagingTabFieldLabel": {
        id: "item-detail-packaging-tab-field-label",
        defaultMessage: "Label",
        description: "The text for the label field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabFieldCode": {
        id: "item-detail-packaging-tab-field-code",
        defaultMessage: "Code",
        description: "The text for the code field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabFieldCoefficient": {
        id: "item-detail-packaging-tab-field-coefficient",
        defaultMessage: "Coefficient",
        description: "The text for the coefficient field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabFieldAuthorizedForSale": {
        id: "item-detail-packaging-tab-field-authorizedForSale",
        defaultMessage: "Authorized for sale",
        description: "The text for the authorized for sale field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabFieldAuthorizedForSaleTrue": {
        id: "item-detail-packaging-tab-field-authorizedForSaleTrue",
        defaultMessage: "Authorized",
        description: "The text for the true value of the authorized for sale field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabFieldAuthorizedForSaleFalse": {
        id: "item-detail-packaging-tab-field-authorizedForSaleFalse",
        defaultMessage: "Unauthorized",
        description: "The text for the false value of the authorized for sale field of the bloc form to edit/create a package"
    },
    "itemDetailPackagingTabNoPackagingFound": {
        id: "item-detail-packaging-tab-noPackagingFound",
        defaultMessage: "No packaging found",
        description: "The text to inform that no package has been found"
    },
    "itemDetailPackagingTabConfirmDeletion": {
        id: "item-detail-packaging-tab-confirm-deletion",
        defaultMessage: "You are about to delete a package",
        description: "The title of the deletion modale of a package"
    },
    "itemDetailPackagingTabConfirmDeletionConfirm": {
        id: "item-detail-packaging-tab-confirm-deletion-confirm",
        defaultMessage: "Confirm : the package will be deleted",
        description: "The title of the deletion confirm action modale of a package"
    },
    "itemDetailPackagingTabConfirmDeletionCancel": {
        id: "item-detail-packaging-tab-confirm-deletion-cancel",
        defaultMessage: "Cancel : the package will be keeped",
        description: "The title of the deletion cancel action modale of a package"
    },
    "userPageHeaderTitle": {
        id: "user-page-header-title",
        defaultMessage: "USER",
        description: "The title for the header of the user page"
    },
    "userPageGeneralInformationTitle": {
        id: "user-page-general-information-title",
        defaultMessage: "Update my user profile",
        description: "The title for the bloc general information of the user page"
    },
    "userNotFound": {
        id: "user-notFound",
        defaultMessage: "No user account found",
        description: "The text to inform that no user has been found"
    },
    "societyPageHeaderTitle": {
        id: "society-page-header-title",
        defaultMessage: "My society space",
        description: "The title for the header of the society page"
    },
    "societyHeaderTitle": {
        id: "society-header-title",
        defaultMessage: "Society",
        description: "The title for the header of the society page"
    },
    "societyFormFieldName": {
        id: "society-form-field-name",
        defaultMessage: "Name",
        description: "The title for the name field of the society form"
    },
    "societyFormFieldBlockAllEmailShipmentsAtOrderSubmission": {
        id: "society-form-field-blockAllEmailShipmentsAtOrderSubmission",
        defaultMessage: "Block all email at shipments at order submission",
        description: "The title for the name blockAllEmailShipmentsAtOrderSubmission of the society form"
    },
    "societyFormFieldFreeManagementOrderLine": {
        id: "society-form-field-freeManagementOrderLine",
        defaultMessage: "Free management order line",
        description: "The title for the name freeManagementOrderLine of the society form"
    },
    "societyFormFieldAuthorizationToChangeDiscounts": {
        id: "society-form-field-authorizationToChangeDiscounts",
        defaultMessage: "Force the discounts of a command line",
        description: "The title for the name authorizationToChangeDiscounts of the society form"
    },
    "societyFormFieldAuthorizationToChangeTheGrossPrice": {
        id: "society-form-field-authorizationToChangeTheGrossPrice",
        defaultMessage: "Force the selling price of a command line",
        description: "The title for the name authorizationToChangeTheGrossPrice of the society form"
    },
    "societyFormFieldBusinessName": {
        id: "society-form-field-businessName",
        defaultMessage: "Business name",
        description: "The title for the businessName field of the society form"
    },
    "noOrderCommentFound": {
        id: "no-order-comment-found",
        defaultMessage: "No comment found for this order",
        description: "The description to inform the user that no comment exists for this order"
    },
    "TryAgain": {
        id: "error-try-again",
        defaultMessage: "Please try again",
        description: "The description to inform the user that nothing match it's search & that he needs to try again"
    },
    "ribTitle": {
        id: "rib-title",
        defaultMessage: "Statement of bank identity",
        description: "The description for the title of the rib component"
    },
    "ribBankCode": {
        id: "rib-bankCode",
        defaultMessage: "Bank code",
        description: "The description for the title of the bank code cell"
    },
    "ribCounterCode": {
        id: "rib-counterCode",
        defaultMessage: "Counter code",
        description: "The description for the title of the counter code cell"
    },
    "ribAccountNumber": {
        id: "rib-accountNumber",
        defaultMessage: "Account code",
        description: "The description for the title of the account number cell"
    },
    "ribKey": {
        id: "rib-key",
        defaultMessage: "Statement of bank identity key",
        description: "The description for the title of the statement of bank identity key cell"
    },
    "ribIban": {
        id: "rib-iban",
        defaultMessage: "International Bank Account Number",
        description: "The description for the title of the iban cell"
    },
    "ribBicSwift": {
        id: "rib-bicSwift",
        defaultMessage: "BIC/SWIFT",
        description: "The description for the title of the bic/swift cell"
    },
    "customerAdressesTabComponentTitle": {
        id: "customer-AdressesTabComponentTitle",
        defaultMessage: "My delivery addresses",
        description: "The description for the title of the customer delivery adresses accordeon component"
    },
    "customerAdressesTabComponentNoAddressFound": {
        id: "customer-AdressesTabComponentNoAddressFound",
        defaultMessage: "No delivery adresses found",
        description: "The description to inform that there are no delivery addresses retrieved for this customer"
    },
    "customerListNoData": {
        id: "customer-list-no-data",
        defaultMessage: "No client data",
        description: "The description to inform that there are no clients data for the datagrid"
    },
    "orderListNoData": {
        id: "order-list-no-data",
        defaultMessage: "No orders data",
        description: "The description to inform that there are no orders data for the datagrid"
    },
    "orderLinesNoData": {
        id: "order-lines-no-data",
        defaultMessage: "No order's lines data",
        description: "The description to inform that there are no order's lines data for the datagrid"
    },
    "rateDetailsLinesNoData": {
        id: "rate-details-lines-no-data",
        defaultMessage: "No rate details lines data",
        description: "The description to inform that there are no rate details lines data for the datagrid"
    },
    "societyMailsForOrderSubmission": {
        id: "society-mails-for-order-submission",
        defaultMessage: "Mails For Order Submission",
        description: "Mails list for order mailing when submission"
    },
    "orderCreationCustomerAmountDueControl": {
        id: "order-creation-customer-amount-due-control",
        defaultMessage: "This customer has an amount due of {amountDue} €. Do you want to create an order for him ?",
        description: "The message of the modal for the customer amount due control in the orderCreation page"
    },
    "orderCreationCustomerAmountDueControlCustomerView": {
        id: "order-creation-customer-amount-due-control-customer-view",
        defaultMessage: "You have an amount due of {amountDue} €. Would you like to create an order?",
        description: "The message of the modal for the customer amount due control (customer view) in the orderCreation page"
    },
    "orderCreationCustomerAmountDueControlTitle": {
        id: "order-creation-customer-amount-due-control-title",
        defaultMessage: "Amount due control",
        description: "The message of the modal for the customer amount due control title in the orderCreation page"
    },
    "adminToolsImportStartRun": {
        id: "admin-tools-import-start-run",
        defaultMessage: "Run import",
        description: "The message of the button for running import"
    },
    "adminToolsImportLaunched": {
        id: "admin-tools-import-launched",
        defaultMessage: "Import launched",
        description: "The message of the alert for import launched"
    },
    "genericIncorrectValue": {
        id: "generic-incorrect-value",
        defaultMessage: "Incorrect value",
        description: "Text for incorrect value error"
    },
    "genericAddAll": {
        id: "generic-add-all",
        defaultMessage: "Add all",
        description: "Action add all"
    },
    "genericRemoveAll": {
        id: "generic-remove-all",
        defaultMessage: "Remove all",
        description: "Action remove all"
    },
    "customerItemMassAction": {
        id: "customer-item-mass-action",
        defaultMessage: "Mass action referencing {type}",
        description: "Action Mass action referencing"
    },
    "customer": {
        id: "customer",
        defaultMessage: "Customer",
        description: "Action remove all"
    },
    "customerFamily": {
        id: "customer-family",
        defaultMessage: "Customer family",
        description: "Customer family"
    },
    "customerSuperFamily": {
        id: "customer-super-family",
        defaultMessage: "Customer super family",
        description: "Customer super family"
    },
    "unknown": {
        id: "unknown",
        defaultMessage: "Unknown",
        description: "Unknown"
    },
    "customerItemMassActionWarning": {
        id: "customer-item-mass-action-warning",
        defaultMessage: "You are about to massively add/remove a referencing from your current selection",
        description: "Warning message before action on add / remove referencing massively"
    },
    "genericActionAskingBeforeValidating": {
        id: "generic-action-asking-before-validating",
        defaultMessage: "Please confirm your action",
        description: "Message asking confirmation on an action"
    },
    "specificXavierTop1": {
        id: "specific-xavier-top1",
        defaultMessage: "You get a discount on invoice of {top1} for the passage of your order online !",
        description: "Specific Xavier for top1 (% discount)"
    },
    "specificXavierTop2AndTop3": {
        id: "specific-xavier-top2-top3",
        defaultMessage: "Take advantage: for any order placed at D-{top3} before your delivery, enjoy a discount of {top2} cumulative to {top1} of the online order !",
        description: "Specific Xavier for top2 + top3 (days + % discount)"
    },
    "rateDetailMinimumQuantity": {
        id: "rate-detail-minimum-quantity",
        defaultMessage: "Minimum quantity",
        description: "Rate detail quantity minimum"
    },
    "rateDetailPrice": {
        id: "rate-detail-price",
        defaultMessage: "Price",
        description: "Rate detail price"
    },
    "rateDetailDiscount": {
        id: "rate-detail-discount",
        defaultMessage: "Discount",
        description: "Rate detail discount"
    },
    "itemInfo": {
        id: "item-info",
        defaultMessage: "Information item",
        description: "Rate detail discount"
    },
    "itemOrderSchedule": {
        id: "item-order-schedule",
        defaultMessage: "Order Schedule",
        description: "Item order Schedule"
    },
    "rateDetailRate": {
        id: "rate-detail-rate",
        defaultMessage: "Rate",
        description: "Rate detail rate"
    },
    "rateDetailPromotionalRate": {
        id: "rate-detail-promotional-rate",
        defaultMessage: "Promotional rate",
        description: "Rate detail promotional rate"
    },
    "rateDetailStartDate": {
        id: "rate-detail-start-date",
        defaultMessage: "Start date",
        description: "Rate detail start date"
    },
    "rateDetailEndDate": {
        id: "rate-detail-end-date",
        defaultMessage: "End date",
        description: "Rate detail end date"
    },
    "deletionDate": {
        id: "deletion-date",
        defaultMessage: "Deletion date",
        description: "Deletion date"
    },
    "deleteOn": {
        id: "delete-on",
        defaultMessage: "Delete {date}",
        description: "Delete on {date}"
    }
})

const messageDesc: ({ [key in keyof typeof messages | string]: MessageDescriptor }) = messages
export const getMessageDescriptor = (id?: keyof typeof messages | string): MessageDescriptor => {
    return id && messageDesc[id] ? messageDesc[id] : messages["msgUnkown"]
}

export default messageDesc