import {ItemGateway} from "../../../corelogic/gateways/ItemGateway";
import {Item, ItemAllFormData, ItemFilter, ItemWithCount, PaginationFilter} from "../../../corelogic/models/item";
import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import qs from "qs";
import {ItemApiResponse, ItemApiWithCountResponse, mapItemApiResponseToItem} from "./Model/itemApi";

export class ApiItemGateway implements ItemGateway {
    async retrieveAll(): Promise<Item[]> {
        return apiFetchApiResponse<ItemApiResponse[]>({url: API_PREFIX.item})
            .then((x) => x.map(i => mapItemApiResponseToItem(i)))
    }

    async retrieveById(id: number, customerId?: number): Promise<Item | undefined> {
        return apiFetchApiResponse<ItemApiResponse>({
            url: customerId ?
                buildUrl(API_PREFIX.item, id.toString(), "customer", customerId.toString()) : buildUrl(API_PREFIX.item, id.toString())
        })
            .then((x) => mapItemApiResponseToItem(x))
    }

    async retrieveAllWithFavorite(): Promise<Item[]> {
        return apiFetchApiResponse<ItemApiResponse[]>({url: buildUrl(API_PREFIX.itemFavorites)})
            .then((x) => x.map(i => mapItemApiResponseToItem(i)))
    }

    async retrieveAllFiltered(filterValue: ItemFilter, paginationFilter: PaginationFilter, customerId?: number): Promise<ItemWithCount> {
        return apiFetchApiResponse<ItemApiWithCountResponse>({
            url: customerId ? buildUrl(API_PREFIX.itemFilter, "customer", customerId.toString()) : buildUrl(API_PREFIX.itemFilter),
            params: {...filterValue, ...paginationFilter},
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        }).then((x) => ({
            count: x.count,
            items: x.items.map(i => mapItemApiResponseToItem(i))
        }))
    }

    async retrieveAllItemFormFields(): Promise<ItemAllFormData> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.item, "all-data")})
    }

    async retrieveAllItemFormFieldsForFilter(idCustomer: number | undefined): Promise<ItemAllFormData> {
        return apiFetchApiResponse({url: idCustomer ? buildUrl(API_PREFIX.itemFilter, "all-data", String(idCustomer)) : buildUrl(API_PREFIX.itemFilter, "all-data")})
    }
}