import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {ToolsGateway} from "../../../corelogic/gateways/ToolsGateway";

export class ApiToolsGateway implements ToolsGateway {

    launchImport(): Promise<void> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.tools, "import"),
        })
    }
}