import {Item} from "../../../../../../corelogic/models/item";
import {
    Alert,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    Popover,
    Select,
    SelectChangeEvent,
    Tooltip
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {QuantityPickerSmaller} from "../../../Utils/QuantityPicker";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {FormattedMessage, useIntl} from "react-intl";
import {Image, UserRoleCode} from "../../../../../../corelogic/models/user";
import {useSelector} from "react-redux";
import {getOrderLinesSelector} from "../../../../../../store/selectors/ordersSelectors";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import {truncateStringWithDot} from "../../../Utils/Utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InfoIcon from "@mui/icons-material/Info";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";

interface OrderItemCardHorizontalInterface {
    data: Item,
    onItemDetailClick: (idItem: number) => void,
    onAddItemToOrderLineClick: (idItem: number, quantity: number, packageSelected: string) => void
    filterAccordeonIsOpen?: boolean
}

export const OrderItemCardHorizontal = (props: OrderItemCardHorizontalInterface) => {
    const {data, onItemDetailClick, onAddItemToOrderLineClick, filterAccordeonIsOpen} = props
    const {dataOrderLines} = useSelector(getOrderLinesSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const intl = useIntl()
    const [quantity, setQuantity] = useState(1)
    const [quantityErrorModal, setQuantityErrorModal] = useState(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [imageValue64Format, setImageValue64Format] = useState<string | undefined>("")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openPopOver = Boolean(anchorEl)
    const PACKAGE = "PACKAGE"
    const setDefaultPackage = () => {
        if (data.authorizationToSellByConsumerUnit) {
            return data.orderUnit?.code || "Not found"
        } else if (data.authorizationToSellByPackage) {
            return PACKAGE
        } else if (data.packagings.length > 0) {
            return data.packagings[0].code
        }
        return ""
    }
    const [packageSelected, setPackageSelected] = useState<string>(setDefaultPackage())

    useEffect(() => {
        // On récupère l'avatar de l'user si il en a déjà un en bdd
        if (data.picture) {
            //Json stringify l'objet de type File pour pouvoir le manipuler
            const imageObjectToJsonString = JSON.stringify(props.data.picture)
            const imageJsonStringToObject: Image = JSON.parse(imageObjectToJsonString)
            //On envoit la value en base64 dans le use state pour preview image
            setImageValue64Format(imageJsonStringToObject.image)
        }
    }, [imageValue64Format, data.picture])

    useEffect(() => {
        if (dataOrderLines) {
            const productToSelect = dataOrderLines.find((item) => item.itemCode === data.code)
            if (productToSelect && productToSelect.quantity) {
                if (productToSelect.quantity >= 999999) {
                    setIsDisabled(true)
                }
            }
        }
    }, [dataOrderLines, data.id, data.code])

    const handleItemAddClick = () => {
        onAddItemToOrderLineClick(data.id, quantity, packageSelected)
    }

    const handleItemDetailClick = () => {
        onItemDetailClick(data.id)
    }

    const handleItemQuantityChange = (quantity: number) => {
        setQuantity(quantity)
    }

    const handleItemQuantityChangeError = () => {
        setQuantityErrorModal(true)
    }

    const handleChangeQuantityType = (event: SelectChangeEvent) => {
        setPackageSelected(event.target.value)
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Card sx={{margin: "5px", width: 610}} elevation={5}>
                <Grid container justifyContent="space-between" alignItems="center" px={1}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <CardMedia
                                    component="img"
                                    height="80"
                                    image={`${process.env.REACT_APP_API_ENDPOINT}img/items/${data.code.replace("/","_")}.jpg`}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null
                                        currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                                    }}
                                    alt="green iguana"
                                    sx={{marginRight: "5px", width: "80px", borderRadius: "5px"}}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Tooltip title={data.label}>
                                            <div>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldLabel"))}
                                                    value={truncateStringWithDot(data.label, 0, 13)?.toLowerCase() || ""}/>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={data.code}>
                                            <div>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCode"))}
                                                    value={data.code || ""}/>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={data.brand?.label}>
                                            <div>
                                                <TextValueField
                                                    text={intl.formatMessage(getMessageDescriptor("orderVisualisationPageGridOrderLinesItemBrand"))}
                                                    value={truncateStringWithDot(data.brand?.label, 0, 13)?.toLowerCase() || ""}/>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <TextValueFieldMonetary
                                            text={intl.formatMessage(getMessageDescriptor("itemDetailRateGrossPrice"))}
                                            value={data.itemPrice?.grossPrice}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            {!filterAccordeonIsOpen &&
                                <Grid item>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item>
                                            <QuantityPickerSmaller sendQuantityProps={handleItemQuantityChange}
                                                                   sendQuantityPropsError={handleItemQuantityChangeError}/>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button variant="outlined" onClick={handleItemDetailClick}>
                                                        <RemoveRedEyeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                    </Button>
                                                </Grid>
                                                {userLogged?.role?.code === UserRoleCode.CUSTOMER &&
                                                    <Grid item>
                                                        <Button variant="outlined"
                                                                aria-owns={openPopOver ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={handlePopoverOpen}
                                                                onMouseLeave={handlePopoverClose}>
                                                            <InfoIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                            <Popover
                                                                id="mouse-over-popover"
                                                                sx={{
                                                                    pointerEvents: 'none',
                                                                }}
                                                                open={openPopOver}
                                                                anchorEl={anchorEl}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                onClose={handlePopoverClose}
                                                                disableRestoreFocus
                                                            >
                                                                <Box sx={{p: 1}}>
                                                                    <Typography textAlign="center"
                                                                                textTransform="uppercase"
                                                                                fontWeight="bolder">
                                                                        <FormattedMessage
                                                                            id={messages.itemListCardRateInformation.id}/></Typography>
                                                                    <DividingHR/>
                                                                    <Box>
                                                                        <TextValueField
                                                                            text={intl.formatMessage(getMessageDescriptor("itemDetailRateGrossPriceBuy"))}
                                                                            value={data.itemPrice?.grossPrice + " €"}/>
                                                                        <TextValueField
                                                                            text={intl.formatMessage(getMessageDescriptor("itemDetailRateNetPriceBuy"))}
                                                                            value={data.itemPrice?.netPrice + " €"}/>
                                                                        {data.itemPrice?.customerRateR1 ?
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemDetailRateCustomerRate"))}
                                                                                value={((Number(data.itemPrice?.customerRateR1)) * 100).toFixed(2) + " %"}/>
                                                                            : ""}
                                                                        {data.itemPrice?.customerPromotionalRateR2 ?
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemDetailRateCustomerPromotionalRate"))}
                                                                                value={((Number(data.itemPrice?.customerPromotionalRateR2)) * 100).toFixed(2) + " %"}/>
                                                                            : ""}
                                                                        {data.itemPrice?.customerLineDiscountR3 ?
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemDetailRateCustomerLineDiscount"))}
                                                                                value={((Number(data.itemPrice?.customerLineDiscountR3)) * 100).toFixed(2) + " %"}/>
                                                                            : ""}
                                                                    </Box>
                                                                </Box>
                                                            </Popover>
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            <Grid item>
                                <Grid container direction="column" alignItems="center" spacing={0.5}>
                                    <Grid item>
                                        <FormControl fullWidth sx={{width: "130px"}}>
                                            <InputLabel>{intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}</InputLabel>
                                            <Select
                                                value={packageSelected}
                                                onChange={handleChangeQuantityType}
                                                label={intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}
                                                sx={{height: 35}}>
                                                {data.authorizationToSellByConsumerUnit &&
                                                    <MenuItem
                                                        value={data.orderUnit?.code}>{data.orderUnit?.label}</MenuItem>
                                                }
                                                {data.authorizationToSellByPackage &&
                                                    <MenuItem value={PACKAGE}>{`Colis (x ${data.byHowMuch})`}</MenuItem>
                                                }
                                                {data.authorizationToSellByPackage && data.packagings.map((x) =>
                                                    <MenuItem key={x.id} value={x.code}>{x.label}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" disabled={isDisabled} onClick={handleItemAddClick}>
                                            <AddIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            {quantityErrorModal &&
                <Dialog
                    maxWidth="lg"
                    open
                >
                    <DialogTitle>
                        <FormattedMessage id={messages.orderCreationModalQuantityErrorInfoTitle.id}/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert
                                severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationModalQuantityErrorInfo"))}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error"
                                onClick={() => setQuantityErrorModal(false)}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                    </DialogActions>
                </Dialog>}
        </>
    )
}