import {AnyAction, combineReducers} from "redux"
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes"
import {CustomerSpecificData} from "../../corelogic/models/CustomerSpecificData";


const serverVersion = (state: string | null = null, action: AnyAction):string | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.server.SERVER_VERSION_SELECTING:
            return action.payload === undefined ? null : action.payload
        default:
            return state
    }
}

const fetchingServerVersion = (state: boolean = false, action: AnyAction): boolean => {
    if (action.type.startsWith("SERVER_VERSION")) {
        return action.type === APP_ACTIONS_TYPES.server.SERVER_VERSION_FETCHING
    }
    return state
}

const customerSpecificData = (state: CustomerSpecificData | null = null, action: AnyAction):CustomerSpecificData | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.server.CUSTOMER_SPECIFIC_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.server.CUSTOMER_SPECIFIC_RESET_SELECTING:
            return null
        default:
            return state
    }
}

const fetchingCustomerSpecificData = (state: boolean = false, action: AnyAction): boolean => {
    if (action.type.startsWith("CUSTOMER_SPECIFIC")) {
        return action.type === APP_ACTIONS_TYPES.server.CUSTOMER_SPECIFIC_FETCHING
    }
    return state
}


export default combineReducers({
    customerSpecificData,
    fetchingCustomerSpecificData,
    serverVersion,
    fetchingServerVersion
})