import * as React from 'react';
import {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Box, Container, Grid, LinearProgress, Paper} from "@mui/material";
import {Item} from "../../../../../../corelogic/models/item";
import {useDispatch, useSelector} from "react-redux";
import {retrieveSelectedItem} from "../../../../../../corelogic/usecase/items/itemsActions";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {getItemSelectedSelector} from "../../../../../../store/selectors/itemsSelectors";
import '../../../Style/Pages/ItemPages/_ItemDetailsPage.scss';
import '../../../Style/Pages/ItemPages/_ItemPage.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ItemGeneralInformations from "./ItemGeneralInformations";
import ItemPackagingComponent from "./ItemPackagingComponent";
import InfoIcon from '@mui/icons-material/Info';
import InventoryIcon from '@mui/icons-material/Inventory';
import ItemDetailsPageHeader from "./ItemDetailsPageHeader";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";

export const ItemDetailsStore = () => {
    const dispatch = useDispatch()
    const {itemId} = useParams()
    const {itemSelected, fetchingSelected} = useSelector(getItemSelectedSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)

    useEffect(() => {
        const itemIdNum = Number(itemId)
        if (itemId && !isNaN(itemIdNum))
            dispatch(retrieveSelectedItem(itemIdNum,userLogged?.linkedCustomer?.id))

        return () => dispatch<any>({type: APP_ACTIONS_TYPES.items.ITEM_RESET_SELECTION})
    }, [dispatch, itemId, userLogged?.linkedCustomer?.id])

    return (
        fetchingSelected ? <LinearProgress color="primary"/>
            : itemSelected && !fetchingSelected ? <ItemDetailsPage data={itemSelected}/>
                : <h1>Article not found</h1>
    )
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

interface ItemDetailsPageInterface {
    data: Item
    withoutHeader?: boolean
}

export const ItemDetailsPage = (props: ItemDetailsPageInterface) => {
    const {data, withoutHeader} = props
    const [value, setValue] = React.useState(0)
    const {userLogged} = useSelector(getUserLoggedSelector)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            {!withoutHeader && <Paper elevation={3} sx={{
                backgroundColor: "common.main",
                position: "sticky",
                top: "77px",
                zIndex: 1000
            }}>
                <ItemDetailsPageHeader data={data}/>
            </Paper>}
            <Container maxWidth={false}>
                <Paper elevation={5} sx={{width: '100%', mt: 1}}>
                    <Grid container justifyContent="center">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="General informations" {...a11yProps(0)} icon={<InfoIcon/>}/>
                            {userLogged?.role?.code !== UserRoleCode.CUSTOMER && <Tab label="Packaging types" {...a11yProps(1)} icon={<InventoryIcon/>}/>}
                        </Tabs>
                    </Grid>
                </Paper>
                <TabPanel value={value} index={0}>
                    <ItemGeneralInformations data={data}/>
                </TabPanel>
                {userLogged?.role?.code !== UserRoleCode.CUSTOMER &&
                    <TabPanel value={value} index={1}>
                        <ItemPackagingComponent itemId={data.id}/>
                    </TabPanel>
                }
            </Container>
        </>
    )
}