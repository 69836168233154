import {TextField} from "@mui/material";
import React from "react";
import '../Style/Utils/_SearchBar.scss';
import SearchIcon from '@mui/icons-material/Search';
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../i18n/messages";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

interface SearchBarInterface {
    value?: string
    label?: string
    onResetClick?: () => void
    onChange: (value: string) => void
}

export const SearchBar = (props: SearchBarInterface) => {

    const intl = useIntl()

    return (
        <div className="search" style={{ minWidth: "250px"}}>
            <TextField {...props}
                       label={props.label ? props.label : intl.formatMessage(getMessageDescriptor("searchBar"))}
                       variant="filled"
                       size="small"
                       fullWidth
                       onChange={(e) => props.onChange(e.target.value)}
                       value={props.value}
                       InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   <SearchIcon/>
                               </InputAdornment>
                           ),
                           endAdornment: (
                               props.value &&
                               <InputAdornment position="end"
                                               onClick={props.onResetClick}
                                               sx={{cursor: "pointer"}}>
                                   <CloseIcon/>
                               </InputAdornment>
                           )
                       }}
            />
        </div>
    )
}
