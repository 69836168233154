import {AnyAction, combineReducers} from "redux"
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes"
import {Depot} from "../../corelogic/models/depot";
import {User} from "../../corelogic/models/user";

const userLogged = (state: User | null = null, action: AnyAction): User | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.session.SESSION_LOGIN:
        case APP_ACTIONS_TYPES.session.SESSION_DATA_UPDATE:
            return action.payload !== undefined ? action.payload : null
        case APP_ACTIONS_TYPES.session.SESSION_LOGOUT:
            return null
        default:
            return state
    }
}

const activeAuthToken = (state: string = "", action: AnyAction): string => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.session.SESSION_AUTH_TOKEN:
            return action.payload
        case APP_ACTIONS_TYPES.session.SESSION_LOGOUT:
            return ""
        default:
            return state
    }
}

const fetching = (state: boolean = false, action: AnyAction): boolean => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.session.LOGIN_FETCHING:
            return true
        case APP_ACTIONS_TYPES.session.SESSION_AUTH_TOKEN:
            return state
        default:
            return false
    }
}

const currentDepot = (state: Depot | null = null, action: AnyAction) => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.session.SESSION_LOGIN:
        case APP_ACTIONS_TYPES.session.SESSION_DATA_UPDATE:
            return action.payload === undefined ? null : action.payload.defaultDepot
        case APP_ACTIONS_TYPES.session.SESSION_LOGOUT:
            return null
        default:
            return state
    }
}


export default combineReducers({
    userLogged,
    fetching,
    activeAuthToken,
    currentDepot
})