import {EnumData, Parameter} from "../../../../corelogic/models/parameter";

export const displayParameter = (parameter?: Parameter) => {
    return parameter ? `${parameter.label}` : ""
}

export const displayEnumData = (enumData?: EnumData) => {
    return enumData ? `${enumData.label}` : ""
}

export const truncateStringWithDot = (sentence?: string, start?: number, end?: number) => {
    return (
        sentence && end && sentence.length > end ? sentence.substring(start ? start : 0, end ? end : 20).trimEnd().concat("...") : sentence
    )
}