import {API_PREFIX} from "corelogic/config";
import {AuthenticationGateway} from "corelogic/gateways/AuthenticationGateway";
import {User} from "corelogic/models/user";
import {apiFetchApiResponse, axiosInstance} from "corelogic/utils/api";
import {buildUrl} from "corelogic/utils/tools";

export class ApiAuthenticationGateway implements AuthenticationGateway {

    private static instance: ApiAuthenticationGateway
    private token = ""

    public static getInstance(): ApiAuthenticationGateway {
        if (!ApiAuthenticationGateway.instance) {
            ApiAuthenticationGateway.instance = new ApiAuthenticationGateway();
        }
        return ApiAuthenticationGateway.instance;
    }

    async login(login: string, password: string): Promise<string | null> {
        this.updateToken(await apiFetchApiResponse({
            url: buildUrl(API_PREFIX.authentication, "login"),
            method: 'POST', data: JSON.stringify({login: login, password: password})
        }))
        return this.token
    }

    async logout(): Promise<void> {
        this.updateToken("")
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.authentication, "logout")})
    }

    async retrieveCurrent(): Promise<User | null> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.authentication, "current")})
    }

    async refreshingToken(): Promise<string> {
        this.updateToken(await apiFetchApiResponse({url: buildUrl(API_PREFIX.authentication, "refresh-token")}))
        return this.token
    }

    private updateToken = (token: string) => {
        axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + token
        this.token = token
    }

    getToken(): string {
        return this.token
    }
}