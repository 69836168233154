import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {SocietyGateway} from "../../../corelogic/gateways/SocietyGateway";
import {Society, SocietyAllFormData, SocietyForm} from "../../../corelogic/models/society";
import {mapSocietyApiResponseToSociety, SocietyApiResponse} from "./Model/societyApi";

export class ApiSocietyGateway implements SocietyGateway {
    retrieveAll(): Promise<Society[]> {
        return apiFetchApiResponse({url: API_PREFIX.society})
    }

    retrieveById(id: number): Promise<Society | undefined> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.society, id.toString())})
    }

    create(society: SocietyForm): Promise<Society> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.society),
            method: "POST",
            data: JSON.stringify(society)
        })
    }

    delete(societyId: number): Promise<void> {
        return Promise.resolve(undefined)
    }

    retrieveAllDataFormField(): Promise<SocietyAllFormData> {
        return apiFetchApiResponse({url: buildUrl(API_PREFIX.society, "all-data")})
    }

    async update(society: SocietyForm): Promise<Society> {
        const x = await apiFetchApiResponse<SocietyApiResponse>({
            url: buildUrl(API_PREFIX.society),
            method: "PUT",
            data: JSON.stringify(society)
        });
        return mapSocietyApiResponseToSociety(x);
    }
}