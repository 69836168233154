import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {CustomerGateway} from "../../gateways/CustomerGateway";
import {Address, CustomerFilter, CustomerForm} from "../../models/customer";
import {CustomerAddressesGateway} from "../../gateways/CustomerAddressesGateway";

export const retrieveCustomerById = (id: number):
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING})
        try {
            const customersData = await customerGateway.retrieveById(id)
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_SELECTING, payload: customersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllCustomersFamilies = ():
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_F_RETRIEVING})
        try {
            const customersFamiliesData = await customerGateway.retrieveAllCustomersFamilies()
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_F_RETRIEVED, payload: customersFamiliesData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllCustomers = ():
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVING})
        try {
            const customersData = await customerGateway.retrieveAll()
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVED, payload: customersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllCustomersFiltered = (filterValue: CustomerFilter):
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVING})
        try {
            const customersData = await customerGateway.retrieveAllFiltered(filterValue)
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVED, payload: customersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const createCustomer = (customer: CustomerForm):
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING})
        try {
            const customerData = await customerGateway.create(customer)
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADD, payload: customerData})
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_SELECTING, payload: customerData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateCustomer = (customer: CustomerForm):
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING})
        try {
            const customersData = await customerGateway.update(customer)
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_UPDATE, payload: customersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const deleteCustomer = (id: number):
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING})
        try {
            await customerGateway.delete(id)
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_DELETE, payload: id})
            const customersData = await customerGateway.retrieveAll()
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVED, payload: customersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveCustomerAddresses = (customerId: number):
    ThunkResult<{ customerAddressesGateway: CustomerAddressesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerAddressesGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_FETCHING})
        try {
            const customerAddresses = await customerAddressesGateway.retrieveAllByCustomerId(customerId)
            dispatch({
                type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_RETRIEVED,
                payload: customerAddresses,
                target: customerId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addCustomerDeliveryAddress = (customerId: number, address: Address):
    ThunkResult<{ customerAddressesGateway: CustomerAddressesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerAddressesGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_FETCHING})
        try {
            const customerAddresses = await customerAddressesGateway.add(customerId, address)
            dispatch({
                type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_ADD,
                payload: customerAddresses,
                target: customerId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateCustomerDeliveryAddress = (address: Address):
    ThunkResult<{ customerAddressesGateway: CustomerAddressesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerAddressesGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_FETCHING})
        try {
            const customerAddresses = await customerAddressesGateway.update(address)
            dispatch({
                type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_UPDATE,
                payload: customerAddresses
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const deleteCustomerDeliveryAddress = (addressId: number):
    ThunkResult<{ customerAddressesGateway: CustomerAddressesGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerAddressesGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_FETCHING})
        try {
            await customerAddressesGateway.delete(addressId)
            dispatch({
                type: APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_DELETE,
                payload: addressId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveCustomerFormDataValues = ():
    ThunkResult<{ customerGateway: CustomerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {customerGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING})
        try {
            const customerFormDataVal = await customerGateway.retrieveAllDataFormField()
            dispatch({type: APP_ACTIONS_TYPES.customers.CUSTOMER_FORM_DATA_FIELDS, payload: customerFormDataVal})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}