import {AppState} from "store/appState";

export const getOrderStatsSelector = (state: AppState) => {
    return {
        data: state.stats.order,
        fetching: state.stats.fetching
    }
}
export const getOrderStatsRepresentativeSelector = (state: AppState) => {
    return {
        representative: state.stats.representative,
        fetchingRepr: state.stats.fetchingRepresentative
    }
}