import {EnumData, Parameter, ParameterRateCode} from "../../../../corelogic/models/parameter";
import {Address, Customer} from "../../../../corelogic/models/customer";
import {Society} from "../../../../corelogic/models/society";

export interface CustomerApiRequest {
    id?: number
    societyId?: number
    code?: string
    businessFormId?: number
    businessName?: string
    languageId?: number
    currencyId?: number
    accountingCodeId?: number
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    countryId?: number
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    isBlocked?: boolean
    ratesId?: number
    statusId?: number
    saleRepresentative1Id?: number
    vatManagement?: string
    superFamilyId?: number
    familyId?: number
    categoryId?: number
    paymentChoiceId?: number
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCodeId?: number
    sendEmailAtOrderSubmission?: boolean
    promotionalRatePercentage?: number
}

export interface CustomerApiResponse {
    id: number
    code: string
    society: Society
    businessForm?: Parameter
    businessName?: string
    language?: Parameter
    currency?: Parameter
    accountingCode?: Parameter
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    isBlocked?: boolean
    rateCode?: ParameterRateCode
    status?: Parameter
    saleRepresentative1?: Parameter
    deliveryAddresses: Address[]
    vatManagement?: EnumData
    superFamily?: Parameter
    family?: Parameter
    category?: Parameter
    paymentChoice?: Parameter
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCode?: Parameter
    sendEmailAtOrderSubmission?: boolean
    promotionalRatePercentage?: number
}

export const mapCustomerApiResponseToCustomer = (customer: CustomerApiResponse): Customer => {
    return {
        ...customer,
        businessForm: customer.businessForm,
        language: customer.language,
        currency: customer.currency,
        country: customer.country,
        deliveryAddresses: [],
        vatManagement: customer.vatManagement,
        promotionalRateCode: customer.promotionalRateCode,
        family: customer.family,
        category: customer.category,
        paymentChoice: customer.paymentChoice,
        superFamily: customer.superFamily,
        saleRepresentative1: customer.saleRepresentative1,
        rateCode: customer.rateCode,
        accountingCode: customer.accountingCode,
        status: customer.status
    }
}