import React, {useEffect, useState} from 'react';
import {
    Alert,
    AppBar,
    Autocomplete,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogProps,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Toolbar,
    Tooltip
} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import CustomerFilterAccordeon from "../Customer/List/CustomerFilterAccordeon";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerFormSelector, getCustomersFamiliesSelector} from "../../../../../store/selectors/customersSelector";
import {columnsCustomerReferencingFamilies} from "../../Descriptions/customerFamilyReferencingListDescription";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReorderIcon from "@mui/icons-material/Reorder";
import {useIntl} from "react-intl";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {useNavigate} from "react-router-dom";
import {
    retrieveAllCustomersFamilies,
    retrieveCustomerFormDataValues
} from "../../../../../corelogic/usecase/customer/customersActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {CustomerFamily, Parameter} from "../../../../../corelogic/models/parameter";
import {CustomerItemReferencingType} from "../../../../secondary/InMemory/Data/enums";
import {
    retrieveAllCustomersSuperFamiliesAndFamiliesReferenced
} from "../../../../../corelogic/usecase/customerItemReference/customerItemReferencesActions";
import {getCustomerItemReferencesSelector} from "../../../../../store/selectors/customerItemRefSelectors";
import {CustomerItemReferencingHeader} from "./CustomerItemReferencingHeader";
import {APP_ACTIONS_TYPES} from "../../../../../store/AppActionsTypes";

export interface CustomerSuperFamiliesAndFamiliesWithId {
    id: number,
    idSF: number,
    codeSF: string,
    labelSF: string,
    idF?: number,
    codeF?: string,
    labelF?: string
}

const CustomerReferencingFamiliesPage = () => {
    const {dataFormFields} = useSelector(getCustomerFormSelector)
    const {dataFamilies} = useSelector(getCustomersFamiliesSelector)
    const {
        dataSuperFamiliesAndFamiliesReferenced,
        fetchingFamilies
    } = useSelector(getCustomerItemReferencesSelector)
    const [dataSuperFamiliesAndFamiliesReferencedWithId, setDataSuperFamiliesAndFamiliesReferencedWithId] = useState<CustomerSuperFamiliesAndFamiliesWithId[]>([])
    const dispatch = useDispatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const [toggleFilters, setToggleFilters] = useState<boolean>(false)
    const [referencingCreationModal, setReferencingCreationModal] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [density, setDensity] = useState<boolean>(false)
    const [selectedSF, setSelectedSF] = useState<Parameter | null>(null)
    const [selectedF, setSelectedF] = useState<CustomerFamily | null>(null)
    const [superFamiliesList, setSuperFamiliesList] = useState<Parameter[]>([])
    const [familiesList, setFamiliesList] = useState<CustomerFamily[]>([])
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('md')
    const handleRefresh = () => {
        dispatch(retrieveAllCustomersSuperFamiliesAndFamiliesReferenced())
    }
    const columns: GridColDef[] = [
        ...columnsCustomerReferencingFamilies.map(cd => ({
            ...cd,
            // headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
            // description: intl.formatMessage(getMessageDescriptor(cd.headerName))
        })),
        {
            field: 'actions',
            type: 'actions',
            width: 300,
            headerAlign: "center",
            align: "center",
            sortable: false,
            editable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            renderHeader: (params) => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("genericDataGridRefresh"))}>
                            <IconButton onClick={handleRefresh}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <RefreshIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("genericDataGridChangeDensity"))}>
                            <IconButton onClick={handleChangeDensity}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <ReorderIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: (params) => {
                return (
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Tooltip title="Gérer referecement article">
                            <IconButton
                                onClick={() => handleNavigation(params)}
                                sx={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer"
                                }}>
                                <AccountTreeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            }
        }
    ]

    useEffect(() => {
        dispatch(retrieveCustomerFormDataValues())
        dispatch(retrieveAllCustomersFamilies())
        dispatch(retrieveAllCustomersSuperFamiliesAndFamiliesReferenced())
        return () => {
            dispatch<any>({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RESET_DATA_SF_AND_F})
        }
    }, [dispatch])

    useEffect(() => {
        setDataSuperFamiliesAndFamiliesReferencedWithId(dataSuperFamiliesAndFamiliesReferenced.map(d => {
            return {id: Number(`${d.idSF ? d.idSF : ""}${d.idF ? d.idF : ""}`), ...d}
        }))
    }, [dataSuperFamiliesAndFamiliesReferenced]);

    useEffect(() => {
        setSuperFamiliesList(dataFormFields.superCustomerFamilies)
    }, [dataFormFields.superCustomerFamilies])

    useEffect(() => {
        setFamiliesList(dataFamilies)
    }, [dataFamilies])

    useEffect(() => {
        if (selectedSF) {
            if (dataFamilies.find(x => x.customerSuperFamily.id !== selectedSF.id)) {
                const newData = dataFamilies.filter(x => x.customerSuperFamily.id === selectedSF.id)
                setFamiliesList(newData)
            }
            if (selectedF && selectedF.customerSuperFamily.id !== selectedSF.id) {
                setSelectedF(null)
            }
        } else {
            setFamiliesList(dataFamilies)
            if (selectedF) {
                setSelectedSF(selectedF.customerSuperFamily)
            }
        }
    }, [dataFamilies, dataFormFields.superCustomerFamilies, selectedF, selectedSF])

    const handleNavigation = (params: GridRenderCellParams) => {
        const row = params.row
        if (row.codeF) {
            navigate(`/customers/referencing?type=${CustomerItemReferencingType.REFERENCING_BY_FAMILY}&id=${row.idF}`)
        } else {
            navigate(`/customers/referencing?type=${CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY}&id=${row.idSF}`)
        }
    }

    const handleChangeDensity = () => {
        setDensity(prevState => !prevState)
        if (!density && paginationModel.pageSize <= 50) {
            setPaginationModel({...paginationModel, pageSize: 50})
        }
    }

    const handleToggleFilterClick = (state: boolean) => {
        setToggleFilters(state)
    }

    const handleAddReferencing = () => {
        setReferencingCreationModal(true)
    }

    const handleCloseReferencingCreationModal = () => {
        setSelectedSF(null)
        setSelectedF(null)
        setReferencingCreationModal(false)
    }
    const handleNavigate = () => {
        if (selectedF) {
            navigate(`/customers/referencing?type=${CustomerItemReferencingType.REFERENCING_BY_FAMILY}&id=${selectedF.id}`)
        } else if (selectedSF) {
            navigate(`/customers/referencing?type=${CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY}&id=${selectedSF.id}`)
        }
    }

    return (
        <div>
            <Box sx={{position: "sticky", top: "77px", zIndex: 1000}}>
                <CustomerItemReferencingHeader getToggleFiltersState={handleToggleFilterClick}
                                               counter={dataSuperFamiliesAndFamiliesReferenced.length}
                                               onAddReferencingClick={handleAddReferencing}/>
            </Box>
            <Grid container height="84vh" p={1}>
                <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%"}}>
                    <CustomerFilterAccordeon dataFormFields={dataFormFields} isReferencingFamiliesMode/>
                </Collapse>
                {/*<Grid item md height="inherit">*/}
                {/*    <Paper sx={{height: "100%"}}>*/}
                        <DataGrid
                            rows={dataSuperFamiliesAndFamiliesReferencedWithId}
                            loading={fetchingFamilies}
                            columns={columns}
                            paginationModel={paginationModel}
                            pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                            disableRowSelectionOnClick
                            density={density ? "compact" : "standard"}
                            slots={{
                                // Toolbar: GridToolbar,
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <Alert severity="info">
                                            Aucun référencement disponible
                                        </Alert>
                                    </Stack>
                                )
                            }}
                            sx={{
                                "& .MuiDataGrid-cell:focus": {
                                    outline: "none"
                                },
                                "& .MuiDataGrid-columnHeader:focus": {
                                    outline: "none"
                                }
                            }}
                        />
                    {/*</Paper>*/}
                {/*</Grid>*/}
            </Grid>
            {referencingCreationModal &&
                <Dialog
                    fullWidth
                    maxWidth={maxWidth}
                    open={referencingCreationModal}
                    // onClose={handleCloseReferencingCreationModal}
                    sx={{overflowY: "hidden"}}
                >
                    <AppBar sx={{
                        position: 'relative', backgroundColor: "background.main"
                    }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseReferencingCreationModal}
                                aria-label="close"
                            >
                                <CloseIcon sx={{fill: "rgb(31,141,229)"}}/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div" color="text.primary">
                                Sélectionner une SF ou F pour gérer le référencement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Paper sx={{height: "200px", overflowY: "hidden"}}>
                        <Grid container direction="column" spacing={2} p={3}>
                            <Grid item>
                                <Autocomplete
                                    options={superFamiliesList}
                                    onChange={(event, param, reason) => {
                                        if (reason === "clear") {
                                            setSelectedF(null)
                                        }
                                        setSelectedSF(param)
                                    }}
                                    value={selectedSF}
                                    getOptionLabel={(option => `${option.label || ""} (${option.code || ""})`)}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormSuperFamily"))}
                                                                        variant="standard"
                                    />}
                                />
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    options={familiesList}
                                    onChange={(event, param) => {
                                        setSelectedF(param)
                                    }}
                                    value={selectedF}
                                    getOptionLabel={(option => `${option.label || ""} (${option.code || ""})`)}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFamily"))}
                                                                        variant="standard"
                                    />}
                                />
                            </Grid>
                            <Grid item container justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" onClick={handleNavigate}
                                            disabled={!selectedSF && !selectedF}>Confirmer</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Dialog>
            }
        </div>
    )
}

export default CustomerReferencingFamiliesPage;