import React, {useCallback, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    BadgeProps,
    Button,
    Checkbox,
    FormGroup,
    Grid,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material";
import Badge from "@mui/material/Badge";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import {DividingHR} from "../../../Utils/DividingHR";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import {CustomerAllFormData, CustomerFilter} from "../../../../../../corelogic/models/customer";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {styled} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import {truncateStringWithDot} from "../../../Utils/Utils";
import Box from "@mui/material/Box";
import BadgeIcon from '@mui/icons-material/Badge';
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import CategoryIcon from '@mui/icons-material/Category';
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface CustomerFilterAccordionProps {
    onClickResetAllFilters?: () => void
    onClickApplyAllFilters?: (customerFilterState: CustomerFilter, filterCount: number) => void
    dataFormFields?: CustomerAllFormData
    isReferencingFamiliesMode?: boolean
}

const CustomerFilterAccordeon = (props: CustomerFilterAccordionProps) => {
    const {isReferencingFamiliesMode} = props
    const {onClickApplyAllFilters, onClickResetAllFilters, dataFormFields} = props
    const intl = useIntl()
    const [filterCount, setFilterCount] = useState(0)
    const [customerFilter, setCustomerFilter] = useState<CustomerFilter>({
        block: false,
        ca: [],
        sf: [],
        fa: [],
        va: [],
        re: []
    })
    const [filterAccordionExpand, setFilterAccordionExpand] = useState({
        block: false,
        customerCategories: false,
        superCustomerFamilies: false,
        customerFamilies: false,
        promotionalRateCodes: false,
        vatManagements: false,
        representatives: false,
        rateCodes: false
    })

    const applyAllFilters = () => {
        if (onClickApplyAllFilters) {
            onClickApplyAllFilters(customerFilter, filterCount)
        }
    }

    const resetAllFilters = () => {
        if (onClickResetAllFilters) {
            onClickResetAllFilters()
        }
        setCustomerFilter({
            block: false,
            iv: "",
            rp: "",
            zc: "",
            ra: "",
            si: "",
            ca: [],
            sf: [],
            fa: [],
            va: [],
            re: []
        })
    }

    const mapFilterObjectToGetFilterCount = useCallback((filters: Object, keysToIgnore: string[] = []) => {
        let count = 0

        Object.entries(filters).forEach(([key, value]) => {
            if (keysToIgnore.includes(key)) {
                return
            }
            if (value === true || value === false) {
                count++
            } else if (Array.isArray(value)) {
                count += Array.from(value).length
            } else if (value) {
                count++
            }
        })
        return count
    }, [])

    useEffect(() => {
        setFilterCount(mapFilterObjectToGetFilterCount(customerFilter))
    }, [customerFilter, mapFilterObjectToGetFilterCount])

    const handleToggleAccordionState = (name: string) => {
        setFilterAccordionExpand({
            ...filterAccordionExpand,
            [name]: !filterAccordionExpand[name as keyof Object]
        })
    }

    const handleFilterBy = (e: React.ChangeEvent<HTMLInputElement>, filterName: string) => {
        if (!filterName) {
            return
        }
        const code = e.target.name
        const filterCodes = customerFilter[filterName as keyof CustomerFilter] as string[] || []

        if (e.target.checked) {
            if (!filterCodes.includes(code)) {
                setCustomerFilter({...customerFilter, [filterName]: [...filterCodes, code]})
            }
        } else {
            setCustomerFilter({
                ...customerFilter,
                [filterName]: filterCodes.filter(c => c !== code)
            })
        }
    }

    const handleFilterByBoolean = (event: React.MouseEvent<HTMLElement>, newState: boolean | null, filterName: string) => {
        if (newState === true) {
            setCustomerFilter({...customerFilter, [filterName]: true})
        } else if (newState === false) {
            setCustomerFilter({...customerFilter, [filterName]: false})
        } else {
            setCustomerFilter({...customerFilter, [filterName]: undefined})
        }
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomerFilter({
            ...customerFilter,
            [event.target.name]: event.target.value
        })
    }

    const handleCleanField = (name: string) => {
        setCustomerFilter({
            ...customerFilter,
            [name]: ""
        })
    }

    return (
        <Box sx={{maxHeight: 780, overflowY: "auto", pb: 1, px: 1}}>
            <Grid container direction="column" spacing={1} px={1} width={285}>
                <Grid item>
                    <Grid container justifyContent="space-evenly">
                        <Grid item>
                            <StyledBadge badgeContent={filterCount} color="error">
                                <Tooltip title={intl.formatMessage(getMessageDescriptor("genericApplyFilters"))}>
                                    <Button variant="contained" onClick={applyAllFilters}>
                                        <FilterAltIcon/>
                                    </Button>
                                </Tooltip>
                            </StyledBadge>
                        </Grid>
                        <Grid item>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("itemListFilterResetTitle"))}>
                                <Button variant="contained" onClick={resetAllFilters}>
                                    <FilterAltOffIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <DividingHR subContent style={{marginBottom: 0}}/>
                {!isReferencingFamiliesMode &&
                    <>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item px={2}>
                                        <Grid container alignItems="center">
                                            <RemoveCircleIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                            <Typography fontWeight="bolder" paddingLeft={2}>
                                                <FormattedMessage id={messages.customerGridColBlocked.id}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ToggleButtonGroup
                                            value={customerFilter.block}
                                            exclusive
                                            onChange={(e, value) => handleFilterByBoolean(e, value, "block")}
                                            aria-label="is blocked state"
                                        >
                                            <ToggleButton value={true} aria-label="left aligned" color="success"
                                                          size="small">
                                                <CheckCircleIcon/>
                                            </ToggleButton>
                                            <ToggleButton value={false} aria-label="centered" color="error"
                                                          size="small">
                                                <UnpublishedIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.customerCategories}
                                       onChange={() => handleToggleAccordionState("customerCategories")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <CategoryIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            Catégories
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.customerCategories.map(cc => {
                                            return (
                                                <FormControlLabel key={cc.code}
                                                                  label={truncateStringWithDot(cc.label ? cc.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="customerCategories"
                                                                      name={cc.code}
                                                                      onChange={(e) => handleFilterBy(e, "ca")}
                                                                      checked={customerFilter.ca?.includes(cc.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.superCustomerFamilies}
                                       onChange={() => handleToggleAccordionState("superCustomerFamilies")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <GroupWorkIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldSuperFamilyTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.superCustomerFamilies.map(sf => {
                                            return (
                                                <FormControlLabel key={sf.code}
                                                                  label={truncateStringWithDot(sf.label ? sf.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="superCustomerFamilies"
                                                                      name={sf.code}
                                                                      onChange={(e) => handleFilterBy(e, "sf")}
                                                                      checked={customerFilter.sf?.includes(sf.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.customerFamilies}
                                       onChange={() => handleToggleAccordionState("customerFamilies")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <WorkspacesIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldFamilyTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.customerFamilies.map(fa => {
                                            return (
                                                <FormControlLabel key={fa.code}
                                                                  label={truncateStringWithDot(fa.label ? fa.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="customerFamilies"
                                                                      name={fa.code}
                                                                      onChange={(e) => handleFilterBy(e, "fa")}
                                                                      checked={customerFilter.fa?.includes(fa.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.representatives}
                                       onChange={() => handleToggleAccordionState("representatives")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <BadgeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.customerGridColRepresentative.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.representatives.map(re => {
                                            return (
                                                <FormControlLabel key={re.code}
                                                                  label={truncateStringWithDot(re.label ? re.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="representatives"
                                                                      name={re.code}
                                                                      onChange={(e) => handleFilterBy(e, "re")}
                                                                      checked={customerFilter.re?.includes(re.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.vatManagements}
                                       onChange={() => handleToggleAccordionState("vatManagements")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <DiscountOutlinedIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.customerPageFormFieldVat.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.vatManagements.map(va => {
                                            return (
                                                <FormControlLabel key={va.value}
                                                                  label={truncateStringWithDot(va.label ? va.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="vatManagements"
                                                                      name={va.value}
                                                                      onChange={(e) => handleFilterBy(e, "va")}
                                                                      checked={customerFilter.va?.includes(va.value)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="center" alignItems="center" p={1}>
                                    <Grid item width="100%">
                                        <Tooltip title="contain">
                                            <TextField size="small" name="si" value={customerFilter.si}
                                                       id="filled-basic"
                                                       label={intl.formatMessage(getMessageDescriptor("customerGridColSiret"))}
                                                       variant="outlined"
                                                       fullWidth
                                                       InputLabelProps={{shrink: true}}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end"
                                                                               onClick={() => handleCleanField("si")}
                                                                               sx={{cursor: "pointer"}}>
                                                                   <CloseIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       onChange={handleChangeTextField}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="center" alignItems="center" p={1}>
                                    <Grid item width="100%">
                                        <Tooltip title="===">
                                            <TextField size="small" name="ra" value={customerFilter.ra}
                                                       id="filled-basic"
                                                       label={intl.formatMessage(getMessageDescriptor("customerGridColRateCode"))}
                                                       variant="outlined"
                                                       fullWidth
                                                       InputLabelProps={{shrink: true}}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end"
                                                                               onClick={() => handleCleanField("ra")}
                                                                               sx={{cursor: "pointer"}}>
                                                                   <CloseIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       onChange={handleChangeTextField}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="center" alignItems="center" p={1}>
                                    <Grid item width="100%">
                                        <Tooltip title="===">
                                            <TextField size="small" name="rp" value={customerFilter.rp}
                                                       id="filled-basic"
                                                       label={intl.formatMessage(getMessageDescriptor("customerPageFormPromotionalRateCode"))}
                                                       variant="outlined"
                                                       fullWidth
                                                       InputLabelProps={{shrink: true}}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end"
                                                                               onClick={() => handleCleanField("rp")}
                                                                               sx={{cursor: "pointer"}}>
                                                                   <CloseIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       onChange={handleChangeTextField}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="center" alignItems="center" p={1}>
                                    <Grid item width="100%">
                                        <Tooltip title="contient">
                                            <TextField size="small" name="iv" value={customerFilter.iv}
                                                       id="filled-basic"
                                                       label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldVat"))}
                                                       variant="outlined"
                                                       fullWidth
                                                       InputLabelProps={{shrink: true}}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end"
                                                                               onClick={() => handleCleanField("iv")}
                                                                               sx={{cursor: "pointer"}}>
                                                                   <CloseIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       onChange={handleChangeTextField}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={5}>
                                <Grid container justifyContent="center" alignItems="center" p={1}>
                                    <Grid item width="100%">
                                        <Tooltip title="===">
                                            <TextField size="small" name="zc" value={customerFilter.zc}
                                                       id="filled-basic"
                                                       label={intl.formatMessage(getMessageDescriptor("customerAddressZipCode1"))}
                                                       variant="outlined"
                                                       fullWidth
                                                       InputLabelProps={{shrink: true}}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end"
                                                                               onClick={() => handleCleanField("zc")}
                                                                               sx={{cursor: "pointer"}}>
                                                                   <CloseIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       onChange={handleChangeTextField}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                }
                {isReferencingFamiliesMode &&
                    <>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.superCustomerFamilies}
                                       onChange={() => handleToggleAccordionState("superCustomerFamilies")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <GroupWorkIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldSuperFamilyTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.superCustomerFamilies.map(sf => {
                                            return (
                                                <FormControlLabel key={sf.code}
                                                                  label={truncateStringWithDot(sf.label ? sf.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="superCustomerFamilies"
                                                                      name={sf.code}
                                                                      onChange={(e) => handleFilterBy(e, "sf")}
                                                                      checked={customerFilter.sf?.includes(sf.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion elevation={5} expanded={filterAccordionExpand.customerFamilies}
                                       onChange={() => handleToggleAccordionState("customerFamilies")}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                    <Grid container>
                                        <WorkspacesIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldFamilyTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </AccordionSummary>
                                <DividingHR subContent style={{margin: 0}}/>
                                <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                    <FormGroup>
                                        {dataFormFields?.customerFamilies.map(fa => {
                                            return (
                                                <FormControlLabel key={fa.code}
                                                                  label={truncateStringWithDot(fa.label ? fa.label : "", 0, 15)}
                                                                  control={<Checkbox
                                                                      data-label-type="customerFamilies"
                                                                      name={fa.code}
                                                                      onChange={(e) => handleFilterBy(e, "fa")}
                                                                      checked={customerFilter.fa?.includes(fa.code)}
                                                                      sx={{
                                                                          color: "rgba(255, 82, 82, 1)",
                                                                          padding: 0
                                                                      }}/>}/>
                                            )
                                        })}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    );
};

export default CustomerFilterAccordeon;